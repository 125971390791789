<template>
    <div>
        <v-snackbar
            v-model="snackbar"
            :vertical="false"
            :timeout="timeout"
            top="top"
            :color="tipo"
            elevation="0"
            multi-line
            right
        >
            {{ mensagem }}
            <template v-slot:action="{ attrs }">
                <v-btn text v-bind="attrs" @click="snackbar = false">OK</v-btn>
            </template>
        </v-snackbar>

        <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                    text
                    icon
                    v-bind="attrs"
                    v-on="on"
                    @click="abrirCadastroRapidoDePaciente"
                    color="primary"
                >
                    <user-plus-icon size="1.2x"></user-plus-icon>
                </v-btn>
            </template>
            <span>Cadastrar novo paciente</span>
        </v-tooltip>

        <!-- Modal Cadastro Rápido de Paciente -->
        <v-dialog
            v-model="janelaCadastroRapidoPaciente"
            persistent
            scrollable
            min-height="500px"
            max-width="550px"
        >
            <v-card flat>
                <v-card-title>
                    <h1 class="titulo-modal">Cadastro Rápido de Paciente</h1>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                    <v-form validation v-model="cadastroPaciente" ref="form">
                        <v-row class="pt-10">
                            <v-col cols="12" sm="12">
                                <v-text-field
                                    label="Nome do paciente"
                                    dense
                                    :autofocus="true"
                                    v-model="paciente.nome"
                                    :rules="campoObrigatorio"
                                >
                                </v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <date-picker
                                    label="Data de Nasc."
                                    v-model="paciente.dataNascimento"
                                    :rules="campoObrigatorio"
                                    :clearable="false"
                                >
                                </date-picker>
                            </v-col>

                            <v-col cols="12" sm="6" class="p-0">
                                <v-radio-group
                                    v-model="paciente.sexo"
                                    row
                                    class="pa-0"
                                    :rules="campoObrigatorio"
                                >
                                    <v-radio
                                        value="m"
                                        :label="'Masculino'"
                                    ></v-radio>
                                    <v-radio
                                        value="f"
                                        :label="'Feminino'"
                                    ></v-radio>
                                </v-radio-group>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-text-field
                                    label="Telefone Celular"
                                    dense
                                    v-model="paciente.celular"
                                    v-mask="'(##) #####-####'"
                                    number
                                    input-mode="decimal"
                                    :rules="campoObrigatorio"
                                >
                                </v-text-field>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-col class="text-right">
                        <v-btn
                            :disabled="!cadastroPaciente || aguardando"
                            text
                            class="btn-primario mr-2"
                            @click="cadatrarPaciente()"
                        >
                            Salvar
                        </v-btn>
                        <v-btn
                            :disabled="aguardando"
                            text
                            class="btn-secundario"
                            @click="fecharCadastroRapidoDePaciente"
                        >
                            Fechar
                        </v-btn>
                    </v-col>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
// APIs
import apiPacientes from "@/api/paciente/paciente-api";

// Components
import DatePicker from "@/components/comum/Datepicker";

// Models
import PacienteModel from "@/model/paciente/paciente-model";

// Utils
import utilsGeral from "@/utils/geral";

// Outros
import mixinMensagen from "@/mixin/mixin-mensagem";

export default {
    name: "CadastroRapidoPaciente",
    props: {},
    mixins: [mixinMensagen],
    components: {
        DatePicker,
    },
    emits: {
        "paciente-cadastrado": undefined,
    },
    data() {
        return {
            janelaCadastroRapidoPaciente: false,
            aguardando: false,
            cadastroPaciente: false,
            campoObrigatorio: [(v) => !!v || "Este campo é obrigatório"],
            paciente: new PacienteModel(),
        };
    },

    methods: {
        abrirCadastroRapidoDePaciente() {
            this.janelaCadastroRapidoPaciente = true;

            this.$nextTick(() => {
                this.$refs.form.reset();
                this.$refs.form.resetValidation();
            });
        },
        cadatrarPaciente() {
            this.aguardando = true;
            
            if (!this.$refs.form.validate()) {
                this.dispararMensagem("Por favor, preencha todos os campos obrigatórios.", "error");
                this.aguardando = false;
                return;
            }

            this.paciente.celular = utilsGeral.removerMascaraCelular(this.paciente.celular);

            apiPacientes
                .cadastrar(this.paciente)
                .then((resposta) => {
                    let paciente = new PacienteModel(resposta.data);
                    this.$emit("paciente-cadastrado", paciente);
                    this.dispararMensagem(`Paciente cadastrado com sucesso`, "success");
                    this.janelaCadastroRapidoPaciente = false;
                })
                .catch((erro) => {
                        this.dispararMensagem(
            erro.response && 
            erro.response.data.mensagem || erro, "error");
                })
                .finally((this.aguardando = false));
        },
        fecharCadastroRapidoDePaciente() {
            this.janelaCadastroRapidoPaciente = false;
        },
    },
};
</script>

<style lang="scss" scoped>
</style>