const name = '_blank';
const specs = ['fullscreen=yes', 'titlebar=yes', 'scrollbars=yes'];
const replace = true;
const url = window.location.origin;

function imprimir(html) {

  const win = window.open(url, name, specs, replace);
  win.document.write(`
    ${html}
  `);

  setTimeout(() => {
    win.document.close();
    win.focus();
    win.print();
    win.close();
  }, 1000);
  return true;
}

export default { imprimir }