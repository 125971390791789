<template>
  <div>
    <v-row v-if="!this.anexos.length">
      <TelaVazia
        :imagemSrc="'anexo.svg'"
        :titulo="'Não há anexos para este paciente'"
        :subtitulo="'Adicione um paciente para iniciar um atendimento'"
        :textoBotaoPrimario="'Adicionar anexo'"
        :exibirBotaoPrimario="true"
        :eventoBotaoPrimario="abrirModalAdicionarAnexo"
      >
      </TelaVazia>
    </v-row>
    <v-card flat v-else>
      <v-card-text>
        <v-row>
          <v-col cols="12" lg="10">
            <v-btn text class="btn-primario" @click="abrirModalAdicionarAnexo">
              Adicionar anexo
            </v-btn>
          </v-col>
          <v-col cols="12" lg="2">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Pesquisar..."
              single-line
              hide-details
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" lg="12">
            <v-data-table
              dense
              :items="anexos"
              :headers="colunasAnexo"
              :items-per-page="10"
              sort-by="id"
              sort-desc
              class="elevation-0"
              :search="search"
            >
              <template v-slot:body="{ items }">
                <tbody>
                  <tr v-for="item in items" :key="item.id">
                    <td class="text-left">
                      {{ item.descricao }}
                    </td>
                    <td class="text-left">
                      {{ item.criadoEm | data }}
                    </td>
                    <td class="text-left">
                      {{ item.caminho && item.caminho.split(".").pop() }}
                    </td>
                    <td class="text-left">
                      {{ item.tamanho | tamanhoParaMB }}
                    </td>
                    <td class="text-right">
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            icon
                            text
                            @click="baixarAnexo(item)"
                            v-bind="attrs"
                            v-on="on"
                          >
                            <download-icon
                              size="1.3x"
                              class="custom-class"
                            ></download-icon>
                          </v-btn>
                        </template>
                        <span>Baixar</span>
                      </v-tooltip>
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            icon
                            text
                            @click="visualizarAnexo(item)"
                            v-bind="attrs"
                            v-on="on"
                          >
                            <eye-icon
                              size="1.3x"
                              class="custom-class"
                            ></eye-icon>
                          </v-btn>
                        </template>
                        <span>Visualizar</span>
                      </v-tooltip>

                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            icon
                            text
                            @click="abrirModalEditarAnexo(item)"
                            v-bind="attrs"
                            v-on="on"
                          >
                            <edit-2-icon size="1.1x"></edit-2-icon>
                          </v-btn>
                        </template>
                        <span>Editar</span>
                      </v-tooltip>

                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            icon
                            text
                            @click="excluir(item)"
                            v-bind="attrs"
                            v-on="on"
                          >
                            <trash-icon size="1.1x"></trash-icon>
                          </v-btn>
                        </template>
                        <span>Excluir</span>
                      </v-tooltip>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <!-- Modal adicionar anexo -->
    <v-dialog width="450px" v-model="modalAdicionarAnexo" persistent>
      <v-card>
        <v-card-title>
          <h1 class="titulo-modal">Anexo</h1>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pt-5">
          <v-row>
            <v-col cols="12" sm="12">
              <v-form
                id="cadastro"
                ref="formCadastro"
                validation
                v-model="formCadastroValido"
              >
                <v-row>
                  <v-col cols="12" lg="12">
                    <v-text-field
                      label="Descrição"
                      dense
                      v-model="anexo.descricao"
                      type="text"
                      class="alinhar-direita"
                      :rules="[campoObrigatorio, maximo50Caracter]"
                      counter
                      maxlength="50"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" lg="12">
                    <v-file-input
                      label="Selecione o arquivo"
                      dense
                      prepend-icon="mdi-file"
                      v-model="anexo.arquivo"
                      :rules="[campoObrigatorio]"
                    ></v-file-input>
                  </v-col>
                </v-row>
              </v-form>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-col class="text-right">
            <v-btn
              text
              class="btn-primario mr-2"
              @click="criarAnexo"
              :loading="carregando"
              :disabled="!formCadastroValido || carregando"
            >
              Salvar
            </v-btn>
            <v-btn
              text
              class="btn-secundario"
              @click="modalAdicionarAnexo = false"
            >
              Fechar
            </v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Modal editar anexo -->
    <v-dialog width="450px" v-model="modalEditarAnexo" persistent>
      <v-card>
        <v-card-title>
          <h1 class="titulo-modal">Anexo</h1>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pt-5">
          <v-row>
            <v-col cols="12" sm="12">
              <v-form
                id="edicao"
                ref="formEdicao"
                validation
                v-model="formEdicaoValido"
              >
                <v-row>
                  <v-col cols="12" lg="12">
                    <v-text-field
                      label="Descrição"
                      dense
                      v-model="anexo.descricao"
                      type="text"
                      class="alinhar-direita"
                      :rules="[campoObrigatorio, maximo50Caracter]"
                      counter
                      maxlength="50"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
              </v-form>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-col class="text-right">
            <v-btn
              text
              class="btn-primario mr-2"
              @click="editarAnexo"
              :loading="carregando"
              :disabled="!formEdicaoValido || carregando"
            >
              Salvar
            </v-btn>
            <v-btn
              text
              class="btn-secundario"
              @click="modalEditarAnexo = false"
            >
              Fechar
            </v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// Components
import TelaVazia from "@/components/comum/TelaVazia";

// OUTROS
import moment from "moment";
import { URLS } from "@/constants/configuracoes-constants";

// API
import apiAnexos from "@/api/anexo/anexo-api";

// MODEL
import AnexoModel from "@/model/anexo/anexo-model";

// Tracking
import mixpanel from "mixpanel-browser";
import mixinAutorizacao from "@/mixin/mixin-autorizacao";

export default {
  name: "AnexosTab",
  components: { TelaVazia },
  mixins: [mixinAutorizacao],
  props: {
    paciente: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },
  emits: {
    "disparar-mensagem": (evento) => {
      return !!(evento.mensagem && evento.tipo);
    },
    "excluir-anexo": (evento) => {
      return !!(evento.callback && evento.mensagem);
    },
    "fechar-janela-confirmacao": undefined,
  },
  data() {
    return {
      search: "",
      anexos: [],
      modalAdicionarAnexo: false,
      formCadastroValido: false,
      modalEditarAnexo: false,
      formEdicaoValido: false,
      anexoExclusao: undefined,
      anexo: new AnexoModel(),
      carregando: false,
      campoObrigatorio: (v) => !!v || "Este campo é obrigatório",
      maximo50Caracter: (v) =>
        (!!v && v.length <= 50) || "Máximo de 50 caracteres",
      colunasAnexo: [
        {
          text: "Descrição",
          value: "descricao",
          sortable: true,
          align: "left",
        },
        {
          text: "Data de envio",
          value: "criadoEm",
          sortable: true,
          align: "left",
        },
        {
          text: "Formato",
          value: "caminho",
          sortable: true,
          align: "left",
        },
        {
          text: "Tamanho",
          value: "tamanho",
          sortable: true,
          align: "left",
        },
        {
          text: "",
          value: "botoes",
          align: "right",
          sortable: false,
        },
      ],
    };
  },
  methods: {
    async abrirModalAdicionarAnexo() {
      mixpanel.track("click", {
        nome_pagina: "prontuario_paciente",
        prontuario_aba: "anexo",
        nome_botao: "btn_adicionar_anexo",
        paciente_id: this.$route.params.id,
      });
      this.anexo = new AnexoModel();
      this.$refs.formCadastro && (await this.$refs.formCadastro.reset());
      this.modalAdicionarAnexo = true;
    },
    criarAnexo() {
      this.carregando = true;
      let formData = new FormData();
      this.anexo.idUsuario = this.usuario.id;
      formData.append("idPaciente", this.paciente.id);
      formData.append("descricao", this.anexo.descricao);
      formData.append("arquivo", this.anexo.arquivo);
      formData.append("idUsuario", this.anexo.idUsuario);
      console.log(formData);
      apiAnexos
        .cadastrar(formData)
        .then((resposta) => {
          let anexo = new AnexoModel(resposta.data);
          this.anexos.unshift(anexo);
          this.modalAdicionarAnexo = false;
          this.$emit("disparar-mensagem", {
            mensagem: "Anexo inserido com sucesso!",
            tipo: "success",
          });
        })
        .catch(() => {
          this.$emit("disparar-mensagem", {
            mensagem: "Erro ao inserir o anexo",
            tipo: "error",
          });
        })
        .finally(() => {
          this.carregando = false;
        });
    },

    async abrirModalEditarAnexo(item) {
      mixpanel.track("click", {
        nome_pagina: "prontuario_paciente",
        prontuario_aba: "anexo",
        nome_botao: "btn_editar_anexo",
        paciente_id: this.$route.params.id,
      });
      this.anexo = Object.assign({}, item);
      this.modalEditarAnexo = true;
    },
    editarAnexo() {
      this.carregando = true;

      apiAnexos
        .atualizar(this.anexo)
        .then((resposta) => {
          let anexoEditado = new AnexoModel(resposta.data);
          let index = this.anexos.findIndex((a) => a.id == anexoEditado.id);
          this.anexos.splice(index, 1, anexoEditado);
          this.modalEditarAnexo = false;
          this.$emit("disparar-mensagem", {
            mensagem: "Anexo editado com sucesso!",
            tipo: "success",
          });
        })
        .catch(() => {
          this.$emit("disparar-mensagem", {
            mensagem: "Erro ao editar o anexo",
            tipo: "error",
          });
        })
        .finally(() => {
          this.carregando = false;
        });
    },

    excluir(anexo) {
      mixpanel.track("click", {
        nome_pagina: "prontuario_paciente",
        prontuario_aba: "anexo",
        nome_botao: "btn_excluir_anexo",
        paciente_id: this.$route.params.id,
      });
      this.anexoExclusao = anexo.id;
      this.$emit("excluir-anexo", {
        callback: this.callbackExcluirAnexo,
        mensagem: `Deseja excluir o anexo?`,
      });
    },
    callbackExcluirAnexo() {
      apiAnexos
        .deletar(this.anexoExclusao)
        .then(() => {
          this.$emit("disparar-mensagem", {
            mensagem: "Anexo excluído com sucesso",
            tipo: "success",
          });
          let indice = this.anexos.findIndex((c) => c.id == this.anexoExclusao);
          this.anexos.splice(indice, 1);
        })
        .catch(() => {
          this.$emit("disparar-mensagem", {
            mensagem: "Falha na exclusão do anexo",
            tipo: "error",
          });
        })
        .finally(() => {
          this.$emit("fechar-janela-confirmacao");
          this.anexoExclusao = undefined;
        });
    },

    visualizarAnexo(anexo) {
      mixpanel.track("click", {
        nome_pagina: "prontuario_paciente",
        prontuario_aba: "anexo",
        nome_botao: "btn_visualizar_anexo",
        paciente_id: this.$route.params.id,
      });
      if (!anexo && !anexo.caminho) {
        return;
      }

      let preview = null;
      let fileContainer = document.createElement("div");
      fileContainer.classList.add("file-container");
      let caminhoFormatado = `${URLS.BASE}/${anexo.caminho.replace(
        "uploads",
        ""
      )}`;

      let ext = anexo.caminho.toString().split(".");
      ext = ext.reverse()[0].toString().toLowerCase();

      switch (ext) {
        default:
        case "png":
        case "jpg":
        case "jpeg":
          preview = document.createElement("img");
          preview.src = caminhoFormatado;
          break;
        case "svg":
          preview = document.createElement("object");
          preview.data = caminhoFormatado;
          preview.type = "image/svg+xml";
          break;
        case "pdf":
          preview = document.createElement("embed");
          preview.src = caminhoFormatado;
          preview.style.cssText =
            "position:fixed; top:0; left:0; bottom:0; right:0; width:100%; height:100%; border:none; margin:0; padding:0; overflow:hidden; z-index:999999;";
          preview.type = "application/pdf";
          break;
      }
      if (ext !== "pdf") preview.classList.add("zoom");

      let win = window.open("");
      win.document.write(`
                    <style>
                        body {
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        padding: 150px;
                        background: #c6c6c6;
                        }
                        .file-container {
                        display: flex;
                        object-fit: contain;
                        width: 100%;
                        height: 100%;
                        }
                        .zoom {
                        /* min-width: 400px;
                        min-height: 400px; */
                        margin: 0 auto;
                        transition: transform 4s;
                        }
                        .zoom:hover {
                        transform: scale(1.5);
                        }
                    </style>
                    <body>
                    </body>
                    `);
      fileContainer.appendChild(preview);
      win.document.body.appendChild(fileContainer);
    },

    baixarAnexo(anexo) {
      mixpanel.track("click", {
        nome_pagina: "prontuario_paciente",
        prontuario_aba: "anexo",
        nome_botao: "btn_baixar_anexo",
        paciente_id: this.$route.params.id,
      });
      apiAnexos
        .baixar(anexo.id)
        .then((response) => {
          let blob = new Blob([response.data]);
          let extensao = anexo.caminho.split(".").pop();
          let link = document.createElement("a");

          link.href = URL.createObjectURL(blob);
          link.setAttribute("download", `${anexo.descricao}.${extensao}`);
          link.click();

          URL.revokeObjectURL(link.href);
          this.$emit("disparar-mensagem", {
            mensagem: "Baixando anexo",
            tipo: "success",
          });
        })
        .catch(() => {
          this.$emit("disparar-mensagem", {
            mensagem: "Falha ao baixar o anexo",
            tipo: "error",
          });
        });
    },
  },
  watch: {},
  filters: {
    data(valor) {
      return moment(valor).format("DD/MM/YYYY");
    },
    tamanhoParaMB(valor) {
      let bytes = parseInt(valor);

      if (isNaN(bytes)) {
        return;
      }

      let kb = bytes / 1024;

      if (kb < 1024) {
        return kb.toFixed(0) + "KBs";
      }

      let mb = kb / 1024;

      return mb.toFixed(1) + "MBs";
    },
  },
  computed: {},
  mounted() {
    apiAnexos
      .obterTodos(this.paciente.id)
      .then((resposta) => {
        this.anexos = resposta.data.map((a) => new AnexoModel(a));
      })
      .catch(() => {
        this.$emit("disparar-mensagem", {
          mensagem: "Erro ao obter os anexos",
          tipo: "error",
        });
      });
  },
};
</script>

<style lang="scss" scoped>
.texto-modal {
  color: black;
}
.titulo-modal {
  font-weight: 400;
  padding-bottom: 5px;
}
.subtitulo-modal {
  font-weight: 500;
}
.timeline-card-topo {
  background-color: $cor_primaria;
  color: $branco;
  font-size: 12px;
  font-weight: 300;
  line-height: 150%;
  display: inline-block;
  padding: 10px;
}

.timeline-card-texto {
  padding: 10px;
}

.timeline-topo-chave {
  font-weight: 500;
}

.linha {
  min-height: 300px;
}

.historico {
  position: absolute;
  padding-bottom: 50px;
  right: 0;
  height: 100%;
  overflow: auto;
}

@media only screen and (max-width: 1263px) {
  .historico {
    overflow: auto;
    position: inherit;
  }
}
</style>