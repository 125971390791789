const TABELA_PERCENTIL_IMC_MESES_MASCULINO = [
  { SEXO: 1, MESES: 0, 
    VARICAO_PERCENTIL:[
      {IMC: (imc) => (imc < 10.1), PERCENTIS: [-0.1], DIAGNOSTICO: "Magreza acentuada"},
      {IMC: (imc) => (imc >= 10.1 && imc < 11.3), PERCENTIS: [0.1, 3], DIAGNOSTICO: "Magreza"},
      {IMC: (imc) => (imc >= 11.3 && imc <= 14.8), PERCENTIS: [3, 5, 10, 15,50, 85], DIAGNOSTICO: "Eutrofia"},
      {IMC: (imc) => (imc > 14.8 && imc <= 16.1), PERCENTIS: [85, 97], DIAGNOSTICO: "Sobrepeso"},
      {IMC: (imc) => (imc > 16.1 && imc <= 18.3), PERCENTIS: [97, 99], DIAGNOSTICO: "Obesidade"},
      {IMC: (imc) => (imc > 18.3), PERCENTIS: [99], DIAGNOSTICO: "Obesidade grave"},
    ], 
    VARICAO_ESCORE_Z:[
      {IMC: (imc) => (imc < 10.2), ESCORE_Z: [-4], DIAGNOSTICO: "Magreza acentuada"},
      {IMC: (imc) => (imc >= 10.2 && imc < 11.1), ESCORE_Z: [-3, -2], DIAGNOSTICO: "Magreza"},
      {IMC: (imc) => (imc >= 11.1 && imc <= 14.8), ESCORE_Z: [-2, -1, 0, 1], DIAGNOSTICO: "Eutrofia"},
      {IMC: (imc) => (imc > 14.8 && imc <= 16.3), ESCORE_Z: [1, 2], DIAGNOSTICO: "Sobrepeso"},
      {IMC: (imc) => (imc > 16.3 && imc <= 18.1), ESCORE_Z: [2, 3], DIAGNOSTICO: "Obesidade"},
      {IMC: (imc) => (imc > 18.1), ESCORE_Z: [4], DIAGNOSTICO: "Obesidade grave"},
    ]
  },{ SEXO: 1, MESES: 1, 
    VARICAO_PERCENTIL:[
      {IMC: (imc) => (imc < 11.2), PERCENTIS: [-0.1], DIAGNOSTICO: "Magreza acentuada"},
      {IMC: (imc) => (imc >= 11.2 && imc < 12.6), PERCENTIS: [0.1, 3], DIAGNOSTICO: "Magreza"},
      {IMC: (imc) => (imc >= 12.6 && imc <= 16.4), PERCENTIS: [3, 5, 10, 15,50, 85], DIAGNOSTICO: "Eutrofia"},
      {IMC: (imc) => (imc > 16.4 && imc <= 17.6), PERCENTIS: [85, 97], DIAGNOSTICO: "Sobrepeso"},
      {IMC: (imc) => (imc > 17.6 && imc <= 19.6), PERCENTIS: [97, 99], DIAGNOSTICO: "Obesidade"},
      {IMC: (imc) => (imc > 19.6), PERCENTIS: [99], DIAGNOSTICO: "Obesidade grave"},
    ], 
    VARICAO_ESCORE_Z:[
      {IMC: (imc) => (imc < 11.3), ESCORE_Z: [-4], DIAGNOSTICO: "Magreza acentuada"},
      {IMC: (imc) => (imc >= 11.3 && imc < 12.4), ESCORE_Z: [-3, -2], DIAGNOSTICO: "Magreza"},
      {IMC: (imc) => (imc >= 12.4 && imc <= 16.3), ESCORE_Z: [-2, -1, 0, 1], DIAGNOSTICO: "Eutrofia"},
      {IMC: (imc) => (imc > 16.3 && imc <= 17.8), ESCORE_Z: [1, 2], DIAGNOSTICO: "Sobrepeso"},
      {IMC: (imc) => (imc > 17.8 && imc <= 19.4), ESCORE_Z: [2, 3], DIAGNOSTICO: "Obesidade"},
      {IMC: (imc) => (imc > 19.4), ESCORE_Z: [4], DIAGNOSTICO: "Obesidade grave"},
    ]
  },{ SEXO: 1, MESES: 2, 
    VARICAO_PERCENTIL:[
      {IMC: (imc) => (imc < 12.4), PERCENTIS: [-0.1], DIAGNOSTICO: "Magreza acentuada"},
      {IMC: (imc) => (imc >= 12.4 && imc < 13.8), PERCENTIS: [0.1, 3], DIAGNOSTICO: "Magreza"},
      {IMC: (imc) => (imc >= 13.8 && imc <= 17.8), PERCENTIS: [3, 5, 10, 15,50, 85], DIAGNOSTICO: "Eutrofia"},
      {IMC: (imc) => (imc > 17.8 && imc <= 19.2), PERCENTIS: [85, 97], DIAGNOSTICO: "Sobrepeso"},
      {IMC: (imc) => (imc > 19.2 && imc <= 21.3), PERCENTIS: [97, 99], DIAGNOSTICO: "Obesidade"},
      {IMC: (imc) => (imc > 21.3), PERCENTIS: [99], DIAGNOSTICO: "Obesidade grave"},
    ], 
    VARICAO_ESCORE_Z:[
      {IMC: (imc) => (imc < 12.5), ESCORE_Z: [-4], DIAGNOSTICO: "Magreza acentuada"},
      {IMC: (imc) => (imc >= 12.5 && imc < 13.7), ESCORE_Z: [-3, -2], DIAGNOSTICO: "Magreza"},
      {IMC: (imc) => (imc >= 13.7 && imc <= 17.8), ESCORE_Z: [-2, -1, 0, 1], DIAGNOSTICO: "Eutrofia"},
      {IMC: (imc) => (imc > 17.8 && imc <= 19.4), ESCORE_Z: [1, 2], DIAGNOSTICO: "Sobrepeso"},
      {IMC: (imc) => (imc > 19.4 && imc <= 21.1), ESCORE_Z: [2, 3], DIAGNOSTICO: "Obesidade"},
      {IMC: (imc) => (imc > 21.1), ESCORE_Z: [4], DIAGNOSTICO: "Obesidade grave"},
    ]
  },{ SEXO: 1, MESES: 3, 
    VARICAO_PERCENTIL:[
      {IMC: (imc) => (imc < 13), PERCENTIS: [-0.1], DIAGNOSTICO: "Magreza acentuada"},
      {IMC: (imc) => (imc >= 13 && imc < 14.4), PERCENTIS: [0.1, 3], DIAGNOSTICO: "Magreza"},
      {IMC: (imc) => (imc >= 14.4 && imc <= 18.5), PERCENTIS: [3, 5, 10, 15,50, 85], DIAGNOSTICO: "Eutrofia"},
      {IMC: (imc) => (imc > 18.5 && imc <= 19.8), PERCENTIS: [85, 97], DIAGNOSTICO: "Sobrepeso"},
      {IMC: (imc) => (imc > 19.8 && imc <= 22), PERCENTIS: [97, 99], DIAGNOSTICO: "Obesidade"},
      {IMC: (imc) => (imc > 22), PERCENTIS: [99], DIAGNOSTICO: "Obesidade grave"},
    ], 
    VARICAO_ESCORE_Z:[
      {IMC: (imc) => (imc < 13.1), ESCORE_Z: [-4], DIAGNOSTICO: "Magreza acentuada"},
      {IMC: (imc) => (imc >= 13.1 && imc < 14.3), ESCORE_Z: [-3, -2], DIAGNOSTICO: "Magreza"},
      {IMC: (imc) => (imc >= 14.3 && imc <= 18.4), ESCORE_Z: [-2, -1, 0, 1], DIAGNOSTICO: "Eutrofia"},
      {IMC: (imc) => (imc > 18.4 && imc <= 20), ESCORE_Z: [1, 2], DIAGNOSTICO: "Sobrepeso"},
      {IMC: (imc) => (imc > 20 && imc <= 21.8), ESCORE_Z: [2, 3], DIAGNOSTICO: "Obesidade"},
      {IMC: (imc) => (imc > 21.8), ESCORE_Z: [4], DIAGNOSTICO: "Obesidade grave"},
    ]
  },{ SEXO: 1, MESES: 4, 
    VARICAO_PERCENTIL:[
      {IMC: (imc) => (imc < 13.3), PERCENTIS: [-0.1], DIAGNOSTICO: "Magreza acentuada"},
      {IMC: (imc) => (imc >= 13.3 && imc < 14.7), PERCENTIS: [0.1, 3], DIAGNOSTICO: "Magreza"},
      {IMC: (imc) => (imc >= 14.7 && imc <= 18.7), PERCENTIS: [3, 5, 10, 15,50, 85], DIAGNOSTICO: "Eutrofia"},
      {IMC: (imc) => (imc > 18.7 && imc <= 20.1), PERCENTIS: [85, 97], DIAGNOSTICO: "Sobrepeso"},
      {IMC: (imc) => (imc > 20.1 && imc <= 22.3), PERCENTIS: [97, 99], DIAGNOSTICO: "Obesidade"},
      {IMC: (imc) => (imc > 22.3), PERCENTIS: [99], DIAGNOSTICO: "Obesidade grave"},
    ], 
    VARICAO_ESCORE_Z:[
      {IMC: (imc) => (imc < 13.4), ESCORE_Z: [-4], DIAGNOSTICO: "Magreza acentuada"},
      {IMC: (imc) => (imc >= 13.4 && imc < 14.5), ESCORE_Z: [-3, -2], DIAGNOSTICO: "Magreza"},
      {IMC: (imc) => (imc >= 14.5 && imc <= 18.7), ESCORE_Z: [-2, -1, 0, 1], DIAGNOSTICO: "Eutrofia"},
      {IMC: (imc) => (imc > 18.7 && imc <= 20.3), ESCORE_Z: [1, 2], DIAGNOSTICO: "Sobrepeso"},
      {IMC: (imc) => (imc > 20.3 && imc <= 22.1), ESCORE_Z: [2, 3], DIAGNOSTICO: "Obesidade"},
      {IMC: (imc) => (imc > 22.1), ESCORE_Z: [4], DIAGNOSTICO: "Obesidade grave"},
    ]
  },{ SEXO: 1, MESES: 5, 
    VARICAO_PERCENTIL:[
      {IMC: (imc) => (imc < 13.4), PERCENTIS: [-0.1], DIAGNOSTICO: "Magreza acentuada"},
      {IMC: (imc) => (imc >= 13.4 && imc < 14.8), PERCENTIS: [0.1, 3], DIAGNOSTICO: "Magreza"},
      {IMC: (imc) => (imc >= 14.8 && imc <= 18.9), PERCENTIS: [3, 5, 10, 15,50, 85], DIAGNOSTICO: "Eutrofia"},
      {IMC: (imc) => (imc > 18.9 && imc <= 20.2), PERCENTIS: [85, 97], DIAGNOSTICO: "Sobrepeso"},
      {IMC: (imc) => (imc > 20.2 && imc <= 22.4), PERCENTIS: [97, 99], DIAGNOSTICO: "Obesidade"},
      {IMC: (imc) => (imc > 22.4), PERCENTIS: [99], DIAGNOSTICO: "Obesidade grave"},
    ], 
    VARICAO_ESCORE_Z:[
      {IMC: (imc) => (imc < 13.5), ESCORE_Z: [-4], DIAGNOSTICO: "Magreza acentuada"},
      {IMC: (imc) => (imc >= 13.5 && imc < 14.7), ESCORE_Z: [-3, -2], DIAGNOSTICO: "Magreza"},
      {IMC: (imc) => (imc >= 14.7 && imc <= 18.8), ESCORE_Z: [-2, -1, 0, 1], DIAGNOSTICO: "Eutrofia"},
      {IMC: (imc) => (imc > 18.8 && imc <= 20.5), ESCORE_Z: [1, 2], DIAGNOSTICO: "Sobrepeso"},
      {IMC: (imc) => (imc > 20.5 && imc <= 22.3), ESCORE_Z: [2, 3], DIAGNOSTICO: "Obesidade"},
      {IMC: (imc) => (imc > 22.3), ESCORE_Z: [4], DIAGNOSTICO: "Obesidade grave"},
    ]
  },{ SEXO: 1, MESES: 6, 
    VARICAO_PERCENTIL:[
      {IMC: (imc) => (imc < 13.5), PERCENTIS: [-0.1], DIAGNOSTICO: "Magreza acentuada"},
      {IMC: (imc) => (imc >= 13.5 && imc < 14.9), PERCENTIS: [0.1, 3], DIAGNOSTICO: "Magreza"},
      {IMC: (imc) => (imc >= 14.9 && imc <= 18.9), PERCENTIS: [3, 5, 10, 15,50, 85], DIAGNOSTICO: "Eutrofia"},
      {IMC: (imc) => (imc > 18.9 && imc <= 20.3), PERCENTIS: [85, 97], DIAGNOSTICO: "Sobrepeso"},
      {IMC: (imc) => (imc > 20.3 && imc <= 22.5), PERCENTIS: [97, 99], DIAGNOSTICO: "Obesidade"},
      {IMC: (imc) => (imc > 22.5), PERCENTIS: [99], DIAGNOSTICO: "Obesidade grave"},
    ], 
    VARICAO_ESCORE_Z:[
      {IMC: (imc) => (imc < 13.6), ESCORE_Z: [-4], DIAGNOSTICO: "Magreza acentuada"},
      {IMC: (imc) => (imc >= 13.6 && imc < 14.7), ESCORE_Z: [-3, -2], DIAGNOSTICO: "Magreza"},
      {IMC: (imc) => (imc >= 14.7 && imc <= 18.8), ESCORE_Z: [-2, -1, 0, 1], DIAGNOSTICO: "Eutrofia"},
      {IMC: (imc) => (imc > 18.8 && imc <= 20.5), ESCORE_Z: [1, 2], DIAGNOSTICO: "Sobrepeso"},
      {IMC: (imc) => (imc > 20.5 && imc <= 22.3), ESCORE_Z: [2, 3], DIAGNOSTICO: "Obesidade"},
      {IMC: (imc) => (imc > 22.3), ESCORE_Z: [4], DIAGNOSTICO: "Obesidade grave"},
    ]
  },{ SEXO: 1, MESES: 7, 
    VARICAO_PERCENTIL:[
      {IMC: (imc) => (imc < 13.6), PERCENTIS: [-0.1], DIAGNOSTICO: "Magreza acentuada"},
      {IMC: (imc) => (imc >= 13.6 && imc < 14.9), PERCENTIS: [0.1, 3], DIAGNOSTICO: "Magreza"},
      {IMC: (imc) => (imc >= 14.9 && imc <= 18.9), PERCENTIS: [3, 5, 10, 15,50, 85], DIAGNOSTICO: "Eutrofia"},
      {IMC: (imc) => (imc > 18.9 && imc <= 20.3), PERCENTIS: [85, 97], DIAGNOSTICO: "Sobrepeso"},
      {IMC: (imc) => (imc > 20.3 && imc <= 22.5), PERCENTIS: [97, 99], DIAGNOSTICO: "Obesidade"},
      {IMC: (imc) => (imc > 22.5), PERCENTIS: [99], DIAGNOSTICO: "Obesidade grave"},
    ], 
    VARICAO_ESCORE_Z:[
      {IMC: (imc) => (imc < 13.7), ESCORE_Z: [-4], DIAGNOSTICO: "Magreza acentuada"},
      {IMC: (imc) => (imc >= 13.7 && imc < 14.8), ESCORE_Z: [-3, -2], DIAGNOSTICO: "Magreza"},
      {IMC: (imc) => (imc >= 14.8 && imc <= 18.8), ESCORE_Z: [-2, -1, 0, 1], DIAGNOSTICO: "Eutrofia"},
      {IMC: (imc) => (imc > 18.8 && imc <= 20.5), ESCORE_Z: [1, 2], DIAGNOSTICO: "Sobrepeso"},
      {IMC: (imc) => (imc > 20.5 && imc <= 22.3), ESCORE_Z: [2, 3], DIAGNOSTICO: "Obesidade"},
      {IMC: (imc) => (imc > 22.3), ESCORE_Z: [4], DIAGNOSTICO: "Obesidade grave"},
    ]
  },{ SEXO: 1, MESES: 8, 
    VARICAO_PERCENTIL:[
      {IMC: (imc) => (imc < 13.5), PERCENTIS: [-0.1], DIAGNOSTICO: "Magreza acentuada"},
      {IMC: (imc) => (imc >= 13.5 && imc < 14.9), PERCENTIS: [0.1, 3], DIAGNOSTICO: "Magreza"},
      {IMC: (imc) => (imc >= 14.9 && imc <= 18.8), PERCENTIS: [3, 5, 10, 15,50, 85], DIAGNOSTICO: "Eutrofia"},
      {IMC: (imc) => (imc > 18.8 && imc <= 20.2), PERCENTIS: [85, 97], DIAGNOSTICO: "Sobrepeso"},
      {IMC: (imc) => (imc > 20.2 && imc <= 22.4), PERCENTIS: [97, 99], DIAGNOSTICO: "Obesidade"},
      {IMC: (imc) => (imc > 22.4), PERCENTIS: [99], DIAGNOSTICO: "Obesidade grave"},
    ], 
    VARICAO_ESCORE_Z:[
      {IMC: (imc) => (imc < 13.6), ESCORE_Z: [-4], DIAGNOSTICO: "Magreza acentuada"},
      {IMC: (imc) => (imc >= 13.6 && imc < 14.7), ESCORE_Z: [-3, -2], DIAGNOSTICO: "Magreza"},
      {IMC: (imc) => (imc >= 14.7 && imc <= 18.7), ESCORE_Z: [-2, -1, 0, 1], DIAGNOSTICO: "Eutrofia"},
      {IMC: (imc) => (imc > 18.7 && imc <= 20.4), ESCORE_Z: [1, 2], DIAGNOSTICO: "Sobrepeso"},
      {IMC: (imc) => (imc > 20.4 && imc <= 22.2), ESCORE_Z: [2, 3], DIAGNOSTICO: "Obesidade"},
      {IMC: (imc) => (imc > 22.2), ESCORE_Z: [4], DIAGNOSTICO: "Obesidade grave"},
    ]
  },{ SEXO: 1, MESES: 9, 
    VARICAO_PERCENTIL:[
      {IMC: (imc) => (imc < 13.5), PERCENTIS: [-0.1], DIAGNOSTICO: "Magreza acentuada"},
      {IMC: (imc) => (imc >= 13.5 && imc < 14.8), PERCENTIS: [0.1, 3], DIAGNOSTICO: "Magreza"},
      {IMC: (imc) => (imc >= 14.8 && imc <= 18.7), PERCENTIS: [3, 5, 10, 15,50, 85], DIAGNOSTICO: "Eutrofia"},
      {IMC: (imc) => (imc > 18.7 && imc <= 20.1), PERCENTIS: [85, 97], DIAGNOSTICO: "Sobrepeso"},
      {IMC: (imc) => (imc > 20.1 && imc <= 22.3), PERCENTIS: [97, 99], DIAGNOSTICO: "Obesidade"},
      {IMC: (imc) => (imc > 22.3), PERCENTIS: [99], DIAGNOSTICO: "Obesidade grave"},
    ], 
    VARICAO_ESCORE_Z:[
      {IMC: (imc) => (imc < 13.6), ESCORE_Z: [-4], DIAGNOSTICO: "Magreza acentuada"},
      {IMC: (imc) => (imc >= 13.6 && imc < 14.7), ESCORE_Z: [-3, -2], DIAGNOSTICO: "Magreza"},
      {IMC: (imc) => (imc >= 14.7 && imc <= 18.6), ESCORE_Z: [-2, -1, 0, 1], DIAGNOSTICO: "Eutrofia"},
      {IMC: (imc) => (imc > 18.6 && imc <= 20.3), ESCORE_Z: [1, 2], DIAGNOSTICO: "Sobrepeso"},
      {IMC: (imc) => (imc > 20.3 && imc <= 22.1), ESCORE_Z: [2, 3], DIAGNOSTICO: "Obesidade"},
      {IMC: (imc) => (imc > 22.1), ESCORE_Z: [4], DIAGNOSTICO: "Obesidade grave"},
    ]
  },{ SEXO: 1, MESES: 10, 
    VARICAO_PERCENTIL:[
      {IMC: (imc) => (imc < 13.4), PERCENTIS: [-0.1], DIAGNOSTICO: "Magreza acentuada"},
      {IMC: (imc) => (imc >= 13.4 && imc < 14.7), PERCENTIS: [0.1, 3], DIAGNOSTICO: "Magreza"},
      {IMC: (imc) => (imc >= 14.7 && imc <= 18.6), PERCENTIS: [3, 5, 10, 15,50, 85], DIAGNOSTICO: "Eutrofia"},
      {IMC: (imc) => (imc > 18.6 && imc <= 19.9), PERCENTIS: [85, 97], DIAGNOSTICO: "Sobrepeso"},
      {IMC: (imc) => (imc > 19.9 && imc <= 22.1), PERCENTIS: [97, 99], DIAGNOSTICO: "Obesidade"},
      {IMC: (imc) => (imc > 22.1), PERCENTIS: [99], DIAGNOSTICO: "Obesidade grave"},
    ], 
    VARICAO_ESCORE_Z:[
      {IMC: (imc) => (imc < 13.5), ESCORE_Z: [-4], DIAGNOSTICO: "Magreza acentuada"},
      {IMC: (imc) => (imc >= 13.5 && imc < 14.6), ESCORE_Z: [-3, -2], DIAGNOSTICO: "Magreza"},
      {IMC: (imc) => (imc >= 14.6 && imc <= 18.5), ESCORE_Z: [-2, -1, 0, 1], DIAGNOSTICO: "Eutrofia"},
      {IMC: (imc) => (imc > 18.5 && imc <= 20.1), ESCORE_Z: [1, 2], DIAGNOSTICO: "Sobrepeso"},
      {IMC: (imc) => (imc > 20.1 && imc <= 22), ESCORE_Z: [2, 3], DIAGNOSTICO: "Obesidade"},
      {IMC: (imc) => (imc > 22), ESCORE_Z: [4], DIAGNOSTICO: "Obesidade grave"},
    ]
  },{ SEXO: 1, MESES: 11, 
    VARICAO_PERCENTIL:[
      {IMC: (imc) => (imc < 13.3), PERCENTIS: [-0.1], DIAGNOSTICO: "Magreza acentuada"},
      {IMC: (imc) => (imc >= 13.3 && imc < 14.6), PERCENTIS: [0.1, 3], DIAGNOSTICO: "Magreza"},
      {IMC: (imc) => (imc >= 14.6 && imc <= 18.4), PERCENTIS: [3, 5, 10, 15,50, 85], DIAGNOSTICO: "Eutrofia"},
      {IMC: (imc) => (imc > 18.4 && imc <= 19.8), PERCENTIS: [85, 97], DIAGNOSTICO: "Sobrepeso"},
      {IMC: (imc) => (imc > 19.8 && imc <= 22), PERCENTIS: [97, 99], DIAGNOSTICO: "Obesidade"},
      {IMC: (imc) => (imc > 22), PERCENTIS: [99], DIAGNOSTICO: "Obesidade grave"},
    ], 
    VARICAO_ESCORE_Z:[
      {IMC: (imc) => (imc < 13.4), ESCORE_Z: [-4], DIAGNOSTICO: "Magreza acentuada"},
      {IMC: (imc) => (imc >= 13.4 && imc < 14.5), ESCORE_Z: [-3, -2], DIAGNOSTICO: "Magreza"},
      {IMC: (imc) => (imc >= 14.5 && imc <= 18.4), ESCORE_Z: [-2, -1, 0, 1], DIAGNOSTICO: "Eutrofia"},
      {IMC: (imc) => (imc > 18.4 && imc <= 20), ESCORE_Z: [1, 2], DIAGNOSTICO: "Sobrepeso"},
      {IMC: (imc) => (imc > 20 && imc <= 21.8), ESCORE_Z: [2, 3], DIAGNOSTICO: "Obesidade"},
      {IMC: (imc) => (imc > 21.8), ESCORE_Z: [4], DIAGNOSTICO: "Obesidade grave"},
    ]
  },{ SEXO: 1, MESES: 12, 
    VARICAO_PERCENTIL:[
      {IMC: (imc) => (imc < 13.3), PERCENTIS: [-0.1], DIAGNOSTICO: "Magreza acentuada"},
      {IMC: (imc) => (imc >= 13.3 && imc < 14.5), PERCENTIS: [0.1, 3], DIAGNOSTICO: "Magreza"},
      {IMC: (imc) => (imc >= 14.5 && imc <= 18.3), PERCENTIS: [3, 5, 10, 15,50, 85], DIAGNOSTICO: "Eutrofia"},
      {IMC: (imc) => (imc > 18.3 && imc <= 19.6), PERCENTIS: [85, 97], DIAGNOSTICO: "Sobrepeso"},
      {IMC: (imc) => (imc > 19.6 && imc <= 21.8), PERCENTIS: [97, 99], DIAGNOSTICO: "Obesidade"},
      {IMC: (imc) => (imc > 21.8), PERCENTIS: [99], DIAGNOSTICO: "Obesidade grave"},
    ], 
    VARICAO_ESCORE_Z:[
      {IMC: (imc) => (imc < 13.4), ESCORE_Z: [-4], DIAGNOSTICO: "Magreza acentuada"},
      {IMC: (imc) => (imc >= 13.4 && imc < 14.4), ESCORE_Z: [-3, -2], DIAGNOSTICO: "Magreza"},
      {IMC: (imc) => (imc >= 14.4 && imc <= 18.2), ESCORE_Z: [-2, -1, 0, 1], DIAGNOSTICO: "Eutrofia"},
      {IMC: (imc) => (imc > 18.2 && imc <= 19.8), ESCORE_Z: [1, 2], DIAGNOSTICO: "Sobrepeso"},
      {IMC: (imc) => (imc > 19.8 && imc <= 21.6), ESCORE_Z: [2, 3], DIAGNOSTICO: "Obesidade"},
      {IMC: (imc) => (imc > 21.6), ESCORE_Z: [4], DIAGNOSTICO: "Obesidade grave"},
    ]
  },{ SEXO: 1, MESES: 13, 
    VARICAO_PERCENTIL:[
      {IMC: (imc) => (imc < 13.2), PERCENTIS: [-0.1], DIAGNOSTICO: "Magreza acentuada"},
      {IMC: (imc) => (imc >= 13.2 && imc < 14.4), PERCENTIS: [0.1, 3], DIAGNOSTICO: "Magreza"},
      {IMC: (imc) => (imc >= 14.4 && imc <= 18.1), PERCENTIS: [3, 5, 10, 15,50, 85], DIAGNOSTICO: "Eutrofia"},
      {IMC: (imc) => (imc > 18.1 && imc <= 19.5), PERCENTIS: [85, 97], DIAGNOSTICO: "Sobrepeso"},
      {IMC: (imc) => (imc > 19.5 && imc <= 21.6), PERCENTIS: [97, 99], DIAGNOSTICO: "Obesidade"},
      {IMC: (imc) => (imc > 21.6), PERCENTIS: [99], DIAGNOSTICO: "Obesidade grave"},
    ], 
    VARICAO_ESCORE_Z:[
      {IMC: (imc) => (imc < 13.3), ESCORE_Z: [-4], DIAGNOSTICO: "Magreza acentuada"},
      {IMC: (imc) => (imc >= 13.3 && imc < 14.3), ESCORE_Z: [-3, -2], DIAGNOSTICO: "Magreza"},
      {IMC: (imc) => (imc >= 14.3 && imc <= 18.1), ESCORE_Z: [-2, -1, 0, 1], DIAGNOSTICO: "Eutrofia"},
      {IMC: (imc) => (imc > 18.1 && imc <= 19.7), ESCORE_Z: [1, 2], DIAGNOSTICO: "Sobrepeso"},
      {IMC: (imc) => (imc > 19.7 && imc <= 21.5), ESCORE_Z: [2, 3], DIAGNOSTICO: "Obesidade"},
      {IMC: (imc) => (imc > 21.5), ESCORE_Z: [4], DIAGNOSTICO: "Obesidade grave"},
    ]
  },{ SEXO: 1, MESES: 14, 
    VARICAO_PERCENTIL:[
      {IMC: (imc) => (imc < 13.1), PERCENTIS: [-0.1], DIAGNOSTICO: "Magreza acentuada"},
      {IMC: (imc) => (imc >= 13.1 && imc < 14.3), PERCENTIS: [0.1, 3], DIAGNOSTICO: "Magreza"},
      {IMC: (imc) => (imc >= 14.3 && imc <= 18), PERCENTIS: [3, 5, 10, 15,50, 85], DIAGNOSTICO: "Eutrofia"},
      {IMC: (imc) => (imc > 18 && imc <= 19.3), PERCENTIS: [85, 97], DIAGNOSTICO: "Sobrepeso"},
      {IMC: (imc) => (imc > 19.3 && imc <= 21.5), PERCENTIS: [97, 99], DIAGNOSTICO: "Obesidade"},
      {IMC: (imc) => (imc > 21.5), PERCENTIS: [99], DIAGNOSTICO: "Obesidade grave"},
    ], 
    VARICAO_ESCORE_Z:[
      {IMC: (imc) => (imc < 13.2), ESCORE_Z: [-4], DIAGNOSTICO: "Magreza acentuada"},
      {IMC: (imc) => (imc >= 13.2 && imc < 14.2), ESCORE_Z: [-3, -2], DIAGNOSTICO: "Magreza"},
      {IMC: (imc) => (imc >= 14.2 && imc <= 18), ESCORE_Z: [-2, -1, 0, 1], DIAGNOSTICO: "Eutrofia"},
      {IMC: (imc) => (imc > 18 && imc <= 19.5), ESCORE_Z: [1, 2], DIAGNOSTICO: "Sobrepeso"},
      {IMC: (imc) => (imc > 19.5 && imc <= 21.3), ESCORE_Z: [2, 3], DIAGNOSTICO: "Obesidade"},
      {IMC: (imc) => (imc > 21.3), ESCORE_Z: [4], DIAGNOSTICO: "Obesidade grave"},
    ]
  },{ SEXO: 1, MESES: 15, 
    VARICAO_PERCENTIL:[
      {IMC: (imc) => (imc < 13), PERCENTIS: [-0.1], DIAGNOSTICO: "Magreza acentuada"},
      {IMC: (imc) => (imc >= 13 && imc < 14.2), PERCENTIS: [0.1, 3], DIAGNOSTICO: "Magreza"},
      {IMC: (imc) => (imc >= 14.2 && imc <= 17.9), PERCENTIS: [3, 5, 10, 15,50, 85], DIAGNOSTICO: "Eutrofia"},
      {IMC: (imc) => (imc > 17.9 && imc <= 19.2), PERCENTIS: [85, 97], DIAGNOSTICO: "Sobrepeso"},
      {IMC: (imc) => (imc > 19.2 && imc <= 21.3), PERCENTIS: [97, 99], DIAGNOSTICO: "Obesidade"},
      {IMC: (imc) => (imc > 21.3), PERCENTIS: [99], DIAGNOSTICO: "Obesidade grave"},
    ], 
    VARICAO_ESCORE_Z:[
      {IMC: (imc) => (imc < 13.1), ESCORE_Z: [-4], DIAGNOSTICO: "Magreza acentuada"},
      {IMC: (imc) => (imc >= 13.1 && imc < 14.1), ESCORE_Z: [-3, -2], DIAGNOSTICO: "Magreza"},
      {IMC: (imc) => (imc >= 14.1 && imc <= 17.8), ESCORE_Z: [-2, -1, 0, 1], DIAGNOSTICO: "Eutrofia"},
      {IMC: (imc) => (imc > 17.8 && imc <= 19.4), ESCORE_Z: [1, 2], DIAGNOSTICO: "Sobrepeso"},
      {IMC: (imc) => (imc > 19.4 && imc <= 21.2), ESCORE_Z: [2, 3], DIAGNOSTICO: "Obesidade"},
      {IMC: (imc) => (imc > 21.2), ESCORE_Z: [4], DIAGNOSTICO: "Obesidade grave"},
    ]
  },{ SEXO: 1, MESES: 16, 
    VARICAO_PERCENTIL:[
      {IMC: (imc) => (imc < 13), PERCENTIS: [-0.1], DIAGNOSTICO: "Magreza acentuada"},
      {IMC: (imc) => (imc >= 13 && imc < 14.2), PERCENTIS: [0.1, 3], DIAGNOSTICO: "Magreza"},
      {IMC: (imc) => (imc >= 14.2 && imc <= 17.8), PERCENTIS: [3, 5, 10, 15,50, 85], DIAGNOSTICO: "Eutrofia"},
      {IMC: (imc) => (imc > 17.8 && imc <= 19.1), PERCENTIS: [85, 97], DIAGNOSTICO: "Sobrepeso"},
      {IMC: (imc) => (imc > 19.1 && imc <= 21.2), PERCENTIS: [97, 99], DIAGNOSTICO: "Obesidade"},
      {IMC: (imc) => (imc > 21.2), PERCENTIS: [99], DIAGNOSTICO: "Obesidade grave"},
    ], 
    VARICAO_ESCORE_Z:[
      {IMC: (imc) => (imc < 13.1), ESCORE_Z: [-4], DIAGNOSTICO: "Magreza acentuada"},
      {IMC: (imc) => (imc >= 13.1 && imc < 14), ESCORE_Z: [-3, -2], DIAGNOSTICO: "Magreza"},
      {IMC: (imc) => (imc >= 14 && imc <= 17.7), ESCORE_Z: [-2, -1, 0, 1], DIAGNOSTICO: "Eutrofia"},
      {IMC: (imc) => (imc > 17.7 && imc <= 19.3), ESCORE_Z: [1, 2], DIAGNOSTICO: "Sobrepeso"},
      {IMC: (imc) => (imc > 19.3 && imc <= 21), ESCORE_Z: [2, 3], DIAGNOSTICO: "Obesidade"},
      {IMC: (imc) => (imc > 21), ESCORE_Z: [4], DIAGNOSTICO: "Obesidade grave"},
    ]
  },{ SEXO: 1, MESES: 17, 
    VARICAO_PERCENTIL:[
      {IMC: (imc) => (imc < 12.9), PERCENTIS: [-0.1], DIAGNOSTICO: "Magreza acentuada"},
      {IMC: (imc) => (imc >= 12.9 && imc < 14.1), PERCENTIS: [0.1, 3], DIAGNOSTICO: "Magreza"},
      {IMC: (imc) => (imc >= 14.1 && imc <= 17.6), PERCENTIS: [3, 5, 10, 15,50, 85], DIAGNOSTICO: "Eutrofia"},
      {IMC: (imc) => (imc > 17.6 && imc <= 18.9), PERCENTIS: [85, 97], DIAGNOSTICO: "Sobrepeso"},
      {IMC: (imc) => (imc > 18.9 && imc <= 21.1), PERCENTIS: [97, 99], DIAGNOSTICO: "Obesidade"},
      {IMC: (imc) => (imc > 21.1), PERCENTIS: [99], DIAGNOSTICO: "Obesidade grave"},
    ], 
    VARICAO_ESCORE_Z:[
      {IMC: (imc) => (imc < 13), ESCORE_Z: [-4], DIAGNOSTICO: "Magreza acentuada"},
      {IMC: (imc) => (imc >= 13 && imc < 13.9), ESCORE_Z: [-3, -2], DIAGNOSTICO: "Magreza"},
      {IMC: (imc) => (imc >= 13.9 && imc <= 17.6), ESCORE_Z: [-2, -1, 0, 1], DIAGNOSTICO: "Eutrofia"},
      {IMC: (imc) => (imc > 17.6 && imc <= 19.1), ESCORE_Z: [1, 2], DIAGNOSTICO: "Sobrepeso"},
      {IMC: (imc) => (imc > 19.1 && imc <= 20.9), ESCORE_Z: [2, 3], DIAGNOSTICO: "Obesidade"},
      {IMC: (imc) => (imc > 20.9), ESCORE_Z: [4], DIAGNOSTICO: "Obesidade grave"},
    ]
  },{ SEXO: 1, MESES: 18, 
    VARICAO_PERCENTIL:[
      {IMC: (imc) => (imc < 12.8), PERCENTIS: [-0.1], DIAGNOSTICO: "Magreza acentuada"},
      {IMC: (imc) => (imc >= 12.8 && imc < 14), PERCENTIS: [0.1, 3], DIAGNOSTICO: "Magreza"},
      {IMC: (imc) => (imc >= 14 && imc <= 17.5), PERCENTIS: [3, 5, 10, 15,50, 85], DIAGNOSTICO: "Eutrofia"},
      {IMC: (imc) => (imc > 17.5 && imc <= 18.8), PERCENTIS: [85, 97], DIAGNOSTICO: "Sobrepeso"},
      {IMC: (imc) => (imc > 18.8 && imc <= 21), PERCENTIS: [97, 99], DIAGNOSTICO: "Obesidade"},
      {IMC: (imc) => (imc > 21), PERCENTIS: [99], DIAGNOSTICO: "Obesidade grave"},
    ], 
    VARICAO_ESCORE_Z:[
      {IMC: (imc) => (imc < 12.9), ESCORE_Z: [-4], DIAGNOSTICO: "Magreza acentuada"},
      {IMC: (imc) => (imc >= 12.9 && imc < 13.9), ESCORE_Z: [-3, -2], DIAGNOSTICO: "Magreza"},
      {IMC: (imc) => (imc >= 13.9 && imc <= 17.5), ESCORE_Z: [-2, -1, 0, 1], DIAGNOSTICO: "Eutrofia"},
      {IMC: (imc) => (imc > 17.5 && imc <= 19), ESCORE_Z: [1, 2], DIAGNOSTICO: "Sobrepeso"},
      {IMC: (imc) => (imc > 19 && imc <= 20.8), ESCORE_Z: [2, 3], DIAGNOSTICO: "Obesidade"},
      {IMC: (imc) => (imc > 20.8), ESCORE_Z: [4], DIAGNOSTICO: "Obesidade grave"},
    ]
  },{ SEXO: 1, MESES: 19, 
    VARICAO_PERCENTIL:[
      {IMC: (imc) => (imc < 12.8), PERCENTIS: [-0.1], DIAGNOSTICO: "Magreza acentuada"},
      {IMC: (imc) => (imc >= 12.8 && imc < 13.9), PERCENTIS: [0.1, 3], DIAGNOSTICO: "Magreza"},
      {IMC: (imc) => (imc >= 13.9 && imc <= 17.4), PERCENTIS: [3, 5, 10, 15,50, 85], DIAGNOSTICO: "Eutrofia"},
      {IMC: (imc) => (imc > 17.4 && imc <= 18.7), PERCENTIS: [85, 97], DIAGNOSTICO: "Sobrepeso"},
      {IMC: (imc) => (imc > 18.7 && imc <= 20.8), PERCENTIS: [97, 99], DIAGNOSTICO: "Obesidade"},
      {IMC: (imc) => (imc > 20.8), PERCENTIS: [99], DIAGNOSTICO: "Obesidade grave"},
    ], 
    VARICAO_ESCORE_Z:[
      {IMC: (imc) => (imc < 12.9), ESCORE_Z: [-4], DIAGNOSTICO: "Magreza acentuada"},
      {IMC: (imc) => (imc >= 12.9 && imc < 13.8), ESCORE_Z: [-3, -2], DIAGNOSTICO: "Magreza"},
      {IMC: (imc) => (imc >= 13.8 && imc <= 17.4), ESCORE_Z: [-2, -1, 0, 1], DIAGNOSTICO: "Eutrofia"},
      {IMC: (imc) => (imc > 17.4 && imc <= 18.9), ESCORE_Z: [1, 2], DIAGNOSTICO: "Sobrepeso"},
      {IMC: (imc) => (imc > 18.9 && imc <= 20.7), ESCORE_Z: [2, 3], DIAGNOSTICO: "Obesidade"},
      {IMC: (imc) => (imc > 20.7), ESCORE_Z: [4], DIAGNOSTICO: "Obesidade grave"},
    ]
  },{ SEXO: 1, MESES: 20, 
    VARICAO_PERCENTIL:[
      {IMC: (imc) => (imc < 12.7), PERCENTIS: [-0.1], DIAGNOSTICO: "Magreza acentuada"},
      {IMC: (imc) => (imc >= 12.7 && imc < 13.9), PERCENTIS: [0.1, 3], DIAGNOSTICO: "Magreza"},
      {IMC: (imc) => (imc >= 13.9 && imc <= 17.4), PERCENTIS: [3, 5, 10, 15,50, 85], DIAGNOSTICO: "Eutrofia"},
      {IMC: (imc) => (imc > 17.4 && imc <= 18.6), PERCENTIS: [85, 97], DIAGNOSTICO: "Sobrepeso"},
      {IMC: (imc) => (imc > 18.6 && imc <= 20.7), PERCENTIS: [97, 99], DIAGNOSTICO: "Obesidade"},
      {IMC: (imc) => (imc > 20.7), PERCENTIS: [99], DIAGNOSTICO: "Obesidade grave"},
    ], 
    VARICAO_ESCORE_Z:[
      {IMC: (imc) => (imc < 12.8), ESCORE_Z: [-4], DIAGNOSTICO: "Magreza acentuada"},
      {IMC: (imc) => (imc >= 12.8 && imc < 13.7), ESCORE_Z: [-3, -2], DIAGNOSTICO: "Magreza"},
      {IMC: (imc) => (imc >= 13.7 && imc <= 17.3), ESCORE_Z: [-2, -1, 0, 1], DIAGNOSTICO: "Eutrofia"},
      {IMC: (imc) => (imc > 17.3 && imc <= 18.8), ESCORE_Z: [1, 2], DIAGNOSTICO: "Sobrepeso"},
      {IMC: (imc) => (imc > 18.8 && imc <= 20.6), ESCORE_Z: [2, 3], DIAGNOSTICO: "Obesidade"},
      {IMC: (imc) => (imc > 20.6), ESCORE_Z: [4], DIAGNOSTICO: "Obesidade grave"},
    ]
  },{ SEXO: 1, MESES: 21, 
    VARICAO_PERCENTIL:[
      {IMC: (imc) => (imc < 12.7), PERCENTIS: [-0.1], DIAGNOSTICO: "Magreza acentuada"},
      {IMC: (imc) => (imc >= 12.7 && imc < 13.8), PERCENTIS: [0.1, 3], DIAGNOSTICO: "Magreza"},
      {IMC: (imc) => (imc >= 13.8 && imc <= 17.3), PERCENTIS: [3, 5, 10, 15,50, 85], DIAGNOSTICO: "Eutrofia"},
      {IMC: (imc) => (imc > 17.3 && imc <= 18.6), PERCENTIS: [85, 97], DIAGNOSTICO: "Sobrepeso"},
      {IMC: (imc) => (imc > 18.6 && imc <= 20.6), PERCENTIS: [97, 99], DIAGNOSTICO: "Obesidade"},
      {IMC: (imc) => (imc > 20.6), PERCENTIS: [99], DIAGNOSTICO: "Obesidade grave"},
    ], 
    VARICAO_ESCORE_Z:[
      {IMC: (imc) => (imc < 12.8), ESCORE_Z: [-4], DIAGNOSTICO: "Magreza acentuada"},
      {IMC: (imc) => (imc >= 12.8 && imc < 13.7), ESCORE_Z: [-3, -2], DIAGNOSTICO: "Magreza"},
      {IMC: (imc) => (imc >= 13.7 && imc <= 17.2), ESCORE_Z: [-2, -1, 0, 1], DIAGNOSTICO: "Eutrofia"},
      {IMC: (imc) => (imc > 17.2 && imc <= 18.7), ESCORE_Z: [1, 2], DIAGNOSTICO: "Sobrepeso"},
      {IMC: (imc) => (imc > 18.7 && imc <= 20.5), ESCORE_Z: [2, 3], DIAGNOSTICO: "Obesidade"},
      {IMC: (imc) => (imc > 20.5), ESCORE_Z: [4], DIAGNOSTICO: "Obesidade grave"},
    ]
  },{ SEXO: 1, MESES: 22, 
    VARICAO_PERCENTIL:[
      {IMC: (imc) => (imc < 12.7), PERCENTIS: [-0.1], DIAGNOSTICO: "Magreza acentuada"},
      {IMC: (imc) => (imc >= 12.7 && imc < 13.8), PERCENTIS: [0.1, 3], DIAGNOSTICO: "Magreza"},
      {IMC: (imc) => (imc >= 13.8 && imc <= 17.2), PERCENTIS: [3, 5, 10, 15,50, 85], DIAGNOSTICO: "Eutrofia"},
      {IMC: (imc) => (imc > 17.2 && imc <= 18.5), PERCENTIS: [85, 97], DIAGNOSTICO: "Sobrepeso"},
      {IMC: (imc) => (imc > 18.5 && imc <= 20.6), PERCENTIS: [97, 99], DIAGNOSTICO: "Obesidade"},
      {IMC: (imc) => (imc > 20.6), PERCENTIS: [99], DIAGNOSTICO: "Obesidade grave"},
    ], 
    VARICAO_ESCORE_Z:[
      {IMC: (imc) => (imc < 12.7), ESCORE_Z: [-4], DIAGNOSTICO: "Magreza acentuada"},
      {IMC: (imc) => (imc >= 12.7 && imc < 13.6), ESCORE_Z: [-3, -2], DIAGNOSTICO: "Magreza"},
      {IMC: (imc) => (imc >= 13.6 && imc <= 17.2), ESCORE_Z: [-2, -1, 0, 1], DIAGNOSTICO: "Eutrofia"},
      {IMC: (imc) => (imc > 17.2 && imc <= 18.7), ESCORE_Z: [1, 2], DIAGNOSTICO: "Sobrepeso"},
      {IMC: (imc) => (imc > 18.7 && imc <= 20.4), ESCORE_Z: [2, 3], DIAGNOSTICO: "Obesidade"},
      {IMC: (imc) => (imc > 20.4), ESCORE_Z: [4], DIAGNOSTICO: "Obesidade grave"},
    ]
  },{ SEXO: 1, MESES: 23, 
    VARICAO_PERCENTIL:[
      {IMC: (imc) => (imc < 12.6), PERCENTIS: [-0.1], DIAGNOSTICO: "Magreza acentuada"},
      {IMC: (imc) => (imc >= 12.6 && imc < 13.7), PERCENTIS: [0.1, 3], DIAGNOSTICO: "Magreza"},
      {IMC: (imc) => (imc >= 13.7 && imc <= 17.1), PERCENTIS: [3, 5, 10, 15,50, 85], DIAGNOSTICO: "Eutrofia"},
      {IMC: (imc) => (imc > 17.1 && imc <= 18.4), PERCENTIS: [85, 97], DIAGNOSTICO: "Sobrepeso"},
      {IMC: (imc) => (imc > 18.4 && imc <= 20.5), PERCENTIS: [97, 99], DIAGNOSTICO: "Obesidade"},
      {IMC: (imc) => (imc > 20.5), PERCENTIS: [99], DIAGNOSTICO: "Obesidade grave"},
    ], 
    VARICAO_ESCORE_Z:[
      {IMC: (imc) => (imc < 12.7), ESCORE_Z: [-4], DIAGNOSTICO: "Magreza acentuada"},
      {IMC: (imc) => (imc >= 12.7 && imc < 13.6), ESCORE_Z: [-3, -2], DIAGNOSTICO: "Magreza"},
      {IMC: (imc) => (imc >= 13.6 && imc <= 17.1), ESCORE_Z: [-2, -1, 0, 1], DIAGNOSTICO: "Eutrofia"},
      {IMC: (imc) => (imc > 17.1 && imc <= 18.6), ESCORE_Z: [1, 2], DIAGNOSTICO: "Sobrepeso"},
      {IMC: (imc) => (imc > 18.6 && imc <= 20.3), ESCORE_Z: [2, 3], DIAGNOSTICO: "Obesidade"},
      {IMC: (imc) => (imc > 20.3), ESCORE_Z: [4], DIAGNOSTICO: "Obesidade grave"},
    ]
  },{ SEXO: 1, MESES: 24, 
    VARICAO_PERCENTIL:[
      {IMC: (imc) => (imc < 12.6), PERCENTIS: [-0.1], DIAGNOSTICO: "Magreza acentuada"},
      {IMC: (imc) => (imc >= 12.6 && imc < 13.7), PERCENTIS: [0.1, 3], DIAGNOSTICO: "Magreza"},
      {IMC: (imc) => (imc >= 13.7 && imc <= 17.1), PERCENTIS: [3, 5, 10, 15,50, 85], DIAGNOSTICO: "Eutrofia"},
      {IMC: (imc) => (imc > 17.1 && imc <= 18.3), PERCENTIS: [85, 97], DIAGNOSTICO: "Sobrepeso"},
      {IMC: (imc) => (imc > 18.3 && imc <= 20.4), PERCENTIS: [97, 99], DIAGNOSTICO: "Obesidade"},
      {IMC: (imc) => (imc > 20.4), PERCENTIS: [99], DIAGNOSTICO: "Obesidade grave"},
    ], 
    VARICAO_ESCORE_Z:[
      {IMC: (imc) => (imc < 12.7), ESCORE_Z: [-4], DIAGNOSTICO: "Magreza acentuada"},
      {IMC: (imc) => (imc >= 12.7 && imc < 13.6), ESCORE_Z: [-3, -2], DIAGNOSTICO: "Magreza"},
      {IMC: (imc) => (imc >= 13.6 && imc <= 17), ESCORE_Z: [-2, -1, 0, 1], DIAGNOSTICO: "Eutrofia"},
      {IMC: (imc) => (imc > 17 && imc <= 18.5), ESCORE_Z: [1, 2], DIAGNOSTICO: "Sobrepeso"},
      {IMC: (imc) => (imc > 18.5 && imc <= 20.3), ESCORE_Z: [2, 3], DIAGNOSTICO: "Obesidade"},
      {IMC: (imc) => (imc > 20.3), ESCORE_Z: [4], DIAGNOSTICO: "Obesidade grave"},
    ]
  }
];

export {
  TABELA_PERCENTIL_IMC_MESES_MASCULINO
}