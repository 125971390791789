import utilsData from "@/utils/data";

function obter(paciente) {
  return [
    {
      nome: "Paciente",
      filhas: [
        {
          nome: "Identificador",
          atributo: "Id_Paciente",
          titulo: "Identificador do paciente",
          inline: true,
          conteudo: () => {
            return paciente.id || '';
          }
        },
        {
          nome: "Nome",
          atributo: "Nome_Paciente",
          titulo: "Nome do paciente",
          inline: true,
          conteudo: () => {
            return paciente.nome || '';
          }
        },
        {
          nome: "Sexo",
          atributo: "Sexo",
          titulo: "Sexo",
          inline: true,
          conteudo: () => {
            return paciente.sexo.replace("M", "Masculino").replace("F", "Feminino") || '';
          }
        },
        {
          nome: "Data de Nascimento",
          atributo: "Data_De_Nascimento",
          titulo: "Data de Nascimento",
          inline: true,
          conteudo: () => {
            return paciente.dataNascimento && utilsData.aplicarMascaraEmDataIso(paciente.dataNascimento) || '';
          }
        },
        {
          nome: "Idade em anos",
          atributo: "Idade_Em_Anos",
          titulo: "Idade em anos",
          inline: true,
          conteudo: () => {
            return paciente.dataNascimento && utilsData.calculaIdade(utilsData.aplicarMascaraEmDataIso(paciente.dataNascimento)) || '';
          }
        },
        {
          nome: "Idade em meses",
          atributo: "Idade_Em_Meses",
          titulo: "Idade em meses",
          inline: true,
          conteudo: () => {
            return paciente.dataNascimento && utilsData.calcularIdadeMes(utilsData.aplicarMascaraEmDataIso(paciente.dataNascimento)) || '';
          }
        },
        {
          nome: "Idade em anos, meses e dias",
          atributo: "Idade_Em_Anos_Meses_Dias",
          titulo: "Idade em anos, meses e dias",
          inline: true,
          conteudo: () => {
            return paciente.dataNascimento && utilsData.calcularIdadeAnoMesesDias(utilsData.aplicarMascaraEmDataIso(paciente.dataNascimento)) || '';
          }
        },
      ]
    }
  ]
}

export default { obter }