import api from '../api'

function obterAntropometriaPorPaciente(idPaciente){
  return  new Promise((resolve, reject) =>{
    return api.get(`/pacientes/${idPaciente}/antropometrias/infantil`)
    .then(response => resolve(response))
    .catch(error => reject(error))
  });
}

function cadastrar(antropometria){
  antropometria = antropometria || {};
  return  new Promise((resolve, reject) =>{
    return api.post(`/pacientes/${antropometria.paciente.id}/antropometrias/infantil`, antropometria)
    .then(response => resolve(response))
    .catch(error => reject(error))
  });
}

function deletar(antropometria){
  antropometria = antropometria || {};
  return  new Promise((resolve, reject) =>{
    return api.delete(`/pacientes/${antropometria.paciente.id}/antropometrias/infantil/${antropometria.id}`)
    .then(response => resolve(response))
    .catch(error => reject(error))
  });
}

function atualizar(antropometria){
  antropometria = antropometria || {};
  return  new Promise((resolve, reject) =>{
    return api.put(`/pacientes/${antropometria.paciente.id}/antropometrias/infantil/${antropometria.id}`, antropometria)
    .then(response => resolve(response))
    .catch(error => reject(error))
  });
}

export default {
  obterAntropometriaPorPaciente,
  cadastrar,
  deletar,
  atualizar,
}