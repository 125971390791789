/*
*  - IMC (kg/m2) para idade (meses) segundo curvas da OMS (2007) (1: sexo masculino; 2: sexo feminino)
*/
import { TABELA_PERCENTIL_IMC_MESES_MASCULINO } from './tabela-percentil-por-imc-meses-masculino';
import { TABELA_PERCENTIL_IMC_MESES_FEMININO } from './tabela-percentil-por-imc-meses-feminino';

const TABELA_PERCENTIL_IMC_MESES = TABELA_PERCENTIL_IMC_MESES_MASCULINO.concat(TABELA_PERCENTIL_IMC_MESES_FEMININO);

export {
  TABELA_PERCENTIL_IMC_MESES
}
