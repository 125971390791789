<template>
  <div class="ma-5">
    <v-snackbar
      v-model="snackbar"
      :vertical="false"
      :timeout="timeout"
      top="top"
      :color="tipo"
      elevation="0"
      multi-line
      right
    >
      {{ mensagem }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar = false">OK</v-btn>
      </template>
    </v-snackbar>

    <!-- Skeleton Loader -->
    <v-row v-show="loading">
      <v-col cols="12" lg="12">
        <v-skeleton-loader type="button" min-width="100px"></v-skeleton-loader>
        <v-skeleton-loader
          type="table-thead"
          min-width="100px"
        ></v-skeleton-loader>
        <v-skeleton-loader
          type="table-tbody"
          min-width="100px"
        ></v-skeleton-loader>
        <v-skeleton-loader
          type="table-tfoot"
          min-width="100px"
        ></v-skeleton-loader>
      </v-col>
    </v-row>

    <!-- Vazia -->
    <v-row v-show="!this.modelos.length && !loading">
      <TelaVazia
        :imagemSrc="'modelos.svg'"
        :titulo="'Não há modelos cadastrados'"
        :subtitulo="'Adicione um modelo para iniciar documentos'"
        :textoBotaoPrimario="'Cadastrar modelo'"
        :eventoBotaoPrimario="cadastrarModelo"
      >
      </TelaVazia>
    </v-row>

    <v-row v-show="!loading">
      <v-col cols="12" lg="12">
        <h1>Controle de Modelos</h1>
        <v-divider></v-divider>
      </v-col>

      <v-col cols="12" lg="10">
        <v-btn class="btn-primario ml-0" text @click="cadastrarModelo">
          <v-icon class="mr-2 icone">mdi-plus</v-icon>
          Adicionar</v-btn
        >
      </v-col>

      <v-col cols="12" lg="2">
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Pesquisar"
          single-line
          hide-details
        ></v-text-field>
      </v-col>

      <v-col cols="12" lg="12">
        <v-card class="card-cuitatto">
          <v-data-table
            :headers="colunas"
            :items="modelos"
            :search="search"
            sort-by="guidAssinante"
            sort-asc
            :items-per-page="10"
          >
            <template v-slot:item.idTipoModelo="{ item }">
              {{ item.tipoDeModelo.descricao }}
            </template>
            <template v-slot:item.criadoEm="{ item }">
              {{ item.criadoEm | data }}
            </template>
            <template v-slot:item.guidAssinante="{ item }">
              <span v-if="item.guidAssinante == 'cuitatto_software'"
                ><v-chip small label color="#e0c3ae"
                  >Modelo Padrão</v-chip
                ></span
              >
              <span v-else
                ><v-chip small label color="light-blue lighten-4"
                  >Modelo personalizado</v-chip
                ></span
              >
            </template>
            <template v-slot:item.favorito="{ item }">
              <span v-if="item.favorito"
                ><v-icon class="mr-2 icone-favorito">mdi-heart</v-icon></span
              >
              <span v-else
                ><v-icon class="mr-2 icone-favorito"
                  >mdi-heart-outline</v-icon
                ></span
              >
            </template>

            <template v-slot:item.acoes="{ item }">
              <td class="coluna-botoes text-right">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-show="item.guidAssinante != guidPadraoDoSistema"
                      icon
                      text
                      @click="editarModelo(item)"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <edit-2-icon
                        size="1.3x"
                        class="custom-class"
                      ></edit-2-icon>
                    </v-btn>
                  </template>
                  <span>Editar</span>
                </v-tooltip>

                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-show="item.guidAssinante != guidPadraoDoSistema"
                      icon
                      text
                      @click="excluirModelo(item)"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <trash-icon size="1.3x" class="custom-class">
                      </trash-icon>
                    </v-btn>
                  </template>
                  <span>Excluir </span>
                </v-tooltip>

                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      text
                      @click="preVisualizarModelo(item)"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <eye-icon size="1.3x" class="custom-class"> </eye-icon>
                    </v-btn>
                  </template>
                  <span>Visualizar </span>
                </v-tooltip>
              </td>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog
      persistent
      scrollable
      min-height="800px"
      max-width="800px"
      v-model="modalPreVisualizarModelo"
    >
      <v-card>
        <v-card-title>{{ modelo.descricao }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pa-4"
          ><span v-html="modelo.conteudo"></span
        ></v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text class="btn-secundario" @click="fechar()"> Fechar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <janela-confirmacao
      v-model="abrirJanelaConfirmacao"
      :callbackPrimario="callbackConfirmarExclusao"
      :mensagem="mensagemDeExclusao"
    />
  </div>
</template>
<script>
// Apis
import apiTipoDeModelos from "@/api/documento/tipo-de-modelo-api";
import apiModelos from "@/api/documento/modelo-api";

import { GUID_DEFAULT } from "@/constants/geral-constantes";

// Models
import TipoDeModeloModel from "@/model/documento/tipo-de-modelo-model";
import ModeloModel from "@/model/documento/modelo-de-documento-model";

// Mixins
import mixinMensagem from "../../mixin/mixin-mensagem";
import mixinAutorizacao from "../../mixin/mixin-autorizacao";

// Components
import TelaVazia from "@/components/comum/TelaVazia";
import JanelaConfirmacao from "@/components/comum/JanelaConfirmacao";

// Dependências
import moment from "moment";
import mixpanel from "mixpanel-browser";

export default {
  name: "Modelos",
  components: {
    JanelaConfirmacao,
    TelaVazia,
  },
  mixins: [mixinMensagem, mixinAutorizacao],
  data() {
    return {
      search: "",
      texto: "",
      guidPadraoDoSistema: GUID_DEFAULT,
      loading: true,
      modalPreVisualizarModelo: false,
      desabilitarBotoes: false,
      tiposDeModelos: [],
      modelos: [],
      modelo: new ModeloModel(),
      abrirJanelaConfirmacao: false,
      mensagemDeExclusao: "",
      colunas: [
        { text: "Nome", value: "descricao", align: "left", sortable: true },
        { text: "Tipo", value: "idTipoModelo", align: "left", sortable: true },
        {
          text: "Modelo",
          value: "guidAssinante",
          align: "center",
          sortable: true,
        },
        {
          text: "Data de cadastro",
          value: "criadoEm",
          align: "center",
          sortable: true,
        },
        {
          text: "Favorito",
          value: "favorito",
          align: "center",
          sortable: true,
        },
        { text: "", value: "acoes", sortable: false },
      ],
    };
  },
  filters: {
    data(valor) {
      return moment(valor).format("DD/MM/YYYY");
    },
  },
  mounted() {
    this.obterTiposDeModelos();
    this.obterModelos();
    mixpanel.track("page_view", { nome_pagina: "modelos_documentos" });
  },
  methods: {
    obterModelos() {
      apiModelos
        .obterTodos()
        .then((resposta) => {
          this.modelos = resposta.data.map((m) => new ModeloModel(m));
        })
        .catch((erro) => {
          this.dispararMensagem(
            (erro.response && erro.response.data.mensagem) || erro,
            "error"
          );
          this.loading = false;
        })
        .finally(() => {
          this.finalizarCarregamento();
        });
    },

    obterTiposDeModelos() {
      apiTipoDeModelos
        .obterTodos()
        .then((resposta) => {
          this.tiposDeModelos = resposta.data.map(
            (t) => new TipoDeModeloModel(t)
          );
        })
        .catch(() => {
          /* Não fazer nada, é responsabilidade do sistema */
        });
    },

    carregando() {
      this.desabilitarBotoes = true;
      this.loading = true;
    },

    finalizarCarregamento() {
      this.loading = false;
      this.desabilitarBotoes = false;
    },

    fecharModais() {
      this.modalCadastroEdicaoModelo = false;
      this.modalPreVisualizarModelo = false;
      this.abrirJanelaConfirmacao = false;
    },

    editarModelo(item) {
      mixpanel.track("click", {
        nome_pagina: "modelos_documentos",
        nome_botao: "btn_editar_modelo",
      });
      this.$router
        .push({
          name: "controle-modelo-edicao",
          params: { id: item.id },
        })
        .catch(() => {});
    },

    excluirModelo(item) {
      this.modelo = item;
      mixpanel.track("click", {
        nome_pagina: "modelos_documentos",
        nome_botao: "btn_excluir_modelo",
      });
      this.abrirJanelaConfirmacao = true;
      this.mensagemDeExclusao = `Deseja excluir o modelo <b>${item.descricao}</b>? <br> <br> Não será possivel utilizar este modelo em outro tipo de documento novamente.</b>`;
    },

    preVisualizarModelo(item) {
      this.modelo = item;
      mixpanel.track("click", {
        nome_pagina: "modelos_documentos",
        nome_botao: "btn_pre_visualizar_modelo",
      });
      this.modalPreVisualizarModelo = true;
    },
    cadastrarModelo() {
      mixpanel.track("click", {
        nome_pagina: "modelos_documentos",
        nome_botao: "btn_adicionar_modelo",
      });
      this.$router
        .push({
          name: "controle-modelo-cadastro",
        })
        .catch(() => {});
    },

    fechar() {
      mixpanel.track("click", {
        nome_pagina: "modelos_documentos",
        nome_botao: "btn_fechar_pre_visualizar_modelo",
      });
      this.modalPreVisualizarModelo = false;
    },

    // Callbacks
    callbackConfirmarExclusao() {
      this.carregando();
      let id = this.modelo.id;
      apiModelos
        .deletar(id)
        .then(() => {
          this.dispararMensagem("Modelo excluido com sucesso", "success");
          this.atualizarModeloNaLista(this.modelo, true);
        })
        .catch((erro) => {
          this.dispararMensagem(erro.response.data.mensagem, "error");
        })
        .finally(() => {
          this.fecharModais();
          this.finalizarCarregamento();
        });
    },

    atualizarModeloNaLista(modelo, deletarDaLista) {
      var index = this.modelos
        .map((p) => parseInt(p.id))
        .indexOf(parseInt(modelo.id));
      !deletarDaLista
        ? this.modelos.splice(index, 1, modelo) // Atualiza
        : this.modelos.splice(index, 1); // Deleta
    },
  },
};
</script>

<style lang="scss" scoped>
h1 {
  color: $cor_primaria;
}
.icone-favorito {
  color: $cor_primaria;
}

.tabela-expandivel {
  padding: 50px;
  height: 200px;
  overflow-y: scroll;
}
</style> 