<template>
  <v-list nav>
    <v-list-item-group class="pt-15">
      <!-- <v-list-item link :to="'/'">
        <v-list-item-action >
          <activity-icon size="1.5x" class="custom-class"></activity-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>Dashboard</v-list-item-title>
        </v-list-item-content>
      </v-list-item> -->

      <v-list-item
        v-if="permissaoParaAcessarMenuPelaRota(acesso.AGENDA.descricao)"
        @click="acessarAgenda()"
      >
        <v-list-item-action>
          <calendar-icon size="1.5x" class="custom-class"></calendar-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>Agenda</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item
        v-if="permissaoParaAcessarMenuPelaRota(acesso.PACIENTES.descricao)"
        @click="acessarPacientes()"
      >
        <v-list-item-action>
          <users-icon size="1.5x" class="custom-class"></users-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>Pacientes</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item
        v-if="permissaoParaAcessarMenuPelaRota(acesso.PACIENTES.descricao)"
        @click="acessarModelos()"
      >
        <v-list-item-action>
          <file-text-icon size="1.5x" class="custom-class"></file-text-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>Modelos</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item
        v-if="permissaoParaAcessarMenuPelaRota(acesso.USUARIOS.descricao)"
        @click="acessarUsuarios()"
      >
        <v-list-item-action>
          <user-icon size="1.5x" class="custom-class"></user-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>Usuários</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list-item-group>
  </v-list>
</template>
<script>
import mixinAutorizacao from "@/mixin/mixin-autorizacao";
import { ROTAS_ACESSO } from "@/constants/geral-constantes";

// Tracking
import mixpanel from "mixpanel-browser";

export default {
  name: "MenuStatic",
  mixins: [mixinAutorizacao],
  data() {
    return {
      acesso: ROTAS_ACESSO,
    };
  },
  methods: {
    acessarAgenda() {
      mixpanel.track("click", {
        nome_botao: "menu_agenda",
      });
      this.$router
        .push({
          name: "agenda",
        })
        .catch(() => {});
    },
    acessarModelos() {
      mixpanel.track("click", {
        nome_botao: "menu_modelos",
      });
      this.$router
        .push({
          name: "controle-modelos",
        })
        .catch(() => {});
    },
    acessarUsuarios() {
      mixpanel.track("click", {
        nome_botao: "menu_usuarios",
      });
      this.$router
        .push({
          name: "controle-usuario",
        })
        .catch(() => {});
    },
    acessarPacientes() {
      mixpanel.track("click", {
        nome_botao: "menu_pacientes",
      });
      this.$router
        .push({
          name: "controle-paciente",
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang="scss" scoped>
.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled),
.v-icon {
  color: $cor_terciaria;
}
.v-item--active.v-list-item--active.v-list-item.v-list-item--link,
.v-list .v-list-item--active .v-icon {
  color: $cor_primaria;
}
</style>