import api from '../api'

function obterAntropometriaPorGestacao(idGestacao){
  return  new Promise((resolve, reject) =>{
    return api.get(`/gestacoes/${idGestacao}/antropometrias`)
    .then(response => resolve(response))
    .catch(error => reject(error))
  });
}

function cadastrar(antropometria){
  antropometria = antropometria || {};
  return  new Promise((resolve, reject) =>{
    return api.post(`/gestacoes/${antropometria.gestacao.id}/antropometrias`, antropometria)
    .then(response => resolve(response))
    .catch(error => reject(error))
  });
}

function deletar(antropometria){
  antropometria = antropometria || {};
  return  new Promise((resolve, reject) =>{
    return api.delete(`/gestacoes/${antropometria.gestacao.id}/antropometrias/${antropometria.id}`)
    .then(response => resolve(response))
    .catch(error => reject(error))
  });
}

function atualizar(antropometria){
  antropometria = antropometria || {};
  return  new Promise((resolve, reject) =>{
    return api.put(`/gestacoes/${antropometria.gestacao.id}/antropometrias/${antropometria.id}`, antropometria)
    .then(response => resolve(response))
    .catch(error => reject(error))
  });
}

export default {
  obterAntropometriaPorGestacao,
  cadastrar,
  deletar,
  atualizar,
}