'use strict';
/*
  Esse arquivo armazena os valores de referencia para cada gráfico masculino.
  OBS: A classificação máxima (OBESIDADE) precisa receber valor da classificação anterior a ela, para que o gráfico seja montado corretamente.
*/


// Segundo a OMS, até 2 anos não é chamado como estatura, é chamado como comprimento.. precisamos tratar isso em todo sistema (inclusive no front)

const INFANTIL_MASCULINO_IMC_PARA_IDADE_0_ATE_2_ANOS = {
  MESES: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24],
  MAGREZA_ACENTUADA: [10.0, 11.1, 12.3, 12.9, 13.2, 13.3, 13.4, 13.5, 13.4, 13.4, 13.3, 13.2, 13.2, 13.1, 13.0, 12.9, 12.9, 12.8, 12.7, 12.7, 12.6, 12.6, 12.6, 12.5, 12.5],
  MAGREZA: [11.3, 12.6, 13.8, 14.4, 14.7, 14.8, 14.9, 14.9, 14.9, 14.8, 14.7, 14.6, 14.5, 14.4, 14.3, 14.2, 14.2, 14.1, 14.0, 13.9, 13.9, 13.8, 13.8, 13.7, 13.7],
  EUTROFIA: [14.8, 16.4, 17.8, 18.5, 18.7, 18.9, 18.9, 18.9, 18.8, 18.7, 18.6, 18.4, 18.3, 18.1, 18.0, 17.9, 17.8, 17.6, 17.5, 17.4, 17.4, 17.3, 17.2, 17.1, 17.1],
  RISCO_SOBREPESO: [16.1, 17.6, 19.2, 19.8, 20.1, 20.2, 20.3, 20.3, 20.2, 20.1, 19.9, 19.8, 19.6, 19.5, 19.3, 19.2, 19.1, 18.9, 18.8, 18.7, 18.6, 18.6, 18.5, 18.4, 18.3],
  SOBREPESO: [18.3, 19.6, 21.3, 22.0, 22.3, 22.4, 22.5, 22.5, 22.4, 22.3, 22.1, 22.0, 21.8, 21.6, 21.5, 21.3, 21.2, 21.1, 21.0, 20.8, 20.7, 20.6, 20.6, 20.5, 20.4],
  OBESIDADE: [18.3, 19.6, 21.3, 22.0, 22.3, 22.4, 22.5, 22.5, 22.4, 22.3, 22.1, 22.0, 21.8, 21.6, 21.5, 21.3, 21.2, 21.1, 21.0, 20.8, 20.7, 20.6, 20.6, 20.5, 20.4],
}

const INFANTIL_MASCULINO_IMC_PARA_IDADE_2_ATE_5_ANOS = {
  MESES: [24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60],
  MAGREZA_ACENTUADA: [12.7, 12.7, 12.6, 12.6, 12.5, 12.5, 12.4, 12.4, 12.4, 12.3, 12.3, 12.3, 12.2, 12.2, 12.2, 12.1, 12.1, 12.1, 12.0, 12.0, 12.0, 12.0, 12.0, 11.9, 11.9, 11.9, 11.9, 11.9, 11.9, 11.8, 11.8, 11.8, 11.8, 11.8, 11.8, 11.8, 11.8],
  MAGREZA: [13.9, 13.9, 13.8, 13.8, 13.8, 13.7, 13.7, 13.7, 13.6, 13.6, 13.5, 13.5, 13.5, 13.5, 13.4, 13.4, 13.4, 13.3, 13.3, 13.3, 13.3, 13.2, 13.2, 13.2, 13.2, 13.2, 13.2, 13.1, 13.1, 13.1, 13.1, 13.1, 13.1, 13.0, 13.0, 13.0, 13.0],
  EUTROFIA: [17.4, 17.4, 17.3, 17.3, 17.2, 17.2, 17.2, 17.1, 17.1, 17.0, 17.0, 17.0, 17.0, 16.9, 16.9, 16.9, 16.8, 16.8, 16.8, 16.8, 16.8, 16.8, 16.7, 16.7, 16.7, 16.7, 16.7, 16.7, 16.7, 16.7, 16.7, 16.7, 16.7, 16.7, 16.7, 16.7, 16.7],
  RISCO_SOBREPESO: [18.7, 18.6, 18.6, 18.5, 18.5, 18.4, 18.4, 18.4, 18.3, 18.3, 18.2, 18.2, 18.2, 18.1, 18.1, 18.1, 18.1, 18.0, 18.0, 18.0, 18.0, 18.0, 18.0, 18.0, 18.0, 18.0, 18.0, 18.0, 18.0, 18.0, 18.0, 18.0, 18.0, 18.0, 18.0, 18.1, 18.1],
  SOBREPESO: [20.8, 20.7, 20.6, 20.6, 20.5, 20.5, 20.4, 20.3, 20.3, 20.2, 20.2, 20.2, 20.1, 20.1, 20.1, 20.0, 20.0, 20.0, 20.0, 20.0, 20.0, 20.0, 20.0, 20.0, 20.0, 20.0, 20.1, 20.1, 20.1, 20.1, 20.2, 20.2, 20.3, 20.3, 20.3, 20.4, 20.5],
  OBESIDADE: [20.8, 20.7, 20.6, 20.6, 20.5, 20.5, 20.4, 20.3, 20.3, 20.2, 20.2, 20.2, 20.1, 20.1, 20.1, 20.0, 20.0, 20.0, 20.0, 20.0, 20.0, 20.0, 20.0, 20.0, 20.0, 20.0, 20.1, 20.1, 20.1, 20.1, 20.2, 20.2, 20.3, 20.3, 20.3, 20.4, 20.5],
}

const INFANTIL_MASCULINO_IMC_PARA_IDADE_5_ATE_19_ANOS = {
  MESES: [61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120, 121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135, 136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150, 151, 152, 153, 154, 155, 156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170, 171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185, 186, 187, 188, 189, 190, 191, 192, 193, 194, 195, 196, 197, 198, 199, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228],
  MAGREZA_ACENTUADA: [11.9, 11.9, 11.9, 11.9, 11.9, 11.9, 11.9, 11.9, 11.9, 12.0, 12.0, 12.0, 12.0, 12.0, 12.0, 12.0, 12.0, 12.0, 12.0, 12.0, 12.0, 12.1, 12.1, 12.1, 12.1, 12.1, 12.1, 12.1, 12.1, 12.1, 12.2, 12.2, 12.2, 12.2, 12.2, 12.2, 12.2, 12.2, 12.3, 12.3, 12.3, 12.3, 12.3, 12.3, 12.3, 12.4, 12.4, 12.4, 12.4, 12.4, 12.4, 12.5, 12.5, 12.5, 12.5, 12.5, 12.5, 12.6, 12.6, 12.6, 12.6, 12.6, 12.7, 12.7, 12.7, 12.7, 12.7, 12.8, 12.8, 12.8, 12.8, 12.9, 12.9, 12.9, 12.9, 13.0, 13.0, 13.0, 13.1, 13.1, 13.1, 13.1, 13.2, 13.2, 13.2, 13.3, 13.3, 13.3, 13.4, 13.4, 13.4, 13.5, 13.5, 13.5, 13.6, 13.6, 13.6, 13.7, 13.7, 13.8, 13.8, 13.8, 13.9, 13.9, 13.9, 14.0, 14.0, 14.1, 14.1, 14.1, 14.2, 14.2, 14.3, 14.3, 14.3, 14.4, 14.4, 14.4, 14.5, 14.5, 14.5, 14.6, 14.6, 14.6, 14.7, 14.7, 14.7, 14.8, 14.8, 14.8, 14.9, 14.9, 14.9, 15.0, 15.0, 15.0, 15.0, 15.1, 15.1, 15.1, 15.1, 15.2, 15.2, 15.2, 15.2, 15.3, 15.3, 15.3, 15.3, 15.4, 15.4, 15.4, 15.4, 15.4, 15.4, 15.5, 15.5, 15.5, 15.5, 15.5, 15.5, 15.6, 15.6, 15.6, 15.6, 15.6, 15.6, 15.6],
  MAGREZA: [13.1, 13.1, 13.1, 13.1, 13.1, 13.1, 13.1, 13.1, 13.1, 13.1, 13.2, 13.2, 13.2, 13.2, 13.2, 13.2, 13.2, 13.2, 13.2, 13.2, 13.2, 13.2, 13.3, 13.3, 13.3, 13.3, 13.3, 13.3, 13.3, 13.3, 13.4, 13.4, 13.4, 13.4, 13.4, 13.4, 13.4, 13.5, 13.5, 13.5, 13.5, 13.5, 13.5, 13.5, 13.6, 13.6, 13.6, 13.6, 13.6, 13.7, 13.7, 13.7, 13.7, 13.7, 13.7, 13.8, 13.8, 13.8, 13.8, 13.9, 13.9, 13.9, 13.9, 14.0, 14.0, 14.0, 14.0, 14.1, 14.1, 14.1, 14.2, 14.2, 14.2, 14.3, 14.3, 14.3, 14.4, 14.4, 14.4, 14.5, 14.5, 14.5, 14.6, 14.6, 14.6, 14.7, 14.7, 14.8, 14.8, 14.8, 14.9, 14.9, 15.0, 15.0, 15.0, 15.1, 15.1, 15.2, 15.2, 15.3, 15.3, 15.4, 15.4, 15.5, 15.5, 15.5, 15.6, 15.6, 15.7, 15.7, 15.8, 15.8, 15.9, 15.9, 16.0, 16.0, 16.1, 16.1, 16.1, 16.2, 16.2, 16.3, 16.3, 16.4, 16.4, 16.4, 16.5, 16.5, 16.6, 16.6, 16.7, 16.7, 16.7, 16.8, 16.8, 16.8, 16.9, 16.9, 17.0, 17.0, 17.0, 17.1, 17.1, 17.1, 17.2, 17.2, 17.2, 17.3, 17.3, 17.3, 17.4, 17.4, 17.4, 17.4, 17.5, 17.5, 17.5, 17.5, 17.6, 17.6, 17.6, 17.6, 17.7, 17.7, 17.7, 17.7, 17.8, 17.8],
  EUTROFIA: [16.7, 16.7, 16.7, 16.7, 16.7, 16.7, 16.7, 16.8, 16.8, 16.8, 16.8, 16.8, 16.8, 16.9, 16.9, 16.9, 16.9, 16.9, 17.0, 17.0, 17.0, 17.1, 17.1, 17.1, 17.1, 17.2, 17.2, 17.2, 17.3, 17.3, 17.3, 17.4, 17.4, 17.4, 17.5, 17.5, 17.5, 17.6, 17.6, 17.7, 17.7, 17.7, 17.8, 17.8, 17.9, 17.9, 17.9, 18.0, 18.0, 18.1, 18.1, 18.2, 18.2, 18.3, 18.3, 18.4, 18.4, 18.5, 18.5, 18.6, 18.6, 18.7, 18.7, 18.8, 18.8, 18.9, 19.0, 19.0, 19.1, 19.1, 19.2, 19.3, 19.3, 19.4, 19.4, 19.5, 19.6, 19.6, 19.7, 19.8, 19.8, 19.9, 20.0, 20.1, 20.1, 20.2, 20.3, 20.3, 20.4, 20.5, 20.6, 20.6, 20.7, 20.8, 20.9, 20.9, 21.0, 21.1, 21.2, 21.3, 21.3, 21.4, 21.5, 21.6, 21.7, 21.7, 21.8, 21.9, 22.0, 22.0, 22.1, 22.2, 22.3, 22.4, 22.4, 22.5, 22.6, 22.7, 22.7, 22.8, 22.9, 23.0, 23.0, 23.1, 23.2, 23.2, 23.3, 23.4, 23.5, 23.5, 23.6, 23.7, 23.7, 23.8, 23.9, 23.9, 24.0, 24.0, 24.1, 24.2, 24.2, 24.3, 24.3, 24.4, 24.5, 24.5, 24.6, 24.6, 24.7, 24.7, 24.8, 24.8, 24.9, 24.9, 25.0, 25.0, 25.1, 25.1, 25.2, 25.2, 25.3, 25.3, 25.4, 25.4, 25.5, 25.5, 25.5, 25.6],
  RISCO_SOBREPESO: [18.1, 18.1, 18.1, 18.1, 18.1, 18.1, 18.2, 18.2, 18.2, 18.2, 18.3, 18.3, 18.3, 18.4, 18.4, 18.4, 18.5, 18.5, 18.5, 18.6, 18.6, 18.7, 18.7, 18.8, 18.8, 18.8, 18.9, 18.9, 19.0, 19.0, 19.1, 19.2, 19.2, 19.3, 19.3, 19.4, 19.4, 19.5, 19.5, 19.6, 19.7, 19.7, 19.8, 19.9, 19.9, 20.0, 20.0, 20.1, 20.2, 20.2, 20.3, 20.4, 20.5, 20.5, 20.6, 20.7, 20.8, 20.8, 20.9, 21.0, 21.1, 21.1, 21.2, 21.3, 21.4, 21.5, 21.6, 21.6, 21.7, 21.8, 21.9, 22.0, 22.1, 22.2, 22.2, 22.3, 22.4, 22.5, 22.6, 22.7, 22.8, 22.9, 23.0, 23.1, 23.1, 23.2, 23.3, 23.4, 23.5, 23.6, 23.7, 23.8, 23.9, 24.0, 24.1, 24.2, 24.3, 24.4, 24.5, 24.6, 24.7, 24.8, 24.8, 24.9, 25.0, 25.1, 25.2, 25.3, 25.4, 25.5, 25.6, 25.7, 25.8, 25.8, 25.9, 26.0, 26.1, 26.2, 26.3, 26.4, 26.4, 26.5, 26.6, 26.7, 26.7, 26.8, 26.9, 27.0, 27.0, 27.1, 27.2, 27.3, 27.3, 27.4, 27.5, 27.5, 27.6, 27.7, 27.7, 27.8, 27.8, 27.9, 28.0, 28.0, 28.1, 28.1, 28.2, 28.2, 28.3, 28.4, 28.4, 28.5, 28.5, 28.6, 28.6, 28.6, 28.7, 28.7, 28.8, 28.8, 28.9, 28.9, 28.9, 29.0, 29.0, 29.1, 29.1, 29.1],
  SOBREPESO: [20.4, 20.4, 20.4, 20.5, 20.5, 20.6, 20.6, 20.7, 20.7, 20.8, 20.8, 20.9, 21.0, 21.0, 21.1, 21.2, 21.3, 21.3, 21.4, 21.5, 21.6, 21.7, 21.8, 21.9, 21.9, 22.0, 22.1, 22.2, 22.3, 22.5, 22.6, 22.7, 22.8, 22.9, 23.0, 23.1, 23.2, 23.4, 23.5, 23.6, 23.8, 23.9, 24.0, 24.2, 24.3, 24.4, 24.6, 24.7, 24.9, 25.0, 25.2, 25.3, 25.5, 25.7, 25.8, 26.0, 26.1, 26.3, 26.5, 26.6, 26.8, 27.0, 27.2, 27.3, 27.5, 27.7, 27.9, 28.0, 28.2, 28.4, 28.6, 28.7, 28.9, 29.1, 29.3, 29.4, 29.6, 29.8, 30.0, 30.1, 30.3, 30.5, 30.6, 30.8, 31.0, 31.1, 31.3, 31.4, 31.6, 31.7, 31.9, 32.0, 32.2, 32.3, 32.5, 32.6, 32.7, 32.9, 33.0, 33.1, 33.2, 33.4, 33.5, 33.6, 33.7, 33.8, 33.9, 34.0, 34.1, 34.2, 34.3, 34.4, 34.5, 34.5, 34.6, 34.7, 34.8, 34.8, 34.9, 35.0, 35.0, 35.1, 35.2, 35.2, 35.3, 35.3, 35.4, 35.4, 35.5, 35.5, 35.5, 35.6, 35.6, 35.7, 35.7, 35.7, 35.8, 35.8, 35.8, 35.8, 35.9, 35.9, 35.9, 35.9, 36.0, 36.0, 36.0, 36.0, 36.0, 36.1, 36.1, 36.1, 36.1, 36.1, 36.1, 36.1, 36.1, 36.1, 36.1, 36.1, 36.1, 36.2, 36.2, 36.2, 36.1, 36.1, 36.1, 36.1],
  OBESIDADE: [20.4, 20.4, 20.4, 20.5, 20.5, 20.6, 20.6, 20.7, 20.7, 20.8, 20.8, 20.9, 21.0, 21.0, 21.1, 21.2, 21.3, 21.3, 21.4, 21.5, 21.6, 21.7, 21.8, 21.9, 21.9, 22.0, 22.1, 22.2, 22.3, 22.5, 22.6, 22.7, 22.8, 22.9, 23.0, 23.1, 23.2, 23.4, 23.5, 23.6, 23.8, 23.9, 24.0, 24.2, 24.3, 24.4, 24.6, 24.7, 24.9, 25.0, 25.2, 25.3, 25.5, 25.7, 25.8, 26.0, 26.1, 26.3, 26.5, 26.6, 26.8, 27.0, 27.2, 27.3, 27.5, 27.7, 27.9, 28.0, 28.2, 28.4, 28.6, 28.7, 28.9, 29.1, 29.3, 29.4, 29.6, 29.8, 30.0, 30.1, 30.3, 30.5, 30.6, 30.8, 31.0, 31.1, 31.3, 31.4, 31.6, 31.7, 31.9, 32.0, 32.2, 32.3, 32.5, 32.6, 32.7, 32.9, 33.0, 33.1, 33.2, 33.4, 33.5, 33.6, 33.7, 33.8, 33.9, 34.0, 34.1, 34.2, 34.3, 34.4, 34.5, 34.5, 34.6, 34.7, 34.8, 34.8, 34.9, 35.0, 35.0, 35.1, 35.2, 35.2, 35.3, 35.3, 35.4, 35.4, 35.5, 35.5, 35.5, 35.6, 35.6, 35.7, 35.7, 35.7, 35.8, 35.8, 35.8, 35.8, 35.9, 35.9, 35.9, 35.9, 36.0, 36.0, 36.0, 36.0, 36.0, 36.1, 36.1, 36.1, 36.1, 36.1, 36.1, 36.1, 36.1, 36.1, 36.1, 36.1, 36.1, 36.2, 36.2, 36.2, 36.1, 36.1, 36.1, 36.1]
}

const INFANTIL_MASCULINO_COMPRIMENTO_PARA_IDADE_0_ATE_2_ANOS = {
  MESES: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24],
  MUITO_BAIXA_ESTATURA_PARA_IDADE: [0.439, 0.486, 0.521, 0.550, 0.574, 0.593, 0.609, 0.624, 0.637, 0.649, 0.661, 0.672, 0.683, 0.693, 0.703, 0.712, 0.721, 0.730, 0.738, 0.746, 0.754, 0.762, 0.769, 0.776, 0.783],
  BAIXA_ESTATURA_PARA_IDADE: [0.462, 0.510, 0.546, 0.575, 0.599, 0.618, 0.635, 0.650, 0.664, 0.676, 0.689, 0.701, 0.712, 0.723, 0.733, 0.743, 0.753, 0.762, 0.771, 0.780, 0.788, 0.796, 0.804, 0.812, 0.820],
  ESTATURA_ADEQUADA_PARA_IDADE: [0.462, 0.510, 0.546, 0.575, 0.599, 0.618, 0.635, 0.650, 0.664, 0.676, 0.689, 0.701, 0.712, 0.723, 0.733, 0.743, 0.753, 0.762, 0.771, 0.780, 0.788, 0.796, 0.804, 0.812, 0.820],
}

const INFANTIL_MASCULINO_ESTATURA_PARA_IDADE_2_ATE_5_ANOS = {
  MESES: [24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60],
  MUITO_BAIXA_ESTATURA_PARA_IDADE: [0.776, 0.782, 0.789, 0.795, 0.801, 0.807, 0.813, 0.819, 0.824, 0.830, 0.835, 0.840, 0.845, 0.850, 0.855, 0.860, 0.865, 0.870, 0.875, 0.880, 0.884, 0.889, 0.894, 0.898, 0.903, 0.907, 0.912, 0.916, 0.920, 0.925, 0.929, 0.934, 0.938, 0.942, 0.947, 0.951, 0.955],
  BAIXA_ESTATURA_PARA_IDADE: [0.813, 0.820, 0.827, 0.834, 0.841, 0.848, 0.854, 0.861, 0.867, 0.873, 0.879, 0.884, 0.890, 0.896, 0.901, 0.907, 0.912, 0.918, 0.923, 0.928, 0.933, 0.938, 0.943, 0.948, 0.953, 0.958, 0.963, 0.968, 0.973, 0.978, 0.983, 0.987, 0.992, 0.997, 1.002, 1.007, 1.011],
  ESTATURA_ADEQUADA_PARA_IDADE: [0.813, 0.820, 0.827, 0.834, 0.841, 0.848, 0.854, 0.861, 0.867, 0.873, 0.879, 0.884, 0.890, 0.896, 0.901, 0.907, 0.912, 0.918, 0.923, 0.928, 0.933, 0.938, 0.943, 0.948, 0.953, 0.958, 0.963, 0.968, 0.973, 0.978, 0.983, 0.987, 0.992, 0.997, 1.002, 1.007, 1.011],
}

const INFANTIL_MASCULINO_ESTATURA_PARA_IDADE_5_ATE_19_ANOS = {
  MESES: [61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120, 121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135, 136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150, 151, 152, 153, 154, 155, 156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170, 171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185, 186, 187, 188, 189, 190, 191, 192, 193, 194, 195, 196, 197, 198, 199, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228],
  MUITO_BAIXA_ESTATURA_PARA_IDADE: [0.960, 0.964, 0.969, 0.973, 0.977, 0.981, 0.986, 0.990, 0.994, 0.998, 1.002, 1.006, 1.010, 1.014, 1.018, 1.022, 1.026, 1.030, 1.034, 1.038, 1.042, 1.045, 1.049, 1.053, 1.057, 1.061, 1.064, 1.068, 1.072, 1.075, 1.079, 1.083, 1.086, 1.090, 1.094, 1.097, 1.101, 1.104, 1.108, 1.111, 1.115, 1.118, 1.122, 1.125, 1.129, 1.132, 1.135, 1.139, 1.142, 1.146, 1.149, 1.153, 1.156, 1.159, 1.163, 1.166, 1.170, 1.173, 1.176, 1.180, 1.183, 1.187, 1.190, 1.194, 1.197, 1.200, 1.204, 1.208, 1.211, 1.215, 1.218, 1.222, 1.226, 1.230, 1.234, 1.237, 1.241, 1.245, 1.249, 1.254, 1.258, 1.262, 1.266, 1.271, 1.275, 1.280, 1.285, 1.289, 1.294, 1.299, 1.304, 1.309, 1.314, 1.319, 1.325, 1.330, 1.335, 1.341, 1.346, 1.351, 1.357, 1.362, 1.367, 1.372, 1.378, 1.383, 1.388, 1.393, 1.398, 1.403, 1.408, 1.413, 1.417, 1.422, 1.426, 1.431, 1.435, 1.439, 1.443, 1.447, 1.451, 1.455, 1.459, 1.462, 1.466, 1.469, 1.473, 1.476, 1.479, 1.482, 1.485, 1.488, 1.491, 1.493, 1.496, 1.498, 1.501, 1.503, 1.505, 1.507, 1.509, 1.511, 1.513, 1.514, 1.516, 1.518, 1.519, 1.520, 1.522, 1.523, 1.524, 1.525, 1.527, 1.528, 1.529, 1.530, 1.531, 1.531, 1.532, 1.533, 1.534, 1.535, 1.535, 1.536, 1.537, 1.538, 1.538, 1.539],
  BAIXA_ESTATURA_PARA_IDADE: [1.015, 1.020, 1.025, 1.030, 1.034, 1.039, 1.043, 1.048, 1.053, 1.057, 1.061, 1.066, 1.070, 1.075, 1.079, 1.083, 1.087, 1.092, 1.096, 1.100, 1.104, 1.109, 1.113, 1.117, 1.121, 1.125, 1.129, 1.133, 1.137, 1.142, 1.146, 1.150, 1.154, 1.158, 1.161, 1.165, 1.169, 1.173, 1.177, 1.181, 1.185, 1.189, 1.192, 1.196, 1.200, 1.204, 1.208, 1.212, 1.215, 1.219, 1.223, 1.227, 1.231, 1.234, 1.238, 1.242, 1.246, 1.249, 1.253, 1.257, 1.261, 1.264, 1.268, 1.272, 1.276, 1.280, 1.284, 1.287, 1.291, 1.295, 1.299, 1.304, 1.308, 1.312, 1.316, 1.320, 1.325, 1.329, 1.333, 1.338, 1.342, 1.347, 1.352, 1.357, 1.361, 1.366, 1.371, 1.376, 1.382, 1.387, 1.392, 1.398, 1.403, 1.409, 1.414, 1.420, 1.425, 1.431, 1.437, 1.442, 1.448, 1.453, 1.459, 1.465, 1.470, 1.475, 1.481, 1.486, 1.491, 1.496, 1.502, 1.506, 1.511, 1.516, 1.521, 1.525, 1.529, 1.534, 1.538, 1.542, 1.546, 1.550, 1.553, 1.557, 1.560, 1.564, 1.567, 1.570, 1.573, 1.576, 1.579, 1.582, 1.584, 1.587, 1.589, 1.592, 1.594, 1.596, 1.598, 1.600, 1.602, 1.604, 1.605, 1.607, 1.608, 1.610, 1.611, 1.612, 1.613, 1.614, 1.616, 1.616, 1.617, 1.618, 1.619, 1.620, 1.621, 1.621, 1.622, 1.623, 1.623, 1.624, 1.625, 1.625, 1.626, 1.626, 1.627, 1.627],
  ESTATURA_ADEQUADA_PARA_IDADE: [1.015, 1.020, 1.025, 1.030, 1.034, 1.039, 1.043, 1.048, 1.053, 1.057, 1.061, 1.066, 1.070, 1.075, 1.079, 1.083, 1.087, 1.092, 1.096, 1.100, 1.104, 1.109, 1.113, 1.117, 1.121, 1.125, 1.129, 1.133, 1.137, 1.142, 1.146, 1.150, 1.154, 1.158, 1.161, 1.165, 1.169, 1.173, 1.177, 1.181, 1.185, 1.189, 1.192, 1.196, 1.200, 1.204, 1.208, 1.212, 1.215, 1.219, 1.223, 1.227, 1.231, 1.234, 1.238, 1.242, 1.246, 1.249, 1.253, 1.257, 1.261, 1.264, 1.268, 1.272, 1.276, 1.280, 1.284, 1.287, 1.291, 1.295, 1.299, 1.304, 1.308, 1.312, 1.316, 1.320, 1.325, 1.329, 1.333, 1.338, 1.342, 1.347, 1.352, 1.357, 1.361, 1.366, 1.371, 1.376, 1.382, 1.387, 1.392, 1.398, 1.403, 1.409, 1.414, 1.420, 1.425, 1.431, 1.437, 1.442, 1.448, 1.453, 1.459, 1.465, 1.470, 1.475, 1.481, 1.486, 1.491, 1.496, 1.502, 1.506, 1.511, 1.516, 1.521, 1.525, 1.529, 1.534, 1.538, 1.542, 1.546, 1.550, 1.553, 1.557, 1.560, 1.564, 1.567, 1.570, 1.573, 1.576, 1.579, 1.582, 1.584, 1.587, 1.589, 1.592, 1.594, 1.596, 1.598, 1.600, 1.602, 1.604, 1.605, 1.607, 1.608, 1.610, 1.611, 1.612, 1.613, 1.614, 1.616, 1.616, 1.617, 1.618, 1.619, 1.620, 1.621, 1.621, 1.622, 1.623, 1.623, 1.624, 1.625, 1.625, 1.626, 1.626, 1.627, 1.627],
}

const INFANTIL_MASCULINO_PESO_PARA_IDADE_0_ATE_5_ANOS = {
  MESES: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60],
  MUITO_BAIXO_PESO_PARA_IDADE: [1.9, 2.8, 3.6, 4.3, 4.8, 5.2, 5.5, 5.8, 6.0, 6.2, 6.4, 6.6, 6.8, 6.9, 7.1, 7.2, 7.4, 7.5, 7.6, 7.8, 7.9, 8.1, 8.2, 8.3, 8.4, 8.6, 8.7, 8.8, 8.9, 9.1, 9.2, 9.3, 9.4, 9.5, 9.6, 9.7, 9.8, 9.9, 10.0, 10.1, 10.2, 10.3, 10.4, 10.5, 10.6, 10.7, 10.8, 10.9, 11.0, 11.1, 11.2, 11.3, 11.4, 11.5, 11.6, 11.7, 11.8, 11.9, 11.9, 12.0, 12.1],
  BAIXO_PESO_PARA_IDADE: [2.4, 3.3, 4.3, 5.0, 5.5, 6.0, 6.3, 6.6, 6.9, 7.1, 7.4, 7.6, 7.7, 7.9, 8.1, 8.3, 8.4, 8.6, 8.8, 8.9, 9.1, 9.2, 9.4, 9.6, 9.7, 9.9, 10.0, 10.1, 10.3, 10.4, 10.6, 10.7, 10.8, 11.0, 11.1, 11.2, 11.3, 11.5, 11.6, 11.7, 11.8, 12.0, 12.1, 12.2, 12.3, 12.4, 12.6, 12.7, 12.8, 12.9, 13.0, 13.2, 13.3, 13.4, 13.5, 13.6, 13.7, 13.8, 14.0, 14.1, 14.2],
  PESO_ADEQUADO_PARA_IDADE: [4.2, 5.6, 6.9, 7.8, 8.5, 9.1, 9.6, 10.1, 10.4, 10.8, 11.1, 11.4, 11.7, 12.0, 12.3, 12.6, 12.8, 13.1, 13.4, 13.6, 13.9, 14.2, 14.4, 14.7, 15.0, 15.2, 15.5, 15.8, 16.0, 16.3, 16.5, 16.8, 17.0, 17.2, 17.5, 17.7, 17.9, 18.2, 18.4, 18.6, 18.9, 19.1, 19.3, 19.6, 19.8, 20.0, 20.3, 20.5, 20.8, 21.0, 21.2, 21.5, 21.7, 22.0, 22.2, 22.4, 22.7, 22.9, 23.2, 23.4, 23.7],
  PESO_ELEVADO_PARA_IDADE: [4.2, 5.6, 6.9, 7.8, 8.5, 9.1, 9.6, 10.1, 10.4, 10.8, 11.1, 11.4, 11.7, 12.0, 12.3, 12.6, 12.8, 13.1, 13.4, 13.6, 13.9, 14.2, 14.4, 14.7, 15.0, 15.2, 15.5, 15.8, 16.0, 16.3, 16.5, 16.8, 17.0, 17.2, 17.5, 17.7, 17.9, 18.2, 18.4, 18.6, 18.9, 19.1, 19.3, 19.6, 19.8, 20.0, 20.3, 20.5, 20.8, 21.0, 21.2, 21.5, 21.7, 22.0, 22.2, 22.4, 22.7, 22.9, 23.2, 23.4, 23.7],
}

const INFANTIL_MASCULINO_PESO_PARA_IDADE_5_ATE_10_ANOS = {
  MESES: [61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120],
  MUITO_BAIXO_PESO_PARA_IDADE: [12.5, 12.6, 12.7, 12.8, 12.9, 13.1, 13.2, 13.3, 13.4, 13.6, 13.7, 13.8, 13.9, 14.1, 14.2, 14.3, 14.5, 14.6, 14.7, 14.9, 15.0, 15.1, 15.3, 15.4, 15.5, 15.7, 15.8, 15.9, 16.1, 16.2, 16.3, 16.5, 16.6, 16.7, 16.9, 17.0, 17.1, 17.2, 17.4, 17.5, 17.6, 17.8, 17.9, 18.0, 18.1, 18.3, 18.4, 18.5, 18.6, 18.8, 18.9, 19.0, 19.2, 19.3, 19.4, 19.6, 19.7, 19.8, 20.0, 20.1],
  BAIXO_PESO_PARA_IDADE: [14.5, 14.6, 14.7, 14.9, 15.0, 15.2, 15.3, 15.4, 15.6, 15.7, 15.9, 16.0, 16.2, 16.3, 16.4, 16.6, 16.7, 16.9, 17.1, 17.2, 17.4, 17.5, 17.7, 17.8, 18.0, 18.1, 18.3, 18.4, 18.6, 18.7, 18.9, 19.0, 19.2, 19.4, 19.5, 19.7, 19.8, 20.0, 20.1, 20.3, 20.4, 20.6, 20.7, 20.9, 21.0, 21.2, 21.3, 21.5, 21.7, 21.8, 22.0, 22.1, 22.3, 22.5, 22.6, 22.8, 23.0, 23.1, 23.3, 23.5],
  PESO_ADEQUADO_PARA_IDADE: [23.8, 24.0, 24.3, 24.5, 24.8, 25.1, 25.3, 25.6, 25.8, 26.1, 26.4, 26.7, 26.9, 27.2, 27.5, 27.8, 28.1, 28.3, 28.6, 28.9, 29.2, 29.5, 29.8, 30.1, 30.4, 30.7, 31.1, 31.4, 31.7, 32.0, 32.3, 32.7, 33.0, 33.3, 33.7, 34.0, 34.4, 34.7, 35.1, 35.5, 35.8, 36.2, 36.6, 37.0, 37.4, 37.8, 38.2, 38.6, 39.0, 39.4, 39.8, 40.3, 40.7, 41.1, 41.6, 42.0, 42.5, 43.0, 43.5, 43.9],
  PESO_ELEVADO_PARA_IDADE: [23.8, 24.0, 24.3, 24.5, 24.8, 25.1, 25.3, 25.6, 25.8, 26.1, 26.4, 26.7, 26.9, 27.2, 27.5, 27.8, 28.1, 28.3, 28.6, 28.9, 29.2, 29.5, 29.8, 30.1, 30.4, 30.7, 31.1, 31.4, 31.7, 32.0, 32.3, 32.7, 33.0, 33.3, 33.7, 34.0, 34.4, 34.7, 35.1, 35.5, 35.8, 36.2, 36.6, 37.0, 37.4, 37.8, 38.2, 38.6, 39.0, 39.4, 39.8, 40.3, 40.7, 41.1, 41.6, 42.0, 42.5, 43.0, 43.5, 43.9],

}
// TODO: Mapear essa essa constante depois que der pull do ajuste do grafico.
const INFANTIL_MASCULINO_PESO_PARA_COMPRIMENTO_0_ATE_2_ANOS = {
  ESTATURA: [0.450, 0.455, 0.460, 0.465, 0.470, 0.475, 0.480, 0.485, 0.490, 0.495, 0.500, 0.505, 0.510, 0.515, 0.520, 0.525, 0.530, 0.535, 0.540, 0.545, 0.550, 0.555, 0.560, 0.565, 0.570, 0.575, 0.580, 0.585, 0.590, 0.595, 0.600, 0.605, 0.610, 0.615, 0.620, 0.625, 0.630, 0.635, 0.640, 0.645, 0.650, 0.655, 0.660, 0.665, 0.670, 0.675, 0.680, 0.685, 0.690, 0.695, 0.700, 0.705, 0.710, 0.715, 0.720, 0.725, 0.730, 0.735, 0.740, 0.745, 0.750, 0.755, 0.760, 0.765, 0.770, 0.775, 0.780, 0.785, 0.790, 0.795, 0.800, 0.805, 0.810, 0.815, 0.820, 0.825, 0.830, 0.835, 0.840, 0.845, 0.850, 0.855, 0.860, 0.865, 0.870, 0.875, 0.880, 0.885, 0.890, 0.895, 0.900, 0.905, 0.910, 0.915, 0.920, 0.925, 0.930, 0.935, 0.940, 0.945, 0.950, 0.955, 0.960, 0.965, 0.970, 0.975, 0.980, 0.985, 0.990, 0.995, 1.000, 1.005, 1.010, 1.015, 1.020, 1.025, 1.030, 1.035, 1.040, 1.045, 1.050, 1.055, 1.060, 1.065, 1.070, 1.075, 1.080, 1.085, 1.090, 1.095, 1.100],
  MAGREZA_ACENTUADA: [1.8, 1.8, 1.9, 2.0, 2.0, 2.1, 2.2, 2.2, 2.3, 2.4, 2.5, 2.5, 2.6, 2.7, 2.8, 2.9, 3.0, 3.1, 3.2, 3.3, 3.4, 3.5, 3.7, 3.8, 3.9, 4.0, 4.1, 4.2, 4.4, 4.5, 4.6, 4.7, 4.8, 4.9, 5.0, 5.1, 5.2, 5.3, 5.4, 5.5, 5.6, 5.7, 5.8, 5.9, 6.0, 6.1, 6.1, 6.2, 6.3, 6.4, 6.5, 6.6, 6.7, 6.8, 6.8, 6.9, 7.0, 7.1, 7.2, 7.3, 7.3, 7.4, 7.5, 7.6, 7.6, 7.7, 7.8, 7.9, 7.9, 8.0, 8.1, 8.1, 8.2, 8.3, 8.4, 8.5, 8.6, 8.6, 8.7, 8.8, 8.9, 9.0, 9.1, 9.2, 9.3, 9.4, 9.5, 9.6, 9.7, 9.8, 9.9, 10.0, 10.1, 10.2, 10.3, 10.4, 10.5, 10.6, 10.7, 10.8, 10.8, 10.9, 11.0, 11.1, 11.2, 11.3, 11.4, 11.5, 11.6, 11.7, 11.8, 11.9, 12.0, 12.1, 12.2, 12.3, 12.4, 12.5, 12.6, 12.7, 12.9, 13.0, 13.1, 13.2, 13.3, 13.4, 13.5, 13.6, 13.8, 13.9, 14.0],
  MAGREZA: [2.0, 2.0, 2.1, 2.2, 2.3, 2.3, 2.4, 2.5, 2.6, 2.6, 2.7, 2.8, 2.9, 3.0, 3.1, 3.2, 3.3, 3.4, 3.5, 3.7, 3.8, 3.9, 4.0, 4.2, 4.3, 4.4, 4.5, 4.7, 4.8, 4.9, 5.0, 5.2, 5.3, 5.4, 5.5, 5.6, 5.7, 5.8, 5.9, 6.0, 6.2, 6.3, 6.4, 6.5, 6.6, 6.7, 6.8, 6.9, 7.0, 7.0, 7.1, 7.2, 7.3, 7.4, 7.5, 7.6, 7.7, 7.8, 7.9, 8.0, 8.1, 8.1, 8.2, 8.3, 8.4, 8.5, 8.6, 8.6, 8.7, 8.8, 8.9, 9.0, 9.0, 9.1, 9.2, 9.3, 9.4, 9.5, 9.6, 9.7, 9.8, 9.9, 10.0, 10.1, 10.2, 10.3, 10.5, 10.6, 10.7, 10.8, 10.9, 11.0, 11.1, 11.2, 11.3, 11.4, 11.5, 11.6, 11.7, 11.8, 11.9, 12.0, 12.1, 12.2, 12.3, 12.4, 12.5, 12.6, 12.7, 12.8, 12.9, 13.1, 13.2, 13.3, 13.4, 13.5, 13.7, 13.8, 13.9, 14.0, 14.1, 14.3, 14.4, 14.5, 14.7, 14.8, 14.9, 15.1, 15.2, 15.3, 15.5],
  EUTROFIA: [2.7, 2.8, 2.9, 3.0, 3.1, 3.1, 3.2, 3.3, 3.4, 3.5, 3.7, 3.8, 3.9, 4.0, 4.1, 4.3, 4.4, 4.5, 4.7, 4.8, 5.0, 5.1, 5.3, 5.4, 5.6, 5.8, 5.9, 6.1, 6.2, 6.4, 6.5, 6.7, 6.8, 7.0, 7.1, 7.3, 7.4, 7.5, 7.7, 7.8, 7.9, 8.1, 8.2, 8.3, 8.4, 8.6, 8.7, 8.8, 8.9, 9.1, 9.2, 9.3, 9.4, 9.6, 9.7, 9.8, 9.9, 10.0, 10.1, 10.3, 10.4, 10.5, 10.6, 10.7, 10.8, 10.9, 11.0, 11.1, 11.2, 11.3, 11.4, 11.5, 11.6, 11.7, 11.8, 11.9, 12.0, 12.2, 12.3, 12.4, 12.5, 12.7, 12.8, 12.9, 13.1, 13.2, 13.3, 13.5, 13.6, 13.7, 13.8, 14.0, 14.1, 14.2, 14.4, 14.5, 14.6, 14.7, 14.9, 15.0, 15.1, 15.3, 15.4, 15.5, 15.7, 15.8, 15.9, 16.1, 16.2, 16.4, 16.5, 16.7, 16.8, 17.0, 17.2, 17.3, 17.5, 17.7, 17.8, 18.0, 18.2, 18.4, 18.5, 18.7, 18.9, 19.1, 19.3, 19.5, 19.6, 19.8, 20.0],
  RISCO_SOBREPESO: [2.9, 3.0, 3.1, 3.2, 3.3, 3.4, 3.5, 3.6, 3.7, 3.8, 4.0, 4.1, 4.2, 4.3, 4.5, 4.6, 4.7, 4.9, 5.0, 5.2, 5.4, 5.5, 5.7, 5.9, 6.0, 6.2, 6.4, 6.5, 6.7, 6.9, 7.0, 7.2, 7.4, 7.5, 7.7, 7.8, 8.0, 8.1, 8.2, 8.4, 8.5, 8.7, 8.8, 8.9, 9.1, 9.2, 9.3, 9.5, 9.6, 9.7, 9.9, 10.0, 10.1, 10.3, 10.4, 10.5, 10.7, 10.8, 10.9, 11.0, 11.2, 11.3, 11.4, 11.5, 11.6, 11.7, 11.8, 12.0, 12.1, 12.2, 12.3, 12.4, 12.5, 12.6, 12.7, 12.8, 13.0, 13.1, 13.2, 13.3, 13.5, 13.6, 13.7, 13.9, 14.0, 14.2, 14.3, 14.4, 14.6, 14.7, 14.9, 15.0, 15.1, 15.3, 15.4, 15.5, 15.7, 15.8, 16.0, 16.1, 16.2, 16.4, 16.5, 16.7, 16.8, 17.0, 17.1, 17.3, 17.4, 17.6, 17.8, 17.9, 18.1, 18.3, 18.5, 18.6, 18.8, 19.0, 19.2, 19.4, 19.6, 19.8, 20.0, 20.2, 20.4, 20.6, 20.8, 21.0, 21.2, 21.4, 21.6],
  SOBREPESO: [3.3, 3.4, 3.5, 3.6, 3.7, 3.8, 4.0, 4.1, 4.2, 4.3, 4.4, 4.6, 4.7, 4.9, 5.0, 5.2, 5.3, 5.5, 5.7, 5.8, 6.0, 6.2, 6.4, 6.6, 6.7, 6.9, 7.1, 7.3, 7.5, 7.7, 7.8, 8.0, 8.2, 8.4, 8.5, 8.7, 8.9, 9.0, 9.2, 9.3, 9.5, 9.6, 9.8, 9.9, 10.1, 10.2, 10.4, 10.5, 10.7, 10.8, 11.0, 11.1, 11.3, 11.4, 11.6, 11.7, 11.9, 12.0, 12.2, 12.3, 12.4, 12.6, 12.7, 12.8, 13.0, 13.1, 13.2, 13.3, 13.4, 13.6, 13.7, 13.8, 13.9, 14.0, 14.2, 14.3, 14.4, 14.6, 14.7, 14.8, 15.0, 15.1, 15.3, 15.4, 15.6, 15.7, 15.9, 16.0, 16.2, 16.3, 16.5, 16.6, 16.8, 16.9, 17.1, 17.3, 17.4, 17.6, 17.7, 17.9, 18.0, 18.2, 18.3, 18.5, 18.7, 18.9, 19.0, 19.2, 19.4, 19.6, 19.8, 20.0, 20.2, 20.4, 20.6, 20.8, 21.0, 21.2, 21.4, 21.6, 21.9, 22.1, 22.3, 22.6, 22.8, 23.0, 23.3, 23.5, 23.8, 24.0, 24.3],
  OBESIDADE: [3.3, 3.4, 3.5, 3.6, 3.7, 3.8, 4.0, 4.1, 4.2, 4.3, 4.4, 4.6, 4.7, 4.9, 5.0, 5.2, 5.3, 5.5, 5.7, 5.8, 6.0, 6.2, 6.4, 6.6, 6.7, 6.9, 7.1, 7.3, 7.5, 7.7, 7.8, 8.0, 8.2, 8.4, 8.5, 8.7, 8.9, 9.0, 9.2, 9.3, 9.5, 9.6, 9.8, 9.9, 10.1, 10.2, 10.4, 10.5, 10.7, 10.8, 11.0, 11.1, 11.3, 11.4, 11.6, 11.7, 11.9, 12.0, 12.2, 12.3, 12.4, 12.6, 12.7, 12.8, 13.0, 13.1, 13.2, 13.3, 13.4, 13.6, 13.7, 13.8, 13.9, 14.0, 14.2, 14.3, 14.4, 14.6, 14.7, 14.8, 15.0, 15.1, 15.3, 15.4, 15.6, 15.7, 15.9, 16.0, 16.2, 16.3, 16.5, 16.6, 16.8, 16.9, 17.1, 17.3, 17.4, 17.6, 17.7, 17.9, 18.0, 18.2, 18.3, 18.5, 18.7, 18.9, 19.0, 19.2, 19.4, 19.6, 19.8, 20.0, 20.2, 20.4, 20.6, 20.8, 21.0, 21.2, 21.4, 21.6, 21.9, 22.1, 22.3, 22.6, 22.8, 23.0, 23.3, 23.5, 23.8, 24.0, 24.3],
}

const INFANTIL_MASCULINO_PESO_PARA_ESTATURA_2_ATE_5_ANOS = {
  ESTATURA: [0.650, 0.655, 0.660, 0.665, 0.670, 0.675, 0.680, 0.685, 0.690, 0.695, 0.700, 0.705, 0.710, 0.715, 0.720, 0.725, 0.730, 0.735, 0.740, 0.745, 0.750, 0.755, 0.760, 0.765, 0.770, 0.775, 0.780, 0.785, 0.790, 0.795, 0.800, 0.805, 0.810, 0.815, 0.820, 0.825, 0.830, 0.835, 0.840, 0.845, 0.850, 0.855, 0.860, 0.865, 0.870, 0.875, 0.880, 0.885, 0.890, 0.895, 0.900, 0.905, 0.910, 0.915, 0.920, 0.925, 0.930, 0.935, 0.940, 0.945, 0.950, 0.955, 0.960, 0.965, 0.970, 0.975, 0.980, 0.985, 0.990, 0.995, 1.000, 1.005, 1.010, 1.015, 1.020, 1.025, 1.030, 1.035, 1.040, 1.045, 1.050, 1.055, 1.060, 1.065, 1.070, 1.075, 1.080, 1.085, 1.090, 1.095, 1.100, 1.105, 1.110, 1.115, 1.120, 1.125, 1.130, 1.135, 1.140, 1.145, 1.150, 1.155, 1.160, 1.165, 1.170, 1.175, 1.180, 1.185, 1.190, 1.195, 1.200],
  MAGREZA_ACENTUADA: [5.7, 5.8, 5.9, 6.0, 6.1, 6.2, 6.3, 6.4, 6.4, 6.5, 6.6, 6.7, 6.8, 6.9, 7.0, 7.0, 7.1, 7.2, 7.3, 7.4, 7.4, 7.5, 7.6, 7.7, 7.7, 7.8, 7.9, 8.0, 8.0, 8.1, 8.2, 8.3, 8.3, 8.4, 8.5, 8.6, 8.7, 8.8, 8.9, 9.0, 9.1, 9.2, 9.3, 9.4, 9.5, 9.6, 9.7, 9.8, 9.9, 10.0, 10.1, 10.2, 10.2, 10.3, 10.4, 10.5, 10.6, 10.7, 10.8, 10.9, 11.0, 11.1, 11.2, 11.3, 11.3, 11.4, 11.5, 11.6, 11.7, 11.8, 11.9, 12.0, 12.1, 12.2, 12.4, 12.5, 12.6, 12.7, 12.8, 12.9, 13.0, 13.1, 13.2, 13.3, 13.5, 13.6, 13.7, 13.8, 13.9, 14.1, 14.2, 14.3, 14.4, 14.6, 14.7, 14.8, 15.0, 15.1, 15.2, 15.3, 15.5, 15.6, 15.8, 15.9, 16.0, 16.2, 16.3, 16.4, 16.6, 16.7, 16.8],
  MAGREZA: [6.3, 6.4, 6.5, 6.6, 6.7, 6.8, 6.9, 7.0, 7.1, 7.2, 7.3, 7.4, 7.5, 7.6, 7.7, 7.7, 7.8, 7.9, 8.0, 8.1, 8.2, 8.3, 8.4, 8.4, 8.5, 8.6, 8.7, 8.7, 8.8, 8.9, 9.0, 9.1, 9.2, 9.2, 9.3, 9.4, 9.5, 9.6, 9.7, 9.8, 10.0, 10.1, 10.2, 10.3, 10.4, 10.5, 10.6, 10.7, 10.8, 10.9, 11.0, 11.1, 11.2, 11.3, 11.4, 11.5, 11.6, 11.7, 11.8, 11.9, 12.0, 12.1, 12.2, 12.3, 12.4, 12.6, 12.7, 12.8, 12.9, 13.0, 13.1, 13.2, 13.3, 13.5, 13.6, 13.7, 13.8, 13.9, 14.1, 14.2, 14.3, 14.4, 14.6, 14.7, 14.8, 15.0, 15.1, 15.2, 15.4, 15.5, 15.7, 15.8, 16.0, 16.1, 16.2, 16.4, 16.5, 16.7, 16.9, 17.0, 17.2, 17.3, 17.5, 17.6, 17.8, 17.9, 18.1, 18.3, 18.4, 18.6, 18.7],
  EUTROFIA: [8.1, 8.2, 8.4, 8.5, 8.6, 8.7, 8.9, 9.0, 9.1, 9.2, 9.4, 9.5, 9.6, 9.7, 9.8, 10.0, 10.1, 10.2, 10.3, 10.4, 10.5, 10.6, 10.7, 10.8, 10.9, 11.0, 11.1, 11.2, 11.3, 11.4, 11.5, 11.6, 11.8, 11.9, 12.0, 12.1, 12.2, 12.3, 12.5, 12.6, 12.7, 12.8, 13.0, 13.1, 13.2, 13.4, 13.5, 13.6, 13.8, 13.9, 14.0, 14.1, 14.3, 14.4, 14.5, 14.7, 14.8, 14.9, 15.0, 15.2, 15.3, 15.4, 15.6, 15.7, 15.9, 16.0, 16.1, 16.3, 16.4, 16.6, 16.7, 16.9, 17.1, 17.2, 17.4, 17.6, 17.7, 17.9, 18.1, 18.2, 18.4, 18.6, 18.8, 19.0, 19.1, 19.3, 19.5, 19.7, 19.9, 20.1, 20.3, 20.5, 20.7, 20.9, 21.1, 21.4, 21.6, 21.8, 22.0, 22.2, 22.4, 22.7, 22.9, 23.1, 23.3, 23.6, 23.8, 24.0, 24.2, 24.5, 24.7],
  RISCO_SOBREPESO: [8.7, 8.9, 9.0, 9.1, 9.3, 9.4, 9.5, 9.7, 9.8, 9.9, 10.1, 10.2, 10.3, 10.5, 10.6, 10.7, 10.8, 11.0, 11.1, 11.2, 11.3, 11.4, 11.6, 11.7, 11.8, 11.9, 12.0, 12.1, 12.2, 12.3, 12.4, 12.5, 12.6, 12.8, 12.9, 13.0, 13.1, 13.3, 13.4, 13.5, 13.7, 13.8, 13.9, 14.1, 14.2, 14.4, 14.5, 14.6, 14.8, 14.9, 15.1, 15.2, 15.3, 15.5, 15.6, 15.7, 15.9, 16.0, 16.1, 16.3, 16.4, 16.6, 16.7, 16.9, 17.0, 17.2, 17.3, 17.5, 17.7, 17.8, 18.0, 18.2, 18.4, 18.5, 18.7, 18.9, 19.1, 19.3, 19.5, 19.7, 19.9, 20.1, 20.3, 20.5, 20.7, 20.9, 21.1, 21.3, 21.5, 21.7, 22.0, 22.2, 22.4, 22.6, 22.9, 23.1, 23.4, 23.6, 23.8, 24.1, 24.3, 24.6, 24.8, 25.1, 25.3, 25.6, 25.8, 26.1, 26.3, 26.6, 26.8],
  SOBREPESO: [9.7, 9.9, 10.0, 10.2, 10.3, 10.5, 10.6, 10.8, 10.9, 11.1, 11.2, 11.4, 11.5, 11.6, 11.8, 11.9, 12.1, 12.2, 12.4, 12.5, 12.6, 12.8, 12.9, 13.0, 13.1, 13.2, 13.4, 13.5, 13.6, 13.7, 13.8, 14.0, 14.1, 14.2, 14.3, 14.5, 14.6, 14.8, 14.9, 15.0, 15.2, 15.3, 15.5, 15.7, 15.8, 16.0, 16.1, 16.3, 16.4, 16.6, 16.7, 16.9, 17.0, 17.2, 17.3, 17.5, 17.6, 17.8, 17.9, 18.1, 18.2, 18.4, 18.6, 18.7, 18.9, 19.1, 19.3, 19.5, 19.7, 19.8, 20.0, 20.2, 20.4, 20.7, 20.9, 21.1, 21.3, 21.5, 21.7, 22.0, 22.2, 22.4, 22.6, 22.9, 23.1, 23.4, 23.6, 23.9, 24.1, 24.4, 24.6, 24.9, 25.2, 25.4, 25.7, 26.0, 26.3, 26.6, 26.8, 27.1, 27.4, 27.7, 28.0, 28.3, 28.6, 28.9, 29.2, 29.5, 29.8, 30.1, 30.4],
  OBESIDADE: [9.7, 9.9, 10.0, 10.2, 10.3, 10.5, 10.6, 10.8, 10.9, 11.1, 11.2, 11.4, 11.5, 11.6, 11.8, 11.9, 12.1, 12.2, 12.4, 12.5, 12.6, 12.8, 12.9, 13.0, 13.1, 13.2, 13.4, 13.5, 13.6, 13.7, 13.8, 14.0, 14.1, 14.2, 14.3, 14.5, 14.6, 14.8, 14.9, 15.0, 15.2, 15.3, 15.5, 15.7, 15.8, 16.0, 16.1, 16.3, 16.4, 16.6, 16.7, 16.9, 17.0, 17.2, 17.3, 17.5, 17.6, 17.8, 17.9, 18.1, 18.2, 18.4, 18.6, 18.7, 18.9, 19.1, 19.3, 19.5, 19.7, 19.8, 20.0, 20.2, 20.4, 20.7, 20.9, 21.1, 21.3, 21.5, 21.7, 22.0, 22.2, 22.4, 22.6, 22.9, 23.1, 23.4, 23.6, 23.9, 24.1, 24.4, 24.6, 24.9, 25.2, 25.4, 25.7, 26.0, 26.3, 26.6, 26.8, 27.1, 27.4, 27.7, 28.0, 28.3, 28.6, 28.9, 29.2, 29.5, 29.8, 30.1, 30.4],
}

const DADOS_ANTROPOMETRIAS_MASCULINA_INFANTIL = {
  IMC_PARA_IDADE: {
    INFANTIL_MASCULINO_IMC_PARA_IDADE_0_ATE_2_ANOS,
    INFANTIL_MASCULINO_IMC_PARA_IDADE_2_ATE_5_ANOS,
    INFANTIL_MASCULINO_IMC_PARA_IDADE_5_ATE_19_ANOS,
  },
  ESTATURA_PARA_IDADE: {
    INFANTIL_MASCULINO_COMPRIMENTO_PARA_IDADE_0_ATE_2_ANOS,
    INFANTIL_MASCULINO_ESTATURA_PARA_IDADE_2_ATE_5_ANOS,
    INFANTIL_MASCULINO_ESTATURA_PARA_IDADE_5_ATE_19_ANOS,
  },
  PESO_PARA_IDADE: {
    INFANTIL_MASCULINO_PESO_PARA_IDADE_0_ATE_5_ANOS,
    INFANTIL_MASCULINO_PESO_PARA_IDADE_5_ATE_10_ANOS
  },
  PESO_PARA_ESTATURA: {
    INFANTIL_MASCULINO_PESO_PARA_COMPRIMENTO_0_ATE_2_ANOS,
    INFANTIL_MASCULINO_PESO_PARA_ESTATURA_2_ATE_5_ANOS
  }
}

export {
  DADOS_ANTROPOMETRIAS_MASCULINA_INFANTIL
}