<template>
  <div>
    <v-row v-if="!this.documentos.length">
      <TelaVazia
        :imagemSrc="'documentos.svg'"
        :titulo="'Não há documentos para este paciente'"
        :textoBotaoPrimario="'Adicionar novo documento'"
        :exibirBotaoPrimario="true"
        :eventoBotaoPrimario="cadastrarDocumento"
      >
      </TelaVazia>
    </v-row>

    <!-- Tabela -->
    <v-card flat v-else>
      <v-card-text>
        <v-row>
          <v-col cols="12" lg="10">
            <v-btn text class="btn-primario" @click="cadastrarDocumento()">
              Adicionar documento
            </v-btn>
          </v-col>
          <v-col cols="12" lg="2">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Pesquisar..."
              single-line
              hide-details
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" lg="12">
            <v-data-table
              dense
              :items="documentos"
              :headers="colunasDocumento"
              :items-per-page="10"
              sort-by="id"
              sort-desc
              class="elevation-0"
              :search="search"
            >
              <template v-slot:body="{ items }">
                <tbody>
                  <tr v-for="item in items" :key="item.id">
                    <td class="text-left">
                      {{ item.descricao }}
                    </td>
                    <td class="text-left">
                      {{ item.criadoEm | data }}
                    </td>
                    <td class="text-right">
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            icon
                            text
                            @click="visualizarDocumento(item)"
                            v-bind="attrs"
                            v-on="on"
                          >
                            <eye-icon
                              size="1.3x"
                              class="custom-class"
                            ></eye-icon>
                          </v-btn>
                        </template>
                        <span>Visualizar</span>
                      </v-tooltip>

                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            icon
                            text
                            @click="excluirDocumento(item)"
                            v-bind="attrs"
                            v-on="on"
                          >
                            <trash-icon size="1.1x"></trash-icon>
                          </v-btn>
                        </template>
                        <span>Excluir</span>
                      </v-tooltip>
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            icon
                            text
                            @click="imprimirDocumento(item)"
                            v-bind="attrs"
                            v-on="on"
                          >
                            <printer-icon size="1.1x"></printer-icon>
                          </v-btn>
                        </template>
                        <span>Imprimir</span>
                      </v-tooltip>
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            icon
                            text
                            @click="editarDocumento(item)"
                            v-bind="attrs"
                            v-on="on"
                          >
                            <edit-icon size="1.1x"></edit-icon>
                          </v-btn>
                        </template>
                        <span>Editar</span>
                      </v-tooltip>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-data-table></v-col
          >
        </v-row>
      </v-card-text>
    </v-card>

    <!-- Modal Visualizar -->
    <v-dialog width="50%" v-model="modalVisualizarDocumento" persistent>
      <v-card>
        <v-card-title>
          <h1 class="titulo-modal">Visualizar documento</h1>
        </v-card-title>
        <v-card-text>
          <v-col cols="12" lg="12">
            <span v-html="previewDocumento"></span>
          </v-col>
        </v-card-text>
        <v-card-actions>
          <v-col class="text-right">
            <v-btn
              text
              class="btn-secundario"
              @click="modalVisualizarDocumento = false"
            >
              Fechar
            </v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// Components
import TelaVazia from "@/components/comum/TelaVazia";

// OUTROS
import moment from "moment";
import mixpanel from "mixpanel-browser";
import compositor from "@/plugins/editor-tinymce/composicao-variaveis/compositor-variaveis.js";
import templateImpressao from "@/constants/template-impressao-constantes";
import utilsImpressao from "@/utils/impressao";

// API
import apiDocumento from "@/api/documento/documento-api";
import apiModelos from "@/api/documento/modelo-api";

// MODEL
import DocumentoModel from "@/model/documento/documento-model";
import ModeloModel from "@/model/documento/documento-model";

export default {
  name: "DocumentoTab",
  components: {
    TelaVazia,
  },
  props: {
    paciente: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },
  emits: {
    "disparar-mensagem": (evento) => {
      return !!(evento.mensagem && evento.tipo);
    },
    "excluir-documento": (evento) => {
      return !!(evento.callback && evento.mensagem);
    },
    "fechar-janela-confirmacao": undefined,
  },
  data() {
    return {
      dialog: true,
      componentKey: 0,
      search: "",
      idPaciente: this.$route.params.id,
      modoEdicao: false,
      documentos: [],
      documento: new DocumentoModel(),
      modelos: [],
      previewDocumento: undefined,
      mensagemDeExclusao: "Deseja excluir?",
      modelo: new ModeloModel(),
      formCadastroValido: false,
      modalAdicionarDocumento: false,
      modalInserirModelo: false,
      modalVisualizarDocumento: false,
      abrirJanelaConfirmacao: false,
      formEdicaoValido: false,
      documentoExclusao: undefined,
      carregando: false,
      campoObrigatorio: (v) => !!v || "Este campo é obrigatório",
      maximo50Caracter: (v) =>
        (!!v && v.length <= 50) || "Máximo de 50 caracteres",
      colunasDocumento: [
        {
          text: "Descrição",
          value: "descricao",
          sortable: true,
          align: "left",
        },
        {
          text: "Data da criação",
          value: "criadoEm",
          sortable: true,
          align: "left",
        },
        {
          text: "",
          value: "botoes",
          align: "right",
          sortable: false,
        },
      ],
    };
  },
  mounted() {
    this.obterDocumentos();
    this.obterModelos();
  },
  methods: {
    forceRerender() {
      this.componentKey += 1;
    },

    limparModal() {
      this.modelo = new ModeloModel();
    },

    obterModelos() {
      apiModelos
        .obterTodos()
        .then((resposta) => {
          this.modelos = resposta.data.map((m) => new ModeloModel(m));
        })
        .catch(() => {
          this.$emit("disparar-mensagem", {
            mensagem: "Erro ao obter os modelos",
            tipo: "error",
          });
          this.loading = false;
        });
    },

    obterDocumentos() {
      apiDocumento
        .obterTodos()
        .then((resposta) => {
          this.documentos = resposta.data.map((d) => new DocumentoModel(d));
        })
        .catch(() => {
          this.$emit("disparar-mensagem", {
            mensagem: "Erro ao obter os documentos",
            tipo: "error",
          });
        });
    },

    imprimirDocumento(item) {
      mixpanel.track("click", {
        nome_pagina: "prontuario_paciente",
        prontuario_aba: "documentos",
        nome_botao: "btn_imprimir_documento",
        paciente_id: this.$route.params.id,
        documento_id: item.id,
      });
      this.documento = item;
      this.substituirVariaveisDoDocumento(item);
      this.documento.conteudo = this.previewDocumento;
      let documentoParaImprimir = templateImpressao.templateImpressaoDocumento(
        this.documento
      );
      utilsImpressao.imprimir(documentoParaImprimir);
    },

    cadastrarDocumento() {
      mixpanel.track("click", {
        nome_pagina: "prontuario_paciente",
        prontuario_aba: "documentos",
        nome_botao: "btn_adicionar_documento",
        paciente_id: this.$route.params.id,
      });
      this.$router.push({
        name: "prontuario-paciente-cadastrar-documentos",
        params: { idPaciente: this.paciente.id },
      });
    },

    editarDocumento(item) {
      mixpanel.track("click", {
        nome_pagina: "prontuario_paciente",
        prontuario_aba: "documentos",
        nome_botao: "btn_editar_documento",
        paciente_id: this.idPaciente,
        documento_id: item.id,
      });
      this.$router.push({
        name: "prontuario-paciente-editar-documentos",
        params: {
          idPaciente: this.paciente.id,
          idDocumento: item.id,
        },
      });
    },

    visualizarDocumento(item) {
      this.documento = item;
      this.substituirVariaveisDoDocumento(item);
      this.modalVisualizarDocumento = true;
      mixpanel.track("click", {
        nome_pagina: "prontuario_paciente",
        prontuario_aba: "documentos",
        nome_botao: "btn_visualizar_documento",
        paciente_id: this.idPaciente,
        documento_id: item.id,
      });
    },

    substituirVariaveisDoDocumento(documento) {
      // Pega as variáveis
      let variaveisDoSistema = compositor.obterVariaveisSistema(this.paciente);
      // Pega o documento
      let documentoOriginal = documento.conteudo;

      variaveisDoSistema.map((item) => {
        item.filhas.forEach((variavel) => {
          let replace = `((<span nome-variavel="@@${variavel.atributo.toLowerCase()}@@")[A-z, :, 0-9, >, ", ., =, \\-, (, ),;,#,&,\\/]*(<\\/span>))`;
          let regex = new RegExp(replace, "ig");
          (documentoOriginal.match(regex) || []).forEach((elemento) => {
            documentoOriginal = documentoOriginal.replace(
              elemento,
              variavel.conteudo // Teste
            );
          });
        });
        return (this.previewDocumento = documentoOriginal);
      });
    },

    excluirDocumento(item) {
      this.documento = item;

      mixpanel.track("click", {
        nome_pagina: "prontuario_paciente",
        prontuario_aba: "documentos",
        nome_botao: "btn_excluir_documento",
        paciente_id: this.idPaciente,
        documento_id: item.id,
      });

      this.$emit("excluir-documento", {
        callback: this.callbackConfirmarExclusao,
        mensagem: `Deseja excluir o documento ${item.descricao} ?`,
      });
    },

    callbackConfirmarExclusao() {
      let id = this.documento.id;
      apiDocumento
        .deletar(id)
        .then((response) => {
          this.documento = response.data;
          this.atualizarDocumentoNaLista(this.documento, true);
          this.$emit("fechar-janela-confirmacao");
          this.$emit("disparar-mensagem", {
            mensagem: "Documento excluido com sucesso",
            tipo: "success",
          });
        })
        .catch(() => {
          this.abrirJanelaConfirmacao = false;
          this.$emit("disparar-mensagem", {
            mensagem: "Erro ao excluir o documento",
            tipo: "error",
          });
        });
    },

    atualizarDocumentoNaLista(documento, deletarDaLista) {
      var index = this.documentos
        .map((p) => parseInt(p.id))
        .indexOf(parseInt(documento.id));
      !deletarDaLista
        ? this.documentos.splice(index, 1, documento) // Atualiza
        : this.documentos.splice(index, 1); // Deleta
    },

    janelaConfirmacaoEvento(event) {
      this.abrirJanelaConfirmacao = true;
      this.callbackConfirmarExclusao = event.callback;
      this.mensagemDeExclusao = event.mensagem;
    },
  },

  watch: {},
  filters: {
    data(valor) {
      return moment(valor).format("DD/MM/YYYY");
    },
  },
  computed: {},
};
</script>

<style lang="scss" scoped>
.texto-modal {
  color: black;
}
.titulo-modal {
  font-weight: 400;
  padding-bottom: 5px;
}
.subtitulo-modal {
  font-weight: 500;
}
.timeline-card-topo {
  background-color: $cor_primaria;
  color: $branco;
  font-size: 12px;
  font-weight: 300;
  line-height: 150%;
  display: inline-block;
  padding: 10px;
}

.timeline-card-texto {
  padding: 10px;
}

.timeline-topo-chave {
  font-weight: 500;
}

.linha {
  min-height: 300px;
}

.historico {
  position: absolute;
  padding-bottom: 50px;
  right: 0;
  height: 100%;
  overflow: auto;
}

@media only screen and (max-width: 1263px) {
  .historico {
    overflow: auto;
    position: inherit;
  }
}
</style>