<template>
  <div class="ma-5">
    <v-snackbar
      v-model="snackbar"
      :vertical="false"
      :timeout="5000"
      top="top"
      :color="tipo"
      elevation="0"
      right
    >
      {{ mensagem }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar = false">OK</v-btn>
      </template>
    </v-snackbar>
    <!-- Skeleton Loader -->
    <v-row v-if="loading">
      <v-col cols="12" lg="2">
        <v-card class="card-cuitatto">
          <v-skeleton-loader type="card" min-height="120px"></v-skeleton-loader>
          <v-col>
            <v-skeleton-loader type="paragraph"></v-skeleton-loader>
            <v-skeleton-loader type="sentences"></v-skeleton-loader>
          </v-col>
          <v-col>
            <v-skeleton-loader type="heading" class="pb-3"></v-skeleton-loader>
            <v-skeleton-loader type="sentences"></v-skeleton-loader>
          </v-col>
          <v-col>
            <v-skeleton-loader type="heading" class="pb-3"></v-skeleton-loader>
            <v-skeleton-loader type="sentences"></v-skeleton-loader>
          </v-col>
          <v-col>
            <div class="chip-skeleton">
              <v-skeleton-loader type="chip"></v-skeleton-loader>
            </div>
            <div class="chip-skeleton">
              <v-skeleton-loader type="chip"></v-skeleton-loader>
            </div>
            <div class="chip-skeleton">
              <v-skeleton-loader type="chip"></v-skeleton-loader>
            </div>
            <div class="chip-skeleton">
              <v-skeleton-loader type="chip"></v-skeleton-loader>
            </div>
          </v-col>
        </v-card>
      </v-col>
      <v-col cols="12" lg="10">
        <v-card class="card-cuitatto mb-3">
          <v-skeleton-loader
            type="actions"
            min-width="100px"
          ></v-skeleton-loader>
        </v-card>
        <v-card class="card-cuitatto">
          <div class="center">
            <v-col>
              <div class="chip-skeleton">
                <v-skeleton-loader type="chip"></v-skeleton-loader>
              </div>
              <div class="chip-skeleton">
                <v-skeleton-loader type="chip"></v-skeleton-loader>
              </div>
              <div class="chip-skeleton">
                <v-skeleton-loader type="chip"></v-skeleton-loader>
              </div>
              <div class="chip-skeleton">
                <v-skeleton-loader type="chip"></v-skeleton-loader>
              </div>
            </v-col>
            <v-divider></v-divider>
            <v-skeleton-loader type="table"></v-skeleton-loader>
            <v-col> </v-col>
          </div>
        </v-card>
      </v-col>
    </v-row>

    <div v-else>
      <v-row>
        <v-col cols="12" lg="2" sm="3" md="3">
          <v-card class="card-cuitatto">
            <div v-if="!this.paciente.foto">
              <v-img
                v-if="this.paciente.sexo == 'F'"
                src="@/assets/img/mulher-placeholder.png"
                dark
              ></v-img>
              <v-img
                v-if="this.paciente.sexo == 'M'"
                src="@/assets/img/homem-placeholder.png"
                dark
              ></v-img>
            </div>
            <div v-else>
              <v-img :src="this.paciente.foto"> </v-img>
            </div>
            <v-list>
              <v-list-item>
                <v-list-item-content>
                  <!-- <p class="titulo-dados pa-0">Informações pessoais</p> -->
                  <h2>{{ this.paciente.nome }}</h2>
                  <p>
                    {{ this.paciente.dataNascimento | idade }}
                  </p>
                  <p class="titulo-dados">Nascimento</p>
                  <p>
                    {{ this.paciente.dataNascimento | data }}
                  </p>
                  <p class="titulo-dados">Gênero</p>
                  <p>
                    {{ this.paciente.sexo | formataSexo }}
                  </p>
                  <div v-if="!this.paciente.nomePai">
                    <p class="titulo-dados">Nome do pai</p>
                    <p class="sem-dados">Não há parentesco cadastrado</p>
                  </div>

                  <div v-if="!this.paciente.nomeMae">
                    <p class="titulo-dados">Nome da Mãe</p>
                    <p class="sem-dados">Não há parentesco cadastrado</p>
                  </div>
                  <div v-if="!this.paciente.nomePai == ''">
                    <p class="titulo-dados">Nome do pai</p>
                    <p>{{ this.paciente.nomePai }}</p>
                  </div>
                  <div v-if="!this.paciente.nomeMae == ''">
                    <p class="titulo-dados">Nome da Mae</p>
                    <p>{{ this.paciente.nomeMae }}</p>
                  </div>
                  <div v-if="this.paciente.classificacao == ''">
                    <p class="titulo-dados">Classificação</p>
                    <p class="sem-dados">
                      Não há classificação para este paciente
                    </p>
                  </div>

                  <div v-if="this.paciente.alergias == ''">
                    <p class="titulo-dados">Alergias</p>
                    <p class="sem-dados">Não há alergias para este paciente</p>
                  </div>

                  <div v-if="this.paciente.classificacao != ''">
                    <p class="titulo-dados">Classificação</p>
                    <v-chip-group column multiple>
                      <v-chip
                        v-for="classificacao in this.paciente.classificacao"
                        :key="classificacao"
                        small
                        label
                        color="success"
                      >
                        {{ classificacao }}
                      </v-chip>
                    </v-chip-group>
                  </div>

                  <div v-if="this.paciente.alergias != ''">
                    <p class="titulo-dados">Alergias</p>
                    <v-chip-group column multiple>
                      <v-chip
                        v-for="alergias in this.paciente.alergias"
                        :key="alergias"
                        small
                        label
                        color="error"
                      >
                        {{ alergias }}
                      </v-chip>
                    </v-chip-group>
                  </div>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <ListaConsultaModal :paciente="paciente"></ListaConsultaModal>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        @click="chamarPacienteNoWhatsapp()"
                        v-bind="attrs"
                        v-on="on"
                        text
                        class="btn-primario my-2 btn-block"
                      >
                        <v-icon color="white" class="mr-2">mdi-whatsapp</v-icon>
                        Conversar
                      </v-btn>
                    </template>
                    <span>Conversar no WhatsApp</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        @click="verDetalhes()"
                        v-bind="attrs"
                        v-on="on"
                        text
                        class="btn-terciario btn-block"
                      >
                        Ver detalhes
                      </v-btn>
                    </template>
                    <span>Ver detalhes</span>
                  </v-tooltip>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card>
        </v-col>
        <v-col cols="12" lg="10" sm="9" md="9">
          <!-- Abas -->
          <v-card class="card-cuitatto" min-height="80vh">
            <v-tabs centered show-arrows>
              <!-- Anamneses -->
              <v-tab @click="verAbaAnamnese()" href="#anamneses">
                <file-text-icon size="1.2x" class="mr-3"></file-text-icon>
                Anamneses
              </v-tab>
              <v-tab-item value="anamneses">
                <AnamneseTab
                  :paciente="paciente"
                  @disparar-mensagem="dispararMensagemEvento"
                ></AnamneseTab>
              </v-tab-item>

              <!-- Anexos -->

              <v-tab @click="verAbaAnexos()" href="#anexos">
                <paper-clip-icon size="1.2x" class="mr-3"></paper-clip-icon>
                Anexos
              </v-tab>
              <v-tab-item value="anexos">
                <AnexosTab
                  :paciente="paciente"
                  @excluir-anexo="janelaConfirmacaoEvento"
                  @disparar-mensagem="dispararMensagemEvento"
                  @fechar-janela-confirmacao="fecharJanelaConfirmacao"
                ></AnexosTab>
              </v-tab-item>
              <v-tab @click="verAbaDocumentos()" href="#documentos">
                <folder-icon size="1.2x" class="mr-3"></folder-icon>
                Documentos
              </v-tab>
              <v-tab-item value="documentos">
                <DocumentosTab
                  :paciente="paciente"
                  @excluir-documento="janelaConfirmacaoEvento"
                  @disparar-mensagem="dispararMensagemEvento"
                  @fechar-janela-confirmacao="fecharJanelaConfirmacao"
                ></DocumentosTab>
              </v-tab-item>

              <!-- Antropometrias -->

              <v-tab @click="verAbaAntropometrias()" href="#antropometrias">
                <heart-icon size="1.2x" class="mr-3"></heart-icon>
                Antropometrias
              </v-tab>
              <v-tab-item value="antropometrias">
                <v-tabs centered>
                  <v-tab @click="verAbaAntropometriasInfantil()"
                    >Infantil</v-tab
                  >
                  <v-tab @click="verAbaAntropometriasAdulto()">Adulto</v-tab>
                  <v-tab @click="verAbaAntropometriasGestante()"
                    >Gestante</v-tab
                  >
                  <!-- Infantil -->
                  <v-tab-item>
                    <v-card flat>
                      <v-card-text>
                        <AntropometriaInfantilTab
                          :paciente="paciente"
                        ></AntropometriaInfantilTab>
                      </v-card-text>
                    </v-card>
                  </v-tab-item>

                  <!-- Adulto -->
                  <v-tab-item height="100%">
                    <v-card flat>
                      <v-card-text class="text-center">
                        <AntropometriaAdultoTab
                          :paciente="paciente"
                        ></AntropometriaAdultoTab>
                      </v-card-text>
                    </v-card>
                  </v-tab-item>

                  <!-- Gestante -->
                  <v-tab-item>
                    <v-card flat>
                      <v-card-text class="text-center">
                        <AntropometriaGestanteTab
                          :paciente="paciente"
                        ></AntropometriaGestanteTab>
                      </v-card-text>
                    </v-card>
                  </v-tab-item>
                </v-tabs>
              </v-tab-item>

              <!-- Cálculo Energético -->
              <v-tab
                @click="verAbaCalculoEnergetico()"
                href="#calculoEnergetico"
              >
                <zap-icon size="1.2x" class="mr-3"></zap-icon> Cálculo
                Energético
              </v-tab>
              <v-tab-item value="calculoEnergetico">
                <CalculoEnergeticoTab
                  :paciente="paciente"
                  @excluir-calculo="janelaConfirmacaoEvento"
                  @disparar-mensagem="dispararMensagemEvento"
                  @fechar-janela-confirmacao="fecharJanelaConfirmacao"
                ></CalculoEnergeticoTab>
              </v-tab-item>
            </v-tabs>
          </v-card>
        </v-col>
      </v-row>
      <v-dialog
        v-model="janelaDetalhes"
        persistent
        scrollable
        min-height="500px"
        max-width="550px"
      >
        <v-card>
          <v-card-text class="pa-5">
            <v-row class="pt-3">
              <v-col cols="12" lg="12">
                <h3>Informações Pessoais</h3>
                <v-divider></v-divider>
              </v-col>
              <v-col cols="12" lg="6" class="py-1">
                <label class="label-detalhes">Nome:</label>
                <p>{{ this.paciente.nome }}</p></v-col
              >
              <v-col cols="12" lg="6" class="py-1">
                <label class="label-detalhes">Gênero:</label>
                <p>
                  {{ this.paciente.sexo }}
                </p></v-col
              >
              <v-col cols="12" lg="6" class="py-1">
                <label class="label-detalhes">E-mail: </label>
                <span v-if="this.paciente.email"
                  ><p>{{ this.paciente.email }}</p></span
                >
                <span v-else><p>-</p></span>
              </v-col>
              <v-col cols="12" lg="6" class="py-1">
                <label class="label-detalhes">Data de Nascimento: </label>
                <span
                  ><p>{{ this.paciente.dataNascimento | data }}</p></span
                >
              </v-col>
              <v-col cols="12" lg="4" class="py-1">
                <label class="label-detalhes">Celular: </label>
                <span v-if="this.paciente.celular"
                  ><p>{{ this.paciente.celular }}</p></span
                >
                <span v-else><p>-</p></span>
              </v-col>
              <v-col cols="12" lg="4" class="py-1">
                <label class="label-detalhes">RG: </label>
                <span v-if="this.paciente.rg"
                  ><p>{{ this.paciente.rg }}</p></span
                >
                <span v-else><p>-</p></span>
              </v-col>
              <v-col cols="12" lg="4" class="py-1">
                <label class="label-detalhes">CPF: </label>
                <span v-if="this.paciente.cpf"
                  ><p>{{ this.paciente.cpf }}</p></span
                >
                <span v-else><p>-</p></span>
              </v-col>
              <v-col cols="12" lg="12" class="py-1">
                <label class="label-detalhes">Endereço: </label>
                <span v-if="this.paciente.endereco"
                  ><p>
                    {{ this.paciente.endereco }}, nº {{ this.paciente.numero }}
                    {{ this.paciente.complemento }} -
                    {{ this.paciente.bairro }} - {{ this.paciente.cidade }}/{{
                      this.paciente.estado
                    }}
                    - CEP: {{ this.paciente.cep }}
                  </p></span
                >
                <span v-else><p>-</p></span>
              </v-col>
              <v-col cols="12" lg="12" class="py-1">
                <label class="label-detalhes">Nome da mãe: </label>
                <span v-if="this.paciente.nomeMae"
                  ><p>{{ this.paciente.nomeMae }}</p></span
                >
                <span v-else><p>-</p></span>
              </v-col>
              <v-col cols="12" lg="12" class="py-1">
                <label class="label-detalhes">Nome do pai: </label>
                <span v-if="this.paciente.nomePai"
                  ><p>{{ this.paciente.nomePai }}</p></span
                >
                <span v-else><p>-</p></span>
              </v-col>
            </v-row></v-card-text
          ><v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="janelaDetalhes = false" class="btn-terciario" text
              >Fechar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <janela-confirmacao
        v-model="abrirJanelaConfirmacao"
        :callbackPrimario="callbackConfirmarExclusao"
        :mensagem="mensagemDeExclusao"
      />
    </div>
  </div>
</template>

<script>
// Componentes
import JanelaConfirmacao from "@/components/comum/JanelaConfirmacao";
import ListaConsultaModal from "@/components/modais/consulta/ListaConsulta";

// Tabs
import CalculoEnergeticoTab from "@/views/paciente/prontuario-tabs/CalculoEnergeticoTab";
import AnamneseTab from "@/views/paciente/prontuario-tabs/AnamneseTab";
import AnexosTab from "@/views/paciente/prontuario-tabs/AnexosTab";
import DocumentosTab from "@/views/paciente/prontuario-tabs/documentos/DocumentosTab";

import AntropometriaGestanteTab from "@/views/paciente/prontuario-tabs/antropometrias/AntropometriaGestanteTab";
import AntropometriaAdultoTab from "@/views/paciente/prontuario-tabs/antropometrias/AntropometriaAdultoTab";
import AntropometriaInfantilTab from "@/views/paciente/prontuario-tabs/antropometrias/AntropometriaInfantilTab";

// import Editor from "@/components/comum/EditorTinymceNutri";
// import Template from "@/model/template/Template";
// import TabelaVazia from "@/components/comum/TabelaVazia";

import moment from "moment";

// API
import apiPacientes from "@/api/paciente/paciente-api";

// Models
import PacienteModel from "@/model/paciente/paciente-model";

// Mixin
import MixinMensagem from "@/mixin/mixin-mensagem";

// Utils
import utilsData from "@/utils/data";
// import utilsCalculos from "@/utils/calculos";

// Tracking
import mixpanel from "mixpanel-browser";

export default {
  name: "prontuario-paciente",
  mixins: [MixinMensagem],
  components: {
    JanelaConfirmacao,
    ListaConsultaModal,
    // "editor-nutri": Editor,
    CalculoEnergeticoTab,
    AnamneseTab,
    AnexosTab,
    AntropometriaGestanteTab,
    AntropometriaAdultoTab,
    AntropometriaInfantilTab,
    DocumentosTab,
  },

  mounted() {
    this.loading = true;
    this.obterPacientePorId();
    mixpanel.track("page_view", {
      nome_pagina: "prontuario_paciente",
      paciente_id: this.$route.params.id,
    });
  },

  data() {
    return {
      idadePaciente: undefined,
      pacienteAdulto: undefined,
      search: "",
      janelaAnamnese: false,
      janelaDetalhes: false,
      abrirJanelaConfirmacao: false,
      abrirJanela: false,
      modalVisualizarConsultas: false,
      informacoesPaciente: true,
      // template: new Template(),
      paciente: new PacienteModel(),
      mensagemDeExclusao: "",
      callbackConfirmarExclusao: () => {},
      loading: false,
      modoEdicao: false,
    };
  },

  filters: {
    data(valor) {
      return moment(valor).format("DD/MM/YYYY");
    },

    idade(dataNascimento) {
      return utilsData.calcularIdadeAnoMesesDias(dataNascimento);
    },

    formataSexo(valor) {
      if (!valor) {
        return "Feminino";
      }
      return valor.replace("M", "Masculino").replace("F", "Feminino");
    },
  },
  emits: {
    "disparar-mensagem": (evento) => {
      return !!(evento.mensagem && evento.tipo);
    },
    "excluir-antropometria": (evento) => {
      return !!(evento.callback && evento.mensagem);
    },
    "fechar-janela-confirmacao": undefined,
  },

  methods: {
    verAbaDocumentos() {
      mixpanel.track("click", {
        nome_pagina: "prontuario_paciente",
        prontuario_aba: "documentos",
        nome_botao: "aba_documentos",
        paciente_id: this.$route.params.id,
      });
    },
    verDetalhes() {
      this.janelaDetalhes = true;
    },
    verAbaAnamnese() {
      mixpanel.track("click", {
        nome_pagina: "prontuario_paciente",
        prontuario_aba: "anamnese",
        nome_botao: "aba_anamnese",
        paciente_id: this.$route.params.id,
      });
    },
    verAbaAnexos() {
      mixpanel.track("click", {
        nome_pagina: "prontuario_paciente",
        prontuario_aba: "anexo",
        nome_botao: "aba_anexo",
        paciente_id: this.$route.params.id,
      });
    },
    verAbaAntropometrias() {
      mixpanel.track("click", {
        nome_pagina: "prontuario_paciente",
        prontuario_aba: "antropometria",
        nome_botao: "aba_antropometria",
        paciente_id: this.$route.params.id,
      });
    },
    verAbaCalculoEnergetico() {
      mixpanel.track("click", {
        nome_pagina: "prontuario_paciente",
        prontuario_aba: "calculo_energetico",
        nome_botao: "aba_calculo_energetico",
        paciente_id: this.$route.params.id,
      });
    },
    verAbaAntropometriasInfantil() {
      mixpanel.track("click", {
        nome_pagina: "prontuario_paciente",
        prontuario_aba: "antropometria_infantil",
        nome_botao: "aba_antropometria_infantil",
        paciente_id: this.$route.params.id,
      });
    },
    verAbaAntropometriasAdulto() {
      mixpanel.track("click", {
        nome_pagina: "prontuario_paciente",
        prontuario_aba: "antropometria_adulto",
        nome_botao: "aba_antropometria_adulto",
        paciente_id: this.$route.params.id,
      });
    },
    verAbaAntropometriasGestante() {
      mixpanel.track("click", {
        nome_pagina: "prontuario_paciente",
        prontuario_aba: "antropometria_gestante",
        nome_botao: "aba_antropometria_gestante",
        paciente_id: this.$route.params.id,
      });
    },

    classificarPaciente() {
      // TODO: melhorar esse método para identificar a classificação. Inicialmente estou só pegando a idade e classificando pelas tabs

      let dataNascimento = new Date(this.paciente.dataNascimento);
      let dataAtual = new Date();
      let idade = parseInt(
        (dataAtual - dataNascimento) / 1000 / 60 / 60 / 24 / 30 / 12
      );

      this.idadePaciente = idade;

      if (idade <= 19) {
        this.pacienteAdulto = false;
      } else {
        this.pacienteAdulto = true;
      }
    },

    // Obter dados do paciente
    obterPacientePorId() {
      let id = this.$route.params.id;
      apiPacientes
        .obterPorId(id)
        .then((resposta) => {
          this.paciente = new PacienteModel(resposta.data);
          this.classificarPaciente();
        })
        .catch(() => {
          this.dispararMensagem(
            "Não foi possível obter as informações deste paciente",
            "error"
          );
        })
        .finally(() => {
          this.loading = false;
        });
    },

    chamarPacienteNoWhatsapp() {
      mixpanel.track("click", {
        nome_pagina: "prontuario_paciente",
        nome_botao: "btn_chamar_whatsapp",
      });
      window.open(`https://wa.me/55${this.paciente.celular}`, "_blank");
    },

    dispararMensagemEvento(event) {
      this.dispararMensagem(event.mensagem, event.tipo);
    },
    fecharJanelaConfirmacao() {
      this.abrirJanelaConfirmacao = false;
    },
    janelaConfirmacaoEvento(event) {
      this.abrirJanelaConfirmacao = true;
      this.callbackConfirmarExclusao = event.callback;
      this.mensagemDeExclusao = event.mensagem;
    },
  },
};
</script>

<style lang="scss" scoped>
.label-pronturario {
  font-weight: 500;
}
.botoes-prontuario {
  display: block;
  position: absolute;
  right: 0;
  top: 10px;
  margin-right: 10px;
}
.botoes-prontuario > * {
  display: inline-block;
}
.conteudo {
  min-height: 50vh;
}

.titulo-dados {
  font-weight: 600;
  padding-top: 10px;
  color: $cor_primaria;
}
.sem-dados {
  font-style: italic;
  color: #656565;
}

.botao-novagestacao {
  display: block;
  position: absolute;
  right: 10px;
  top: 30px;
}
.grafico {
  width: 100%;
  height: 400px;
  background-color: $cor_primaria_sombra;
}
.chip-skeleton {
  display: inline-block;
  padding-right: 10px;
}
.center {
  text-align: center;
}
.label-detalhes {
  font-weight: bold;
  font-size: 13px;
  line-height: 0.1em;
}
h3 {
  color: $cor_primaria;
  font-weight: 400;
}
</style>