import utilsStorage from '@/utils/storage';
import UsuarioModel from '@/model/usuario-model';

import { ROTAS_ACESSO } from '@/constants/geral-constantes';



const mixinAutorizacao = {
    data(){
        return{
            usuario: new UsuarioModel()
        }
    },
    mounted(){
        this.obterUsuario();
    },

    methods:{
        obterUsuario(){
            this.usuario = new UsuarioModel(utilsStorage.obterUsuarioAtualStorage());
            return this.usuario;
        },

        /**
         * Metodo que retorna um booleano se o usuario pode ou não acessar a rota.
         * @param {Route} nomeRota Nome da rota que verificação.
         * @returns retorna True ou False
         */
        permissaoParaAcessarMenuPelaRota(nomeRota){
            switch (nomeRota) {
                case ROTAS_ACESSO.AGENDA.descricao :
                    return !!ROTAS_ACESSO.AGENDA.perfis.some(id => id == this.usuario.idPerfil);    
                     
                case ROTAS_ACESSO.PACIENTES.descricao :
                    return !!ROTAS_ACESSO.PACIENTES.perfis.some(id => id == this.usuario.idPerfil);

                case ROTAS_ACESSO.USUARIOS.descricao :
                    return !!ROTAS_ACESSO.USUARIOS.perfis.some(id => id == this.usuario.idPerfil); 
            }
        }
    }
}

export default mixinAutorizacao;