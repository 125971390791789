import ConsultaModel from '../consulta/consulta-model';
import PacienteModel from '../paciente/paciente-model';

export default class AntropometriaInfantilModel {
  constructor(obj) {
    obj = obj || {};
    this.id = obj.id;

    this.imc = obj.imc;
    this.altura = obj.altura;
    this.peso = parseFloat(obj.peso) || undefined;
    this.consulta = new ConsultaModel(obj.consulta);
    this.paciente = new PacienteModel(obj.paciente);
    this.idadeMeses = obj.idadeMeses;

    this.dataMarcacao = obj.dataMarcacao;
    this.idUsuario = parseInt(obj.idUsuario)

    this.dataInativacao = obj.dataInativacao;
    this.criadoEm = obj.criadoEm;
    this.atualizadoEm = obj.atualizadoEm;
  }

  modeloValido() {
    return !!(this.paciente && this.paciente.id &&
      this.imc &&
      this.altura &&
      this.peso &&
      this.idadeMeses &&
      this.dataMarcacao);
  }
}