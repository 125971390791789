
import api from '../api'

export default {
  cadastrar(documento) {
    documento = documento || {};
    return new Promise((resolve, reject) => {
      return api.post('/documentos', documento)
        .then(response => resolve(response))
        .catch(error => reject(error))
    });
  },

  obterTodos() {
    return new Promise((resolve, reject) => {
      return api.get('/documentos')
        .then(response => resolve(response))
        .catch(error => reject(error))
    });
  },

  obterId(id) {
    return new Promise((resolve, reject) => {
      return api.get('/documentos/' + id)
        .then(response => resolve(response))
        .catch(error => reject(error))
    });
  },

  deletar(id) {
    return new Promise((resolve, reject) => {
      return api.delete('/documentos/' + id)
        .then(response => resolve(response))
        .catch(error => reject(error))
    });
  },

  atualizar(documento) {
    documento = documento || {};
    return new Promise((resolve, reject) => {
      return api.put(`/documentos/${documento.id}`, documento)
        .then(response => resolve(response))
        .catch(error => reject(error))
    });
  },
};
