var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!this.documentos.length)?_c('v-row',[_c('TelaVazia',{attrs:{"imagemSrc":'documentos.svg',"titulo":'Não há documentos para este paciente',"textoBotaoPrimario":'Adicionar novo documento',"exibirBotaoPrimario":true,"eventoBotaoPrimario":_vm.cadastrarDocumento}})],1):_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"10"}},[_c('v-btn',{staticClass:"btn-primario",attrs:{"text":""},on:{"click":function($event){return _vm.cadastrarDocumento()}}},[_vm._v(" Adicionar documento ")])],1),_c('v-col',{attrs:{"cols":"12","lg":"2"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Pesquisar...","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"12"}},[_c('v-data-table',{staticClass:"elevation-0",attrs:{"dense":"","items":_vm.documentos,"headers":_vm.colunasDocumento,"items-per-page":10,"sort-by":"id","sort-desc":"","search":_vm.search},scopedSlots:_vm._u([{key:"body",fn:function(ref){
var items = ref.items;
return [_c('tbody',_vm._l((items),function(item){return _c('tr',{key:item.id},[_c('td',{staticClass:"text-left"},[_vm._v(" "+_vm._s(item.descricao)+" ")]),_c('td',{staticClass:"text-left"},[_vm._v(" "+_vm._s(_vm._f("data")(item.criadoEm))+" ")]),_c('td',{staticClass:"text-right"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","text":""},on:{"click":function($event){return _vm.visualizarDocumento(item)}}},'v-btn',attrs,false),on),[_c('eye-icon',{staticClass:"custom-class",attrs:{"size":"1.3x"}})],1)]}}],null,true)},[_c('span',[_vm._v("Visualizar")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","text":""},on:{"click":function($event){return _vm.excluirDocumento(item)}}},'v-btn',attrs,false),on),[_c('trash-icon',{attrs:{"size":"1.1x"}})],1)]}}],null,true)},[_c('span',[_vm._v("Excluir")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","text":""},on:{"click":function($event){return _vm.imprimirDocumento(item)}}},'v-btn',attrs,false),on),[_c('printer-icon',{attrs:{"size":"1.1x"}})],1)]}}],null,true)},[_c('span',[_vm._v("Imprimir")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","text":""},on:{"click":function($event){return _vm.editarDocumento(item)}}},'v-btn',attrs,false),on),[_c('edit-icon',{attrs:{"size":"1.1x"}})],1)]}}],null,true)},[_c('span',[_vm._v("Editar")])])],1)])}),0)]}}])})],1)],1)],1)],1),_c('v-dialog',{attrs:{"width":"50%","persistent":""},model:{value:(_vm.modalVisualizarDocumento),callback:function ($$v) {_vm.modalVisualizarDocumento=$$v},expression:"modalVisualizarDocumento"}},[_c('v-card',[_c('v-card-title',[_c('h1',{staticClass:"titulo-modal"},[_vm._v("Visualizar documento")])]),_c('v-card-text',[_c('v-col',{attrs:{"cols":"12","lg":"12"}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.previewDocumento)}})])],1),_c('v-card-actions',[_c('v-col',{staticClass:"text-right"},[_c('v-btn',{staticClass:"btn-secundario",attrs:{"text":""},on:{"click":function($event){_vm.modalVisualizarDocumento = false}}},[_vm._v(" Fechar ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }