import api from '../api'

function cadastrar(anamnese) {
	anamnese = anamnese || {};
	return new Promise((resolve, reject) => {
		return api.post(`/anamneses`, anamnese)
			.then(response => resolve(response))
			.catch(error => reject(error))
	});
}


function obterAnamnesePorPaciente(idPaciente) {
	return new Promise((resolve, reject) => {
		return api.get(`/pacientes/${idPaciente}/anamneses`)
			.then(response => resolve(response))
			.catch(error => reject(error))
	});
}

function obterAnamnesePorId(id) {
	return new Promise((resolve, reject) => {
		return api.get(`/anamneses/${id}`)
			.then(response => resolve(response))
			.catch(error => reject(error))
	});
}

function atualizar(anamnese) {
	anamnese = anamnese || {};
	return new Promise((resolve, reject) => {
		return api.put(`/anamneses/${anamnese.id}`, anamnese)
			.then(response => resolve(response))
			.catch(error => reject(error))
	});
}

function deletar(idAnamnese) {
	return new Promise((resolve, reject) => {
		return api.delete(`/anamneses/${idAnamnese}`)
			.then(response => resolve(response))
			.catch(error => reject(error))
	});
}

export default {
	cadastrar,
	obterAnamnesePorPaciente,
	obterAnamnesePorId,
	atualizar,
	deletar
}