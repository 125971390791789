import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import VueMask from 'v-mask';
import 'material-design-icons-iconfont/dist/material-design-icons.css';
import '@/static/styles/_index.scss';
import mixpanel from 'mixpanel-browser'

import {
  ActivityIcon,
  ArchiveIcon,
  CalendarIcon,
  UserPlusIcon,
  CheckSquareIcon,
  ClockIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  EditIcon,
  Edit2Icon,
  Edit3Icon,
  EyeIcon,
  FrownIcon,
  FilterIcon,
  FolderIcon,
  FileTextIcon,
  HeartIcon,
  InfoIcon,
  KeyIcon,
  LogOutIcon,
  MenuIcon,
  MoonIcon,
  NavigationIcon,
  PrinterIcon,
  SunIcon,
  SettingsIcon,
  SmileIcon,
  PlusIcon,
  PaperclipIcon,
  TrashIcon,
  UserIcon,
  UsersIcon,
  XCircleIcon,
  ZapIcon,
  DownloadIcon
} from 'vue-feather-icons';

Vue.component("activity-icon", ActivityIcon);
Vue.component("archive-icon", ArchiveIcon);
Vue.component("calendar-icon", CalendarIcon);
Vue.component("user-plus-icon", UserPlusIcon);
Vue.component("check-square-icon", CheckSquareIcon);
Vue.component("clock-icon", ClockIcon);
Vue.component("chevron-left-icon", ChevronLeftIcon);
Vue.component("chevron-right-icon", ChevronRightIcon);
Vue.component("edit-icon", EditIcon);
Vue.component("edit-2-icon", Edit2Icon);
Vue.component("edit-3-icon", Edit3Icon);
Vue.component("eye-icon", EyeIcon);
Vue.component("frown-icon", FrownIcon);
Vue.component("filter-icon", FilterIcon);
Vue.component("folder-icon", FolderIcon);
Vue.component("file-text-icon", FileTextIcon);
Vue.component("heart-icon", HeartIcon);
Vue.component("info-icon", InfoIcon);
Vue.component("key-icon", KeyIcon);
Vue.component("log-out-icon", LogOutIcon);
Vue.component("menu-icon", MenuIcon);
Vue.component("moon-icon", MoonIcon);
Vue.component("navigation-icon", NavigationIcon);
Vue.component("printer-icon", PrinterIcon);
Vue.component("paper-clip-icon", PaperclipIcon);
Vue.component("sun-icon", SunIcon);
Vue.component("settings-icon", SettingsIcon)
Vue.component("smile-icon", SmileIcon);
Vue.component("plus-icon", PlusIcon);
Vue.component("trash-icon", TrashIcon);
Vue.component("user-icon", UserIcon);
Vue.component("users-icon", UsersIcon);
Vue.component("x-circle-icon", XCircleIcon);
Vue.component("zap-icon", ZapIcon);
Vue.component("download-icon", DownloadIcon);

Vue.config.productionTip = false

Vue.prototype.$eventBus = new Vue();

Vue.use(VueMask);

Vue.use(mixpanel);

// Alterar futuramente a forma como tratamos essas chaves em diferentes ambientes

// mixpanel.init('c35f79cd73c7d402b9123b8d8eab5fe2', { debug: false }) // Produção
mixpanel.init('17b292f0dacfc69cb06d82d878eef6dd', { debug: true }) // Homologação

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
