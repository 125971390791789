function obterUsuarioAtualStorage(){
    var usuario = localStorage.getItem('usuario-nutri');

    if(usuario){
        usuario = atob(usuario);
        return JSON.parse(usuario);
    }
    return undefined;
}

function salvarUsuarioNaStorage(usuario){
    usuario = btoa(JSON.stringify(usuario));
    localStorage.setItem('usuario-nutri', usuario);
}

function salvarTokenNaStorage(token){
    localStorage.setItem('token-cuitatto', token);
}

function obterTokenNaStorage(){
   return localStorage.getItem('token-cuitatto');
}

function removerAutenticacao(){
    localStorage.removeItem('token-cuitatto');
}

export default {
    obterUsuarioAtualStorage,
    salvarUsuarioNaStorage,
    salvarTokenNaStorage,
    obterTokenNaStorage,
    removerAutenticacao
}