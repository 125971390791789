'use strict';
const ACESSO = {
  // LOCAL: "http://localhost:3333",
  HOMOLOGACAO: "https://hml.cuitatto.com.br/api",
  // PRODUCAO: "https://app.cuitatto.com.br/api",

}
// Alterar também ambiente no arquivo main.js

const URLS = {
  // BASE: ACESSO.LOCAL,
  BASE: ACESSO.HOMOLOGACAO,
  // BASE: ACESSO.PRODUCAO,

  VIACEP: "https://viacep.com.br/ws",
  IBGE: "https://servicodados.ibge.gov.br"
}

const TEMPO_ESPERA_PARA_PESQUISA_AUTOCOMPLETE = 1500;

const INPUT_KEY_CODES = {
  enter: 13
};

const VERSAO = 'v1.3.0';

export {
  INPUT_KEY_CODES,
  TEMPO_ESPERA_PARA_PESQUISA_AUTOCOMPLETE,
  URLS,
  VERSAO,
}
