<template>
  <div class="pa-5">
    <v-snackbar
      v-model="snackbar"
      :vertical="false"
      :timeout="timeout"
      top="top"
      :color="tipo"
      elevation="0"
      multi-line
      right
    >
      {{ mensagem }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar = false">OK</v-btn>
      </template>
    </v-snackbar>

    <janela-confirmacao
      v-model="abrirJanelaConfirmacao"
      :callbackPrimario="deletarAgendamento"
      :mensagem="mensagemDeExclusao"
    />

    <v-row>
      <v-col sm="12" lg="12">
        <h1>Agenda</h1>
        <v-divider></v-divider>
      </v-col>
    </v-row>
    <div v-if="loader">
      <v-row>
        <v-col cols="12" md="8">
          <v-skeleton-loader type="table-heading"></v-skeleton-loader>
          <v-row class="pa-0">
            <v-col cols="12" md="3">
              <v-skeleton-loader type="image"></v-skeleton-loader>
            </v-col>
            <v-col cols="12" md="3">
              <v-skeleton-loader type="image"></v-skeleton-loader>
            </v-col>
            <v-col cols="12" md="3">
              <v-skeleton-loader type="image"></v-skeleton-loader>
            </v-col>
            <v-col cols="12" md="3">
              <v-skeleton-loader type="image"></v-skeleton-loader>
            </v-col>
          </v-row>
          <v-row class="pa-0">
            <v-col cols="12" md="3">
              <v-skeleton-loader type="image"></v-skeleton-loader>
            </v-col>
            <v-col cols="12" md="3">
              <v-skeleton-loader type="image"></v-skeleton-loader>
            </v-col>
            <v-col cols="12" md="3">
              <v-skeleton-loader type="image"></v-skeleton-loader>
            </v-col>
            <v-col cols="12" md="3">
              <v-skeleton-loader type="image"></v-skeleton-loader>
            </v-col>
          </v-row>
          <v-row class="pa-0">
            <v-col cols="12" md="3">
              <v-skeleton-loader type="image"></v-skeleton-loader>
            </v-col>
            <v-col cols="12" md="3">
              <v-skeleton-loader type="image"></v-skeleton-loader>
            </v-col>
            <v-col cols="12" md="3">
              <v-skeleton-loader type="image"></v-skeleton-loader>
            </v-col>
            <v-col cols="12" md="3">
              <v-skeleton-loader type="image"></v-skeleton-loader>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" md="4">
          <v-skeleton-loader
            min-width="300px"
            class="pt-2"
            type="button"
          ></v-skeleton-loader>
          <v-skeleton-loader
            min-width="300px"
            class="pt-2"
            type="heading"
          ></v-skeleton-loader>
          <v-divider class="my-3"></v-divider>
          <v-skeleton-loader class="pt-2" type="article@4"></v-skeleton-loader>
        </v-col>
      </v-row>
    </div>

    <v-row class="px-2" v-show="!loader">
      <v-col class="px-0 mx-0">
        <v-row>
          <!-- Calendário Agenda -->
          <v-col cols="12" xs="12" sm="8" class="pl-4">
            <v-row class="mb-1">
              <v-col cols="12" sm="auto" lg="9" md="8">
                <v-btn
                  outlined
                  class="mr-4"
                  color="grey darken-2"
                  @click="setToday"
                >
                  Hoje
                </v-btn>

                <v-btn text icon @click="prev">
                  <chevron-left-icon size="1.5x"></chevron-left-icon>
                </v-btn>

                <span
                  elevation="0"
                  class="mes-selecionado px-5"
                  flat
                  v-if="$refs.calendar"
                >
                  {{ $refs.calendar.title }}
                </span>
                <v-btn text icon @click="next">
                  <chevron-right-icon size="1.5x"></chevron-right-icon>
                </v-btn>
                <v-menu right offset-x>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon text v-bind="attrs" v-on="on">
                      <filter-icon size="1.5x"></filter-icon>
                    </v-btn>
                  </template>

                  <v-list>
                    <v-list-item
                      v-for="(item, index) in listaDeUsuarios"
                      :key="index"
                      @click="obterAgendamentosPorUsuario(item)"
                    >
                      <v-list-item-title>{{ item.nome }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-col>
              <v-col cols="12" sm="auto" lg="3" md="4" class="text-right">
                <v-spacer></v-spacer>
                <small v-show="filtroPorUsuario">Filtrado por:</small>
                <v-chip
                  v-show="filtroPorUsuario"
                  class="ma-2"
                  close
                  small
                  outlined
                  color="primary"
                  @click:close="limparFiltrarPorUsuario()"
                >
                  {{ this.usuarioSelecionado.nome }}
                </v-chip>
              </v-col>
            </v-row>
            <v-sheet class="calendario-agenda">
              <v-calendar
                ref="calendar"
                v-model="focus"
                color="primary"
                locale="pt"
                :events="eventos"
                :event-overlap-mode="mode"
                :event-overlap-threshold="45"
                :type="type"
                @click:event="abrirVisualizacaoAgendamentos"
                @click:more="verAgendamentos"
                @click:date="abrirTelaCadastroAgendamento"
                @change="obterEventos"
              ></v-calendar>
            </v-sheet>
            <v-row class="my-2">
              <v-col cols="12" md="6">
                <v-btn
                  class="btn-terciario"
                  text
                  :class="{ ativo: this.botaoMes }"
                  @click="exibirMes()"
                >
                  Mês
                </v-btn>
                <v-btn
                  class="btn-terciario"
                  text
                  :class="{ ativo: this.botaoSemana }"
                  @click="exibirSemana()"
                >
                  Semana
                </v-btn>
                <v-btn
                  class="btn-terciario"
                  text
                  :class="{ ativo: this.botaoQuatroDias }"
                  @click="exibirQuatroDias()"
                >
                  4 Dias
                </v-btn>
                <v-btn
                  class="btn-terciario"
                  text
                  :class="{ ativo: this.botaoDia }"
                  @click="exibirDia()"
                >
                  Dia
                </v-btn>
              </v-col>
            </v-row>
          </v-col>

          <!-- Próximos agendamentos -->
          <v-col cols="12" xs="12" sm="4">
            <v-btn
              text
              class="btn-primario mr-4"
              @click="abrirTelaCadastroAgendamento"
            >
              <plus-icon size="2x" class="pr-2"></plus-icon>
              Novo agendamento
            </v-btn>
            <v-spacer class="py-3"></v-spacer>
            <h2>Próximos agendamentos</h2>
            <v-divider class="pb-3"></v-divider>

            <div class="sem-registro" v-show="!proximosAgendamentos.length">
              <h1 class="titulo-sem-registro">Não há próximos agendamentos</h1>
            </div>

            <div class="agendamentos">
              <v-card
                v-for="agenda in proximosAgendamentos"
                :key="agenda.id"
                elevation="0"
                class="proximos-compromissos"
              >
                <v-card
                  class="cor-evento"
                  elevation="0"
                  :color="agenda.tipoAgendamento.cor"
                ></v-card>

                <h2 class="titulo-proximo-agendamento">
                  <strong>
                    {{ agenda.tipoAgendamento.descricao }} -
                    {{ agenda.usuario.nome }}
                  </strong>
                </h2>
                <v-divider class="pb-2"></v-divider>
                <p>
                  {{ agenda.dataHoraInicial | data }} às
                  {{ agenda.dataHoraInicial | hora }}
                </p>
                <p v-show="agenda.tipoAgendamento.id == 2">
                  {{ agenda.procedimento.descricao }} -
                  {{ agenda.classificacao.descricao }}
                </p>
              </v-card>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <!-- Modal visualização agendamento -->
    <v-dialog
      v-model="visualizarAgendamento"
      persistent
      scrollable
      min-height="500px"
      max-width="550px"
    >
      <v-card width="400px" flat>
        <v-toolbar flat>
          <v-card
            class="cor-evento-visualizacao"
            elevation="0"
            :color="agendamento.tipoAgendamento.cor"
          ></v-card>
          <v-toolbar-title
            elevation="0"
            v-html="
              agendamento.usuario.nome +
              ` - ` +
              agendamento.tipoAgendamento.descricao
            "
          >
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-show="agendamento.tipoAgendamento.id == 2"
                v-bind="attrs"
                v-on="on"
                text
                icon
                @click="visualizarPacienteAgendado()"
              >
                <user-icon class="custom-class" size="1.2x"></user-icon>
              </v-btn>
            </template>
            <span>Ir para o prontuário do paciente</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                text
                icon
                @click="abrirTelaEdicaoAgendamento(agendamento)"
              >
                <edit-icon class="custom-class" size="1.2x"></edit-icon>
              </v-btn>
            </template>
            <span>Editar</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                text
                icon
                @click="confirmarDelecaoAgendamento()"
              >
                <trash-icon class="custom-class" size="1.2x"></trash-icon>
              </v-btn>
            </template>
            <span>Excluir</span>
          </v-tooltip>
        </v-toolbar>
        <v-divider></v-divider>
        <v-card-text class="pa-5">
          <v-row>
            <v-col
              v-show="agendamento.tipoAgendamento.id == 2"
              cols="12"
              md="12"
            >
              <label class="label-preview-agendamento">Paciente</label>
              <p>
                {{ agendamento.paciente.nome }}
              </p>
            </v-col>
            <v-col
              v-show="agendamento.tipoAgendamento.id == 2"
              cols="12"
              md="6"
            >
              <label class="label-preview-agendamento">Procedimento</label>
              <p>
                {{ agendamento.procedimento.descricao }}
              </p>
            </v-col>
            <v-col
              v-show="agendamento.tipoAgendamento.id == 2"
              cols="12"
              md="6"
            >
              <label class="label-preview-agendamento">Classificação</label>
              <p>
                {{ agendamento.classificacao.descricao }}
              </p>
            </v-col>
            <v-col cols="12" md="6">
              <label class="label-preview-agendamento"
                >Data e hora inicial</label
              >
              <p>
                {{ agendamento.dataHoraInicial | dataEHora }}
              </p>
            </v-col>
            <v-col cols="12" md="6">
              <label class="label-preview-agendamento">Data e hora final</label>
              <p>
                {{ agendamento.dataHoraFinal | dataEHora }}
              </p>
            </v-col>
            <v-col cols="12" md="12">
              <label class="label-preview-agendamento">Observação</label>
              <p>{{ agendamento.observacao }}</p>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <CadastroConsultaModal
            v-if="
              agendamento.tipoAgendamento.id == 2 && !$store.state.consulta.id
            "
            :agendamento="agendamento"
          >
          </CadastroConsultaModal>
          <v-spacer></v-spacer>

          <v-btn
            text
            class="btn-primario text-right"
            @click="fecharVisualizacaoAgendamento"
          >
            Fechar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Modal Cadastro de Agendamento -->
    <v-dialog
      v-model="cadastroDeAgendamento"
      persistent
      scrollable
      min-height="600px"
      max-width="980px"
    >
      <v-card flat>
        <v-card-title>
          <h1 class="titulo-modal">
            {{ modoEdicao ? "Editar agendamento" : "Novo agendamento" }}
          </h1>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pt-5">
          <v-form validation v-model="cadastroAgendamentoValido" ref="form">
            <v-row>
              <v-col cols="12" lg="5">
                <v-text-field
                  v-model="agendamento.descricao"
                  label="Descrição"
                  autofocus
                  counter="50"
                  maxlength="50"
                ></v-text-field>
              </v-col>

              <v-col cols="12" lg="3">
                <v-autocomplete
                  :items="tiposDeAgendamentos"
                  label="Tipo de agendamento"
                  item-value="id"
                  item-text="descricao"
                  v-model="agendamento.tipoAgendamento.id"
                  :rules="campoObrigatorio"
                >
                </v-autocomplete>
              </v-col>

              <v-col cols="12" sm="4">
                <v-autocomplete
                  label="Nutricionista"
                  :items="listaDeUsuarios"
                  item-value="id"
                  item-text="codigoNome"
                  v-model="agendamento.usuario.id"
                  :rules="campoObrigatorio"
                >
                </v-autocomplete>
              </v-col>

              <v-col cols="12" lg="3">
                <date-picker
                  label="Data inicial"
                  v-model="agendamento.dataHoraInicial"
                  @onChange="atualizarDataInicialEFinal"
                  :rules="campoObrigatorio"
                  :clearable="false"
                >
                </date-picker>
              </v-col>

              <v-col cols="12" lg="3">
                <v-autocomplete
                  label="Hora inicial"
                  :items="horas"
                  prepend-icon="mdi-clock"
                  v-mask="'##:##'"
                  dense
                  v-model="horaInicial"
                  input-mode="decimal"
                  :rules="campoObrigatorio"
                >
                </v-autocomplete>
              </v-col>

              <v-col cols="12" lg="3">
                <date-picker
                  label="Data final"
                  :dataMinima="agendamento.dataHoraInicial"
                  v-model="agendamento.dataHoraFinal"
                  :rules="campoObrigatorio"
                  :clearable="false"
                >
                </date-picker>
              </v-col>

              <v-col cols="12" lg="3">
                <v-autocomplete
                  label="Hora Final"
                  :items="horas"
                  prepend-icon="mdi-clock"
                  v-mask="'##:##'"
                  dense
                  v-model="horaFinal"
                  input-mode="decimal"
                  :rules="campoObrigatorio"
                >
                </v-autocomplete>
              </v-col>

              <!-- Consulta -->
              <v-col
                v-if="agendamento.tipoAgendamento.id == 2"
                cols="12"
                lg="3"
              >
                <v-autocomplete
                  label="Procedimento"
                  :items="listaTiposDeProcedimentos"
                  dense
                  item-text="descricao"
                  item-value="id"
                  v-model="agendamento.procedimento.id"
                  :rules="campoObrigatorio"
                >
                </v-autocomplete>
              </v-col>

              <!-- Consulta -->
              <v-col
                v-if="agendamento.tipoAgendamento.id == 2"
                cols="12"
                lg="3"
              >
                <v-autocomplete
                  label="Classificação"
                  :items="listaTiposDeClassificacao"
                  dense
                  item-text="descricao"
                  item-value="id"
                  v-model="agendamento.classificacao.id"
                  :rules="campoObrigatorio"
                >
                </v-autocomplete>
              </v-col>

              <!-- Consulta -->
              <v-col
                v-if="agendamento.tipoAgendamento.id == 2"
                cols="12"
                lg="6"
              >
                <v-row>
                  <v-col cols="12" lg="10">
                    <v-autocomplete
                      v-model="agendamento.paciente.id"
                      label="Paciente"
                      :items="listaDePacientes"
                      item-value="id"
                      item-text="codigoNome"
                      :rules="campoObrigatorio"
                      dense
                    >
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="12" lg="2">
                    <CadastroRapidoPaciente
                      @paciente-cadastrado="atualizarPaciente"
                    />
                  </v-col>
                </v-row>
              </v-col>

              <v-col cols="12" lg="12">
                <v-textarea
                  label="Observação"
                  dense
                  no-resize
                  outlined
                  rows="3"
                  v-model="agendamento.observacao"
                >
                </v-textarea>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-col class="text-right">
            <v-btn
              text
              class="btn-primario mr-2"
              @click="salvarAgendamento"
              :disabled="!cadastroAgendamentoValido || salvando"
              :loading="salvando"
            >
              Salvar
            </v-btn>
            <v-btn text class="btn-secundario" @click="fecharModalCadastro">
              Fechar
            </v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// APIs
import apiAgenda from "@/api/agendamento/agendamento-api";
import apiUsuario from "@/api/usuario/usuario-api";
import apiProcedimentos from "@/api/agendamento/procedimentos-api";
import apiClassificacoes from "@/api/agendamento/classificacoes-api";
import apiPacientes from "@/api/paciente/paciente-api";

// Models
import AgendamentoModel from "@/model/agendamento/agendamento-model";
import TipoAgendamentoModel from "@/model/agendamento/tipo-agendamento-model";
import UsuarioModel from "@/model/usuario-model";
import ProcedimentoModel from "@/model/agendamento/procedimento-model";
import ClassificacaoModel from "@/model/agendamento/classificacao-model";
import PacienteModel from "@/model/paciente/paciente-model";

// Components
import JanelaConfirmacao from "@/components/JanelaConfirmacao";
import CadastroConsultaModal from "@/components/modais/consulta/CadastroConsulta";
import DatePicker from "@/components/comum/Datepicker";
import CadastroRapidoPaciente from "../../components/modais/paciente/CadastroRapidoPaciente";

// Utils
import utilsData from "@/utils/data";

// Tracking
import mixpanel from "mixpanel-browser";

// Outros
import mixinMensagen from "@/mixin/mixin-mensagem";
import moment from "moment";
import { HORARIOS } from "@/constants/geral-constantes";

export default {
  name: "Agenda",
  mixins: [mixinMensagen],
  components: {
    JanelaConfirmacao,
    CadastroConsultaModal,
    DatePicker,
    CadastroRapidoPaciente,
  },
  data() {
    return {
      abrirJanelaConfirmacao: false,
      mensagemDeExclusao: "Confirma a exclusão deste agendamento?",
      // Geral
      listaDeAgendamentos: [],
      tiposDeAgendamentos: [],
      listaDeUsuarios: [],
      listaTiposDeProcedimentos: [],
      listaTiposDeClassificacao: [],
      listaDePacientes: [],
      agendamento: new AgendamentoModel(),
      salvando: false,
      loader: true,
      usuarioSelecionado: [],

      // Calendario
      focus: "",
      eventos: [],
      mode: "stack",
      type: "month",
      botaoMes: false,
      botaoSemana: false,
      botaoQuatroDias: false,
      botaoDia: false,
      filtroPorUsuario: false,

      // Visualizacao agendamento
      visualizarAgendamento: false,

      // Cadastro agendamento
      cadastroDeAgendamento: false,
      cadastroAgendamentoValido: false,
      modoEdicao: false,
      horas: HORARIOS,
      horaInicial: undefined,
      horaFinal: undefined,
      campoObrigatorio: [(v) => !!v || "Este campo é obrigatório"],
    };
  },

  mounted() {
    mixpanel.track("page_view", { nome_pagina: "agenda" });
    this.obterTodosAgendamentos();
    this.obterTiposDeAgendamento();
    this.obterTodosOsUsuariosVinculadosAssinatura();
    this.obterProcedimentos();
    this.obterClassificacoes();
    this.obterTodosPacientes();
    this.exibirMes();
  },
  watch: {
    // "agendamento.usuario.id": function (id) {
    //   this.obterAgendamentosPorUsuario(id);
    // },
  },
  filters: {
    data(valor) {
      return utilsData.aplicarMascaraEmDataIso(valor);
    },
    hora(valor) {
      return utilsData.aplicarMascaraHoraSemSegundos(valor);
    },
    dataEHora(valor) {
      return utilsData.aplicarMascaraDataHoraSemSegundos(valor);
    },
  },

  computed: {
    proximosAgendamentos() {
      if (this.listaDeAgendamentos.length == 0) {
        return [];
      }

      let proximos = [].concat(this.listaDeAgendamentos);
      proximos = proximos.filter(
        (i) => new Date(i.dataHoraInicial) >= new Date()
      );

      // ordena o array de acordo com a data e hora inicial da consulta (menor data primeiro)
      proximos.sort((a, b) => {
        let dataA = new Date(a.dataHoraInicial);
        let dataB = new Date(b.dataHoraInicial);
        if (dataA > dataB) {
          return 1;
        }
        if (dataA < dataB) {
          return -1;
        }
        return 0;
      });

      return proximos.splice(0, 4);
    },
  },

  methods: {
    limparFiltrarPorUsuario() {
      mixpanel.track("click", {
        nome_pagina: "agenda",
        nome_botao: "btn_icone_limpar_filtro",
      });
      this.obterTodosAgendamentos();
      this.filtroPorUsuario = false;
      this.agendamento.usuario = new UsuarioModel();
    },
    visualizarPacienteAgendado() {
      mixpanel.track("click", {
        nome_pagina: "agenda",
        nome_botao: "btn_icone_ver_paciente",
      });
      this.$router.push({
        name: "prontuario-paciente",
        params: { id: this.agendamento.paciente.id },
      });
    },

    // Acoes
    abrirVisualizacaoAgendamentos(evento) {
      mixpanel.track("click", {
        nome_pagina: "agenda",
        nome_botao: "btn_visualizar_agendamento",
      });
      this.visualizarAgendamento = true;
      this.agendamento = new AgendamentoModel(evento.event.agendamento);
    },
    fecharVisualizacaoAgendamento() {
      mixpanel.track("click", {
        nome_pagina: "agenda",
        nome_botao: "fechar_modal_visualizar_agendamento",
      });
      this.visualizarAgendamento = false;
      this.limparForm();
    },
    abrirTelaCadastroAgendamentoPelaData(objData) {
      mixpanel.track("click", {
        nome_pagina: "agenda",
        nome_botao: "btn_dia_novo_agendamento",
      });
      this.abrirTelaCadastroAgendamento(objData);
    },
    abrirTelaCadastroAgendamento(objData) {
      mixpanel.track("click", {
        nome_pagina: "agenda",
        nome_botao: "btn_novo_agendamento",
      });
      objData =
        (objData && objData.date) ||
        moment().locale("pt-br").format("YYYY-MM-DD");
      this.agendamento.dataHoraInicial = objData + "T00:00:00";
      this.agendamento.dataHoraFinal = objData + "T00:00:00";
      this.modoEdicao = false;
      this.cadastroDeAgendamento = true;

      // if (this.usuarioSelecionado) {
      //   this.agendamento.usuario = this.usuarioSelecionado;
      // }
    },
    abrirTelaEdicaoAgendamento() {
      mixpanel.track("click", {
        nome_pagina: "agenda",
        nome_botao: "abrir_modal_editar_agendamento",
      });
      this.modoEdicao = true;
      this.horaInicial = utilsData.aplicarMascaraHoraSemSegundos(
        this.agendamento.dataHoraInicial
      );
      this.horaFinal = utilsData.aplicarMascaraHoraSemSegundos(
        this.agendamento.dataHoraFinal
      );
      this.modoEdicao = true;
      this.cadastroDeAgendamento = true;
    },
    deletarAgendamento() {
      apiAgenda
        .deletar(this.agendamento.id)
        .then(() => {
          this.removerAgendamentoDaLista();
          this.obterEventos();
          this.abrirJanelaConfirmacao = false;
          this.fecharVisualizacaoAgendamento();
          this.dispararMensagem(`Agendamento excluido com sucesso!`, "success");
        })
        .catch((error) => {
          this.dispararMensagem(error.response.data.mensagem, "error");
        });
    },
    removerAgendamentoDaLista() {
      let indice = this.listaDeAgendamentos.findIndex(
        (a) => a.id == this.agendamento.id
      );
      if (indice < 0) return;
      this.listaDeAgendamentos.splice(indice, 1);
    },
    limparForm() {
      this.$refs.form && this.$refs.form.reset();
      this.agendamento = new AgendamentoModel();
    },
    salvarAgendamento() {
      this.salvando = true;

      if (!this.validarAgendamento()) {
        this.salvando = false;
        return;
      }

      this.gerenciarDescricao();

      this.modoEdicao ? this.editarAgendamento() : this.cadastrarAgendamento();
    },
    gerenciarDescricao() {
      if (!this.agendamento.descricao) {
        this.preencherDescricaoAutomaticamente();
        return;
      }
      let partesDescricao = this.agendamento.descricao.split(" - ").length;
      if (partesDescricao == 2) {
        this.preencherDescricaoAutomaticamente();
      }
    },
    preencherDescricaoAutomaticamente() {
      let tipoAgendamentoDescricao = this.tiposDeAgendamentos.find(
        (t) => t.id == this.agendamento.tipoAgendamento.id
      ).descricao;
      let usuarioNome = this.listaDeUsuarios.find(
        (u) => u.id == this.agendamento.usuario.id
      ).nome;
      if (this.agendamento.tipoAgendamento.id != 2) {
        this.agendamento.descricao = `${tipoAgendamentoDescricao} - ${usuarioNome}`;
      } else {
        let pacienteNome = this.listaDePacientes.find(
          (p) => p.id == this.agendamento.paciente.id
        ).nome;
        let procedimentoDescricao = this.listaTiposDeProcedimentos.find(
          (p) => p.id == this.agendamento.procedimento.id
        ).descricao;
        this.agendamento.descricao = `${pacienteNome} (${procedimentoDescricao}) - ${usuarioNome}`;
      }
    },
    validarAgendamento() {
      if (
        !this.$refs.form.validate() ||
        this.agendamento.modeloValidoParaCadastro()[0]
      ) {
        this.dispararMensagem(
          "Por favor, preencha todos os campos obrigatórios.",
          "error"
        );
        return false;
      }

      let dataHoraInicial = this.agendamento.dataHoraInicial.substring(0, 11);
      let dataHoraFinal = this.agendamento.dataHoraFinal.substring(0, 11);
      dataHoraInicial = dataHoraInicial + this.horaInicial + ":00";
      dataHoraFinal = dataHoraFinal + this.horaFinal + ":00";

      if (new Date(dataHoraInicial) > new Date(dataHoraFinal)) {
        this.dispararMensagem(
          "Data e hora final não pode ser menor que data e hora inicial",
          "error"
        );
        return false;
      }

      this.agendamento.dataHoraInicial = dataHoraInicial;
      this.agendamento.dataHoraFinal = dataHoraFinal;
      return true;
    },
    fecharModalCadastro() {
      this.visualizarAgendamento = false;
      this.cadastroDeAgendamento = false;
      this.limparForm();
    },
    editarAgendamento() {
      apiAgenda
        .atualizar(this.agendamento)
        .then((resposta) => {
          this.atualizarAgendamentoNaLista(new AgendamentoModel(resposta.data));
          this.obterEventos();
          this.fecharModalCadastro();
          this.dispararMensagem(
            `Agendamento atualizado com sucesso!`,
            "success"
          );
        })
        .catch((error) => {
          this.dispararMensagem(error.response.data.mensagem, "error");
        })
        .finally((this.salvando = false));
    },
    atualizarAgendamentoNaLista(agendamento) {
      let indice = this.listaDeAgendamentos.findIndex(
        (a) => a.id == agendamento.id
      );
      if (indice < 0) return;
      this.listaDeAgendamentos.splice(indice, 1, agendamento);
    },
    cadastrarAgendamento() {
      apiAgenda
        .cadastrar(this.agendamento)
        .then((resposta) => {
          this.listaDeAgendamentos.push(new AgendamentoModel(resposta.data));
          this.obterEventos();
          this.fecharModalCadastro();

          this.dispararMensagem(
            `Agendamento realizado com sucesso!`,
            "success"
          );
        })
        .catch((error) => {
          this.dispararMensagem(error.response.data.mensagem, "error");
        })
        .finally((this.salvando = false));
    },

    atualizarPaciente(evento) {
      let paciente = new PacienteModel(evento);
      this.listaDePacientes.push(paciente);
      this.agendamento.paciente.id = paciente.id;
    },

    // Mounted
    obterAgendamentosPorUsuario(item) {
      mixpanel.track("click", {
        nome_pagina: "agenda",
        nome_botao: "btn_icone_filtro_por_usuario",
      });
      this.loader = true;
      this.filtroPorUsuario = true;
      this.usuarioSelecionado = item;
      apiAgenda
        .obterTodos()
        .then((resposta) => {
          let id = item.id;
          let filtrados = resposta.data.filter((a) => a.usuario.id == id);
          this.listaDeAgendamentos = filtrados.map(
            (a) => new AgendamentoModel(a)
          );
          this.obterEventos();
          this.loader = false;
        })
        .catch((error) =>
          this.dispararMensagem(error.response.data.mensagem, "error")
        );
    },
    obterTodosAgendamentos() {
      this.loader = true;
      apiAgenda
        .obterTodos()
        .then((resposta) => {
          this.listaDeAgendamentos = resposta.data.map(
            (a) => new AgendamentoModel(a)
          );
          this.obterEventos();
          this.loader = false;
        })
        .catch((error) => {
          this.dispararMensagem(error.response.data.mensagem, "error");
        });
    },
    obterTiposDeAgendamento() {
      apiAgenda
        .obterTiposDeAgendamento()
        .then((resposta) => {
          this.tiposDeAgendamentos = resposta.data.map(
            (t) => new TipoAgendamentoModel(t)
          );
        })
        .catch(() => {
          /*Não precisa fazedr nada aqui, é responsabilidade do sistema e não do cliente*/
        });
    },
    obterTodosOsUsuariosVinculadosAssinatura() {
      apiUsuario
        .obterTodos()
        .then((resposta) => {
          this.listaDeUsuarios = resposta.data.map((u) => new UsuarioModel(u));
        })
        .catch((error) =>
          this.dispararMensagem(error.response.data.mensagem, "error")
        );
    },
    obterProcedimentos() {
      apiProcedimentos
        .obterTodos()
        .then((resposta) => {
          this.listaTiposDeProcedimentos = resposta.data.map(
            (p) => new ProcedimentoModel(p)
          );
        })
        .catch(() => {
          /*Não precisa fazedr nada aqui, é responsabilidade do sistema e não do cliente*/
        });
    },
    obterClassificacoes() {
      apiClassificacoes
        .obterTodos()
        .then((resposta) => {
          this.listaTiposDeClassificacao = resposta.data.map(
            (c) => new ClassificacaoModel(c)
          );
        })
        .catch(() => {
          /*Não precisa fazedr nada aqui, é responsabilidade do sistema e não do cliente*/
        });
    },
    obterTodosPacientes() {
      apiPacientes
        .obterTodos()
        .then((resposta) => {
          this.listaDePacientes = resposta.data.map(
            (p) => new PacienteModel(p)
          );
        })
        .catch(() => {
          /*Não precisa fazedr nada aqui, é responsabilidade do sistema e não do cliente*/
        });
    },

    // Calendario
    setToday() {
      mixpanel.track("click", {
        nome_pagina: "agenda",
        nome_botao: "btn_icone_ver_hoje",
      });
      this.focus = "";
    },
    prev() {
      mixpanel.track("click", {
        nome_pagina: "agenda",
        nome_botao: "btn_icone_ver_anterior",
      });
      this.$refs.calendar.prev();
    },
    next() {
      mixpanel.track("click", {
        nome_pagina: "agenda",
        nome_botao: "btn_icone_ver_proximo",
      });
      this.$refs.calendar.next();
    },
    verAgendamentos({ date }) {
      this.focus = date;
      this.exibirDia();
    },
    exibirDia() {
      mixpanel.track("click", {
        nome_pagina: "agenda",
        nome_botao: "btn_ver_por_um_dia",
      });
      this.type = "day";
      this.botaoDia = true;
      this.botaoQuatroDias = false;
      this.botaoSemana = false;
      this.botaoMes = false;
    },
    exibirQuatroDias() {
      mixpanel.track("click", {
        nome_pagina: "agenda",
        nome_botao: "btn_ver_por_quatro_dias",
      });
      this.type = "4day";
      this.botaoDia = false;
      this.botaoQuatroDias = true;
      this.botaoSemana = false;
      this.botaoMes = false;
    },
    exibirSemana() {
      mixpanel.track("click", {
        nome_pagina: "agenda",
        nome_botao: "btn_ver_por_semana",
      });
      this.type = "week";
      this.botaoDia = false;
      this.botaoQuatroDias = false;
      this.botaoSemana = true;
      this.botaoMes = false;
    },
    exibirMes() {
      mixpanel.track("click", {
        nome_pagina: "agenda",
        nome_botao: "btn_ver_por_mes",
      });
      this.type = "month";
      this.botaoDia = false;
      this.botaoQuatroDias = false;
      this.botaoSemana = false;
      this.botaoMes = true;
    },
    obterEventos() {
      this.eventos = this.listaDeAgendamentos.map((a) => {
        return {
          name: a.descricao,
          start: new Date(a.dataHoraInicial).getTime(),
          end: new Date(a.dataHoraFinal).getTime(),
          timed: !a.diaInteiro,
          color: a.tipoAgendamento.cor,
          agendamento: a,
        };
      });
    },

    // Cadastro agendamento
    atualizarDataInicialEFinal() {
      let dataInicial = new Date(this.agendamento.dataHoraInicial);
      let dataFinal = new Date(this.agendamento.dataHoraFinal);
      if (dataFinal < dataInicial) {
        this.agendamento.dataHoraFinal = this.agendamento.dataHoraInicial;
      }
    },

    // Visualizacao Agendamento
    confirmarDelecaoAgendamento() {
      mixpanel.track("click", {
        nome_pagina: "agenda",
        nome_botao: "btn_excluir_agendamento",
      });
      this.abrirJanelaConfirmacao = true;
    },
  },
};
</script>

<style lang="scss" scoped>
// Calendario
.mes-selecionado {
  font-size: 1.2em;
  text-transform: capitalize;
  color: $cor_primaria;
}
.calendario-agenda {
  height: 80vh;
}
.agendamentos {
  overflow: hidden auto;
  position: relative;
}
@media (max-width: 320px) {
  .agendamentos {
    height: 30vh;
  }

  .calendario-agenda {
    max-height: 100%;
  }
}
.proximos-compromissos {
  padding: 10px;
  margin-top: 5px;
  border: 1px solid $cor_primaria 0.5;
  background-color: $cor_primaria_sombra;
}
.cor-evento {
  width: 15px;
  height: 15px;
  display: block;
  right: 0;
  position: absolute;
  border-radius: 3px;
  border: 1px solid rgb(204, 204, 204);
  margin-right: 10px;
}
.titulo-proximo-agendamento {
  font-size: 1.3em;
  color: $cor_primaria;
  font-weight: 300;
}

// Visualizacao Agendamento
.cor-evento-visualizacao {
  width: 15px;
  height: 15px;
  display: block;
  left: 0;
  position: relative;
  border-radius: 3px;
  border: 1px solid $branco;
  margin-right: 10px;
}
.label-preview-agendamento {
  font-weight: bold;
}
.ativo {
  background-color: $cor_primaria;
  color: $branco;
  border-radius: $radius-btn;
}
</style>