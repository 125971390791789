import axios from 'axios';
import { URLS } from '@/constants/configuracoes-constants';

function obterEstados() {
  return new Promise((resolve, reject) => {
    axios.get(`${URLS.IBGE}/api/v1/localidades/estados`)
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
}

function obterMunicipios(selectUF) {
  return new Promise((resolve, reject) => {
    axios.get(`${URLS.IBGE}/api/v1/localidades/estados/${selectUF}/municipios`)
      .then(response => resolve(response))
      .catch(error => reject(error));
  });
}

export default {
  obterEstados,
  obterMunicipios
};
