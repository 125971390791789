
import { TABELA_PERCENTIL_IMC_MESES } from '@/constants/tabelas/tabela-percentil-imc-meses/tabela-percentil-por-imc-meses'

function calcularImc(peso, altura) {
  peso = parseFloat(peso);
  altura = parseFloat(altura);

  if (isNaN(peso) || isNaN(altura)) {
    return 0;
  }

  var imc = peso / (altura * altura);

  imc = parseFloat(imc.toPrecision(3));

  return imc
}

/**
* @param {Number} sexo (1 - Masculino,  2 - Feminino)
* @param {Number} meses Idade em meses
* @param {Number} imc IMC do paciente
* @returns Retorna o percentil
*/
function calcularPercentil(sexo, meses, imc){
  let linha = TABELA_PERCENTIL_IMC_MESES.find(e => e.SEXO == sexo && e.MESES == meses);

  //Se retornar Zero é porque deu algum erro, ou a tabela esta incompleta ou o valor passado está incorreto.
  if (!linha) return 0;

  let variacao = linha.VARICAO_PERCENTIL.find(p => p.IMC(imc));

  //Se retornar Zero é porque deu algum erro, ou a tabela esta incompleta ou o valor passado está incorreto.
  if (!variacao) return 0;

  return variacao.PERCENTIL;
}


export default {
  calcularImc,
  calcularPercentil
}
