import api from '../api'

function cadastrar(assinante){
  return  new Promise((resolve, reject) =>{
    return api.post('/assinantes', assinante)
    .then(response => resolve(response))
    .catch(error => reject(error))
  });
}

export default {
  cadastrar  
}