<template>
    <div v-show="visivel" class="novaAnamnese">
        <v-expansion-panels 
            dark
            class="painel-nova-anamnese" 
            accordion
            v-model="paineisVisiveis"
        >
            <v-expansion-panel>
                <v-expansion-panel-header color="#C29879">
                    <span><file-text-icon size="1.2x" class="mr-2"></file-text-icon>Nova anamnese</span>
                </v-expansion-panel-header>
                <v-expansion-panel-content eager color="white">
                    <editor-nutri
                        v-model='texto'
                        editorToolbar="undo redo pagebreak formatselect bold italic underline alignleft aligncenter alignright alignjustify bullist"
                    ></editor-nutri>
                    <span class="black--text pl-3 pb-5">
                        {{ status }} <br>
                    </span>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
    </div>
</template>

<script>
import Editor from "@/components/comum/EditorTinymceNutri";
import Template from "@/model/template/Template";
import apiAnamnese from "@/api/consulta/anamneses-api";

export default {
    name: "NovaAnamnese",
    components: {
        "editor-nutri": Editor,
    },

    data() {
        return {
            template: new Template(),
            texto: '',
            timer: null,
            visivel: false,
            paineisVisiveis: undefined,
            status: 'Salvo'
        };
    },
    methods: {
        exibir() {
            this.texto = '';
            this.paineisVisiveis = 0;
            this.visivel = true;
        },
        ocultar() {
            this.visivel = false;
            this.texto = '';
            this.paineisVisiveis = undefined;
        },
        salvarAnamnese() {
          this.$store.commit("atualizarDescricaoAnamnese", this.texto);
            apiAnamnese
                .atualizar(this.$store.state.anamnese)
                .then(() => { 
                    this.$eventBus.$emit('anamnese-atualizada');
                    this.status = 'Salvo';
                })
                .catch(() => {  });
        }
    },
    watch: {
        texto() {
            if (!this.visivel) {
                return;
            }

            clearTimeout(this.timer);
            this.status = 'Digitando...';

            this.timer = setTimeout(() => {
                this.salvarAnamnese();
            }, 2500);
        },
    },
    mounted () {
        this.$eventBus.$on('nova-anamnese', this.exibir);
        this.$eventBus.$on('encerrando-consulta', () => { 
            clearTimeout(this.timer);
            this.salvarAnamnese();
        });
        this.$eventBus.$on('consulta-encerrada', this.ocultar);
    },
};
</script>

<style lang="scss" scoped>
.painel-nova-anamnese {
    display: block;
    bottom: 0;
    position: absolute;
    max-width: 500px;
    border-radius: 20px;
    right: 10px;
    max-height: 90vh;
}

@media only screen and (max-device-width:700px) {
  .painel-nova-anamnese {
    min-width: 100%;
    display: block;
    bottom: 0;
    right: 0;
  }
  .v-expansion-panel-content{
    height: 50%;
  }
}
.novaAnamnese {
    width: 100vw;
    bottom: 0;
    z-index: 1000;
    position: fixed;
}
.v-expansion-panel-content > .v-expansion-panel-content__wrap{
  padding: 0px;
  height: 45px;
}
.v-expansion-panel-header{
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
      padding: 15px;
}

.v-expansion-panel--active > .v-expansion-panel-header{
  min-height: 45px;
}
</style>