
import api from '../api'

export default {
  cadastrar(modelo) {
    modelo = modelo || {};
    return new Promise((resolve, reject) => {
      return api.post('/modelos', modelo)
        .then(response => resolve(response))
        .catch(error => reject(error))
    });
  },

  obterTodos() {
    return new Promise((resolve, reject) => {
      return api.get('/modelos')
        .then(response => resolve(response))
        .catch(error => reject(error))
    });
  },

  obterId(id) {
    return new Promise((resolve, reject) => {
      return api.get('/modelos/' + id)
        .then(response => resolve(response))
        .catch(error => reject(error))
    });
  },

  deletar(id) {
    return new Promise((resolve, reject) => {
      return api.delete('/modelos/' + id)
        .then(response => resolve(response))
        .catch(error => reject(error))
    });
  },

  atualizar(modelo) {
    modelo = modelo || {};
    return new Promise((resolve, reject) => {
      return api.put(`/modelos/${modelo.id}`, modelo)
        .then(response => resolve(response))
        .catch(error => reject(error))
    });
  },
};
