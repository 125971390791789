export default class TipoDeModeloModel {
  constructor(obj) {
    obj = obj || {};
    this.id = obj.id;
    this.descricao = obj.descricao
    this.guidAssinante = obj.guidAssinante;
    this.idUsuario = parseInt(obj.idUsuario);
    this.criadoEm = obj.criadoEm;
    this.atualizadoEm = obj.atualizadoEm;
    this.dataInativacao = obj.dataInativacao;
  }

  modeloValido() {
    return !!(this.descricao && this.con && this.conteudo);
  }
}