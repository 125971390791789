
export default class DocumentoModel {
  constructor(obj) {
    obj = obj || {};
    this.id = obj.id;
    this.descricao = obj.descricao
    this.conteudo = obj.conteudo;
    this.guidAssinante = obj.guidAssinante;
    this.idUsuario = parseInt(obj.idUsuario);
    this.idPaciente = parseInt(obj.idPaciente);
    this.criadoEm = obj.criadoEm;
    this.atualizadoEm = obj.atualizadoEm;
    this.dataInativacao = obj.dataInativacao;
  }

  modeloValidoParaCadastro() {
    return !!(this.descricao && this.idTipoDeModelo && this.conteudo);
  }
}