import TipoDeModeloModel from './tipo-de-modelo-model';

export default class ModeloModel {
  constructor(obj) {
    obj = obj || {};
    this.id = obj.id;
    this.descricao = obj.descricao
    this.idTipoDeModelo = obj.idTipoDeModelo
    this.tipoDeModelo = new TipoDeModeloModel(obj.tipoDeModelo);
    this.favorito = obj.favorito || false;
    this.conteudo = obj.conteudo;
    this.guidAssinante = obj.guidAssinante;
    this.idUsuario = parseInt(obj.idUsuario);
    this.criadoEm = obj.criadoEm;
    this.atualizadoEm = obj.atualizadoEm;
    this.dataInativacao = obj.dataInativacao;
  }

  modeloValidoParaCadastro() {
    return !!(this.descricao && this.idTipoDeModelo && this.conteudo);
  }
}