'use strict';

import variaveisPaciente from './variaveis-paciente.js';
import variaveisDatas from './variaveis-datas.js';

function obterVariaveisSistema(paciente) {
  paciente = paciente || {};

  return [].concat(
    variaveisPaciente.obter(paciente),
    variaveisDatas.obter(),
  );

}

export default { obterVariaveisSistema };