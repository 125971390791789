import PacienteModel from '../paciente/paciente-model';
import ConsultaModel from './consulta-model';

export default class AnamneseModel {
  constructor(obj) {
    obj = obj || {};
    this.id = obj.id;
    this.descricao = obj.descricao || '',
      this.paciente = new PacienteModel(obj.paciente),
      this.consulta = new ConsultaModel(obj.consulta),
      this.dataInativacao = obj.dataInativacao;
    this.criadoEm = obj.criadoEm;
    this.atualizadoEm = obj.atualizadoEm;
    this.idUsuario = parseInt(obj.idUsuario);
  }

  modeloValido() {
    let erros = [];

    let valido = this.paciente && this.paciente.id &&
      this.consulta && this.consulta.id;

    if (!valido) {
      erros.push(`Os campos paciente e consulta são obrigatórios.`);
    }

    return erros;
  }

}