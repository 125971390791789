import utilsData from '@/utils/data.js';

function templateImpressaoAnamnese(anamnese) {
  return `
    <!DOCTYPE html>
    <html lang="pt-br">
      <head>
        <meta charset="UTF-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <title>Impressão Anamnese</title>
      </head>
      <body style="font-family: Arial;">
        <h1 style="color:#c29879">Anamnese</h1>
        <p>
          <strong>
            Consulta: ${utilsData.aplicarMascaraEmDataIso(anamnese.criadoEm)}<br />
            Procedimento: ${anamnese.consulta.procedimento.descricao}<br />
            Classifica&ccedil;&atilde;o: ${anamnese.consulta.classificacao.descricao}
          </strong>
        </p>
        <p>
          ${anamnese.descricao}
        </p>
      </body>
    </html>`;
}

function templateImpressaoDocumento(documento) {
  return `<!DOCTYPE html>
    <html lang="pt-br">
      <head>
        <meta charset="UTF-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <title>${documento.descricao}</title>
          <style type="text/css" media="print">
              @media print {
              @page { margin: 0; }
              body { margin: 1.6cm; }
            }
          </style>
      </head>
      <body>
        <p>
          ${documento.conteudo}
        </p>
      </body>
    </html>`
}

export default {
  templateImpressaoAnamnese,
  templateImpressaoDocumento
}