import api from '../api'
function obterTodos(){
  return  new Promise((resolve, reject) =>{
    return api.get('/gestacoes')
    .then(response => resolve(response))
    .catch(error => reject(error))
  });
}

function obterId(id){
  return new Promise((resolve, reject) =>{
    return api.get(`/gestacoes/${id}`)
    .then(response => resolve(response))
    .catch(error => reject(error))
  });
}
function obterPaciente(id){
  return new Promise((resolve, reject) =>{
    return api.get(`/pacientes/${id}/gestacoes`)
    .then(response => resolve(response))
    .catch(error => reject(error))
  });
}

function cadastrar(gestacao){
  gestacao = gestacao || {};
  return  new Promise((resolve, reject) =>{
    return api.post('/gestacoes', gestacao)
    .then(response => resolve(response))
    .catch(error => reject(error))
  });
}
function deletar(gestacao){
  gestacao = gestacao || {};
  return  new Promise((resolve, reject) =>{
    return api.delete(`/gestacoes/${gestacao.id}`, gestacao)
    .then(response => resolve(response))
    .catch(error => reject(error))
  });
}
function atualizar(gestacao){
  gestacao = gestacao || {};
  return  new Promise((resolve, reject) =>{
    return api.put(`/gestacoes/${gestacao.id}`, gestacao)
    .then(response => resolve(response))
    .catch(error => reject(error))
  });
}


export default {
  obterTodos,
  obterPaciente,
  obterId,
  cadastrar,
  deletar,
  atualizar,
}