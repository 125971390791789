<template>
  <v-card-text class="sem-registro mt-5">
    <img
      v-show="imagemSrc"
      :src="require(`@/assets/img/figuras/${imagemSrc}`)"
      :width="largura"
      alt="Pacientes"
    />
    <h1 v-show="titulo" class="titulo-sem-registro">{{ titulo }}</h1>
    <p v-show="subtitulo" class="subtitulo-sem-registro">{{ subtitulo }}</p>

    <v-btn
      v-show="exibirBotaoPrimario"
      text
      class="btn-primario"
      @click="eventoBotaoPrimario()"
      >{{ textoBotaoPrimario }}</v-btn
    >
  </v-card-text>
</template>

<script>
export default {
  name: "TelaVazia",
  data() {
    return {};
  },
  props: {
    imagemSrc: { type: String, default: "" },
    largura: {type: String, default:"300"},
    titulo: { type: String, default: "" },
    subtitulo: { type: String, default: "" },
    textoBotaoPrimario: { type: String },
    eventoBotaoPrimario: { type: Function, default: () => {} },
    exibirBotaoPrimario: { type: Boolean, default: true },
  },
};
</script>

<style lang="scss">
</style>