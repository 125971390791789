<template>
  <v-app>  
    <Menu v-if="this.$router.currentRoute.name != 'Login' && 
                this.$router.currentRoute.name != 'Assinatura' &&
                this.$router.currentRoute.name != 'ConfirmacaoAssinatura'" ></Menu>
     <v-snackbar
      v-model="snackbar"
      :vertical="false"
      :timeout="timeout"
      top="top"
      :color="tipo"
      elevation="0"
      multi-line
      right
    >
      {{ mensagem }}
      <template v-slot:action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          @click="snackbar = false"
          >OK</v-btn
        >
      </template>
    </v-snackbar>

    <v-main>
        <router-view />
    </v-main>
    <NovaAnamnese> </NovaAnamnese>
    <span id="versao">{{ versao }}</span>
  </v-app>
</template>

<script>
import Menu from '@/components/menu/Menu';
import MixinMensagem from "@/mixin/mixin-mensagem";
import NovaAnamnese from '@/components/comum/NovaAnamnese';
import { VERSAO } from './constants/configuracoes-constants';

export default {
  name: "App",
  mixins:[MixinMensagem],
  components: {
    Menu,
    NovaAnamnese
  },

  data: () => {
      return {
        versao: VERSAO
      }
  },

  created(){
  },
  methods :{
  }

};
</script>
<style lang="scss" scoped>
#versao{
  display: block;
  position: fixed;
  bottom: 0;
  right: 0;
}
</style>
