import api from '../api'

function cadastrar(consulta) {
	consulta = consulta || {};
	return new Promise((resolve, reject) => {
		return api.post(`/consultas`, consulta)
			.then(response => resolve(response))
			.catch(error => reject(error))
	});
}


function obterConsultaPorPaciente(idPaciente) {
	return new Promise((resolve, reject) => {
		return api.get(`/pacientes/${idPaciente}/consultas`)
			.then(response => resolve(response))
			.catch(error => reject(error))
	});
}

function obterConsultaPorId(id) {
	return new Promise((resolve, reject) => {
		return api.get(`/consultas/${id}`)
			.then(response => resolve(response))
			.catch(error => reject(error))
	});
}

function atualizar(consulta) {
	consulta = consulta || {};
	return new Promise((resolve, reject) => {
		return api.put(`/consultas/${consulta.id}`, consulta)
			.then(response => resolve(response))
			.catch(error => reject(error))
	});
}

function deletar(idConsulta) {
	return new Promise((resolve, reject) => {
		return api.delete(`/consultas/${idConsulta}`)
			.then(response => resolve(response))
			.catch(error => reject(error))
	});
}

export default {
	cadastrar,
	obterConsultaPorPaciente,
	obterConsultaPorId,
	atualizar,
	deletar
}