'use strict';
/*
    Esse arquivo armazena os valores de referencia para cada gráfico de Gestante.
*/
const GESTANTE ={
    SEMANASGESTACAO: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42],
    BAIXOPESO: [19.9, undefined, undefined, undefined, undefined, undefined, 19.9, undefined, 20.1, undefined, 20.2, 20.3, 20.4, 20.6, 20.7, 20.8, 21, 21.1, 21.2, 21.4, 21.5, 21.7, 21.8, 22, 22.2, 22.4, 22.6, 22.7, 22.9, 23.1, 23.3, 23.4, 23.6, 23.8, 23.9, 24.1, 24.2, 24.4, 24.5, 24.7, 24.9, 25, 25],
    ADEQUADO: [24.9, undefined, undefined, undefined, undefined, undefined, 24.9, undefined, 25, undefined, 25.2, 25.3, 25.4, 25.6, 25.7, 25.8, 25.9, 26, 26.1, 26.2, 26.3, 26.4, 26.6, 26.8, 26.9, 27, 27.2, 27.3, 27.5, 27.6, 27.8, 27.9, 28, 28.1, 28.3, 28.4, 28.5, 28.7, 28.8, 28.9, 29.1, 29.2, 29.2],
    SOBREPESO: [30, undefined, undefined, undefined, undefined, undefined, 30, undefined, 30.1, undefined, 30.2, 30.3, 30.3, 30.4, 30.5, 30.6, 30.7, 30.8, 30.9, 30.9, 31, 31.1, 31.2, 31.3, 31.5, 31.6, 31.7, 31.8, 31.9, 32, 32.1, 32.2, 32.3, 32.4, 32.5, 32.6, 32.7, 32.8, 32.9, 33, 33.1, 33.2, 33.2],
    OBESIDADE: [40, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, 40],
    IDEAL: [22.45, undefined, undefined, undefined, undefined, undefined, 22.45, undefined, 22.65, undefined, 22.75, 22.85, 22.95, 23.15, 23.25, 23.35, 23.55, 23.65, 23.75, 23.95, 24.05, 24.25, 24.35, 24.55, 24.75, 24.95, 25.15, 25.25, 25.45, 25.65, 25.85, 25.95, 26.15, 26.35, 26.45, 26.65, 26.75, 26.95, 27.05, 27.25, 27.45, 27.55, 27.55]
}

export {
    GESTANTE,
}