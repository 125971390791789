const SEXO = {
  MASCULINO: "M",
  FEMININO: "F"
};

// const CORES = {
//   LEVE: "#8AE6B9",
//   MODERADO: "#FFDD80",
//   ATIVO: "#FF8080"
// }

const TIPO_PACIENTE = {
  BEBES: { ID: 1, DESCRICAO: "Bebês" },
  CRIANCAS: { ID: 2, DESCRICAO: "Crianças" },
  GESTANTES: { ID: 3, DESCRICAO: "Gestantes" },
  ADULTOS: { ID: 4, DESCRICAO: "Adultos" },
  ADOLESCENTES: { ID: 5, DESCRICAO: "Adolescentes" },
  LACTANTES: { ID: 6, DESCRICAO: "Lactantes" },
};

const LISTA_TIPO_PACIENTE = [
  TIPO_PACIENTE.BEBES,
  TIPO_PACIENTE.CRIANCAS,
  TIPO_PACIENTE.GESTANTES,
  TIPO_PACIENTE.ADULTOS,
  TIPO_PACIENTE.ADOLESCENTES,
  TIPO_PACIENTE.LACTANTES,
];

const FATOR_ATIVIDADE_CRIANCA = [
  {
    tipo: TIPO_PACIENTE.CRIANCAS.DESCRICAO,
    sexo: SEXO.MASCULINO,
    lista: [
      { valor: 1, descricao: "Sedentaria - (1)" },
      { valor: 1.13, descricao: "Leve - (1.13)" },
      { valor: 1.26, descricao: "Moderada - (1.26)" },
      { valor: 1.42, descricao: "Intensa - (1.42)" }
    ]
  },
  {
    tipo: TIPO_PACIENTE.CRIANCAS.DESCRICAO,
    sexo: SEXO.FEMININO,
    lista: [
      { valor: 1, descricao: "Sedentaria - (1)" },
      { valor: 1.16, descricao: "Leve - (1.16)" },
      { valor: 1.31, descricao: "Moderada (1.31)" },
      { valor: 1.56, descricao: "Intensa - (1.56)" }
    ]
  },
];

const FATOR_ATIVIDADE_LACTANTES = [
  {
    tipo: TIPO_PACIENTE.LACTANTES.DESCRICAO,
    sexo: SEXO.FEMININO,
    lista: [
      { valor: 1, descricao: "Sedentaria - (1)" },
      { valor: 1.12, descricao: "Pouco ativa - (1.12)" },
      { valor: 1.27, descricao: "Ativa - (1.27)" },
      { valor: 1.45, descricao: "Muita ativa - (1.45)" }
    ]
  }
];

const FATOR_ATIVIDADE_ADULTOS = [
  {
    tipo: TIPO_PACIENTE.ADULTOS.DESCRICAO,
    sexo: SEXO.MASCULINO,
    idadeMinima: 9,
    idadeMaxima: 18,
    lista: [
      { valor: 1, descricao: "Sedentaria - (1)" },
      { valor: 1.13, descricao: "Pouco ativa - (1.13)" },
      { valor: 1.26, descricao: "Ativa - (1.26)" },
      { valor: 1.42, descricao: "Muita ativa - (1.42)" }
    ]
  },
  {
    tipo: TIPO_PACIENTE.ADULTOS.DESCRICAO,
    sexo: SEXO.MASCULINO,
    idadeMinima: 19,
    idadeMaxima: 100,
    lista: [
      { valor: 1, descricao: "Sedentaria - (1)" },
      { valor: 1.11, descricao: "Pouco ativa - (1.11)" },
      { valor: 1.25, descricao: "Ativa - (1.25)" },
      { valor: 1.48, descricao: "Muita ativa - (1.48)" }
    ]
  },
  {
    tipo: TIPO_PACIENTE.ADULTOS.DESCRICAO,
    sexo: SEXO.FEMININO,
    idadeMinima: 9,
    idadeMaxima: 18,
    lista: [
      { valor: 1, descricao: "Sedentaria - (1)" },
      { valor: 1.16, descricao: "Leve - (1.16)" },
      { valor: 1.31, descricao: "Moderada - (1.31)" },
      { valor: 1.56, descricao: "Intensa - (1.56)" }
    ]
  },
  {
    tipo: TIPO_PACIENTE.ADULTOS.DESCRICAO,
    sexo: SEXO.FEMININO,
    idadeMinima: 19,
    idadeMaxima: 100,
    lista: [
      { valor: 1, descricao: "Sedentaria - (1)" },
      { valor: 1.12, descricao: "Leve - (1.12)" },
      { valor: 1.27, descricao: "Moderada - (1.27)" },
      { valor: 1.45, descricao: "Intensa - (1.45)" }
    ]
  }
];

const LISTA_FATOR_ATIVIDADE =
  FATOR_ATIVIDADE_CRIANCA.concat(FATOR_ATIVIDADE_LACTANTES)
    .concat(FATOR_ATIVIDADE_ADULTOS);

const CALCULOS_GESTANTES = [
  {
    tipo: TIPO_PACIENTE.GESTANTES.DESCRICAO,
    nome: `FAO/WHO/ONU(2004) - ${TIPO_PACIENTE.GESTANTES.DESCRICAO}`,
    calculo: (calculoEnergetico) => {

      if (calculoEnergetico.idade <= 30) {
        calculoEnergetico.taxaMetabolicaBasal = (calculoEnergetico.peso * 14.818) + 486.6;
      }
      else {
        calculoEnergetico.taxaMetabolicaBasal = (calculoEnergetico.peso * 8.126) + 845.6;
      }

      calculoEnergetico.gastoEnergeticoTotal = (calculoEnergetico.taxaMetabolicaBasal * calculoEnergetico.fatorAtividade) + calculoEnergetico.adicionalEnergetico;
    }
  }
];

const CALCULOS_CRIANCAS = [
  {
    tipo: TIPO_PACIENTE.CRIANCAS.DESCRICAO,
    nome: `(FAO/OMS, 1985)`,
    calculo: (calculoEnergetico) => {
      if (calculoEnergetico.idade < 2 || calculoEnergetico.idade > 10) {
        // Menos que 2 anos ou maior que 10 anos.
        calculoEnergetico.gastoEnergeticoTotal = 0; // Não calcular, retornar Zero, a formula não se aplica.
      }
      else if (calculoEnergetico.idade <= 3) {
        // Crianças entre 2 e 3 anos
        calculoEnergetico.gastoEnergeticoTotal =
          calculoEnergetico.paciente.sexo == "M" ?
            (60.9 * calculoEnergetico.peso) - 54 : // Masculino
            (61 * calculoEnergetico.peso) - 51; // Feminino
      }
      else {
        // Crianças de 3 a 10 anos.
        calculoEnergetico.gastoEnergeticoTotal =
          calculoEnergetico.paciente.sexo == "M" ?
            (22.7 * calculoEnergetico.peso) + 495 : // Masculino
            (22.5 * calculoEnergetico.peso) + 499; // Feminino
      }
    }
  },
  {
    tipo: TIPO_PACIENTE.CRIANCAS.DESCRICAO,
    nome: `(Schofield, 1985) - Com estatura`,
    calculo: (calculoEnergetico) => {
      if (calculoEnergetico.idade < 2 || calculoEnergetico.idade > 10) {
        // Menos que 2 anos ou maior que 10 anos.
        calculoEnergetico.gastoEnergeticoTotal = 0; // Não calcular, retornar Zero, a formula não se aplica.
      }
      else if (calculoEnergetico.idade <= 3) {
        // Crianças entre 2 e 3 anos
        calculoEnergetico.gastoEnergeticoTotal =
          calculoEnergetico.paciente.sexo == "M" ?
            (0.167 * calculoEnergetico.peso) + (15.174 * (calculoEnergetico.altura * 100)) - 617.6 : // Masculino altura em centimetros.
            (16.252 * calculoEnergetico.peso) + (10.232 * (calculoEnergetico.altura * 100)) - 413.5; // Feminino altura em centimetros.
      }
      else {
        // Crianças de 3 a 10 anos.
        calculoEnergetico.gastoEnergeticoTotal =
          calculoEnergetico.paciente.sexo == "M" ?
            (19.59 * calculoEnergetico.peso) + (1.303 * 110) + 414.9 : // Masculino
            (16.969 * calculoEnergetico.peso) + (1.618 * 110) + 371.2; // Feminino
      }
    }
  },
  {
    tipo: TIPO_PACIENTE.CRIANCAS.DESCRICAO,
    nome: `(Schofield, 1985) - Sem estatura`,
    calculo: (calculoEnergetico) => {
      if (calculoEnergetico.idade < 2 || calculoEnergetico.idade > 10) {
        // Menos que 2 anos ou maior que 10 anos.
        calculoEnergetico.gastoEnergeticoTotal = 0; // Não calcular, retornar Zero, a formula não se aplica.
      }
      else if (calculoEnergetico.idade <= 3) {
        // Crianças entre 2 e 3 anos
        calculoEnergetico.gastoEnergeticoTotal =
          calculoEnergetico.paciente.sexo == "M" ?
            (59.48 * calculoEnergetico.peso) - 30.33 :
            (58.29 * calculoEnergetico.peso) - 31.05;
      }
      else {
        // Crianças de 3 a 10 anos.
        calculoEnergetico.gastoEnergeticoTotal =
          calculoEnergetico.paciente.sexo == "M" ?
            (22.7 * calculoEnergetico.peso) + 505 :
            (20.3 * calculoEnergetico.peso) + 486;
      }
    }
  },
  {
    tipo: TIPO_PACIENTE.CRIANCAS.DESCRICAO,
    nome: `Institute of Medicine (IOM 2002/2005)`,
    calculo: (calculoEnergetico) => {
      if (calculoEnergetico.idade < 3 || calculoEnergetico.idade > 8) {
        // Menos que 3 anos ou maior que 8 anos.
        calculoEnergetico.gastoEnergeticoTotal = 0; // Não calcular, retornar Zero, a formula não se aplica.
      }
      else {
        // Crianças de 3 a 10 anos.
        calculoEnergetico.gastoEnergeticoTotal =
          calculoEnergetico.paciente.sexo == "M" ?
            88.5 - (61.9 * calculoEnergetico.idade) + calculoEnergetico.fatorAtividade * (26.7 * calculoEnergetico.peso + 903 * (calculoEnergetico.altura * 100)) + 20 : // Masculino
            153.3 - (30.8 * calculoEnergetico.idade) + calculoEnergetico.fatorAtividade * (10 * calculoEnergetico.peso + 934 * (calculoEnergetico.altura * 100)) + 20; //Feminino
      }
    }
  },
];

const CALCULOS_BEBES = [
  {
    tipo: TIPO_PACIENTE.BEBES.DESCRICAO,
    nome: `(FAO/OMS, 1985)`,
    calculo: (calculoEnergetico) => {

      if (calculoEnergetico.idade < 0 || calculoEnergetico.idade > 2) {
        calculoEnergetico.gastoEnergeticoTotal = 0;
      }
      else {
        calculoEnergetico.gastoEnergeticoTotal =
          calculoEnergetico.paciente.sexo == "M" ?
            60.9 * calculoEnergetico.peso - 54 : // Masculino
            61 * calculoEnergetico.peso - 51; // Feminino;
      }
    }
  },
  {
    tipo: TIPO_PACIENTE.BEBES.DESCRICAO,
    nome: `(Schofield, 1985) - Com estatura`,
    calculo: (calculoEnergetico) => {

      if (calculoEnergetico.idade < 0 || calculoEnergetico.idade > 2) {
        calculoEnergetico.gastoEnergeticoTotal = 0;
      }
      else {
        calculoEnergetico.gastoEnergeticoTotal =
          calculoEnergetico.paciente.sexo == "M" ?
            (0.167 * calculoEnergetico.peso) + (15.174 * (calculoEnergetico.altura * 100)) - 617.6 : // Masculino
            (16.252 * calculoEnergetico.peso) + (10.232 * (calculoEnergetico.altura * 100)) - 413.5; // Feminino;
      }
    }
  },
  {
    tipo: TIPO_PACIENTE.BEBES.DESCRICAO,
    nome: `(Schofield, 1985) - Sem estatura`,
    calculo: (calculoEnergetico) => {

      if (calculoEnergetico.idade < 0 || calculoEnergetico.idade > 2) {
        calculoEnergetico.gastoEnergeticoTotal = 0;
      }
      else {
        calculoEnergetico.gastoEnergeticoTotal =
          calculoEnergetico.paciente.sexo == "M" ?
            59.48 * calculoEnergetico.peso - 30.33 : // Masculino
            58.29 * calculoEnergetico.peso - 31.05; // Feminino;
      }
    }
  },
  {
    tipo: TIPO_PACIENTE.BEBES.DESCRICAO,
    nome: `(IOM/Food and Nutrition Board, 2002) - Geral`,
    calculo: (calculoEnergetico) => {
      if (calculoEnergetico.idadeMeses > 35) {
        // nao se aplica
        calculoEnergetico.gastoEnergeticoTotal = 0;
      }

      if (calculoEnergetico.idadeMeses <= 3) {
        calculoEnergetico.gastoEnergeticoTotal = 
          - 99.4 + (88.6 * calculoEnergetico.peso) + (89 * calculoEnergetico.peso) - 100 + 175;
      }
      else if (calculoEnergetico.idadeMeses <= 6) {
        calculoEnergetico.gastoEnergeticoTotal =
          -99.4 + (88.6 * calculoEnergetico.peso) + (89 * calculoEnergetico.peso) - 100 + 56;
      }
      else if (calculoEnergetico.idadeMeses <= 12) {
        calculoEnergetico.gastoEnergeticoTotal =
          -99.4 + (88.6 * calculoEnergetico.peso) + (89 * calculoEnergetico.peso) - 100 + 22;
      }
      else {
        calculoEnergetico.gastoEnergeticoTotal =
          -99.4 + (88.6 * calculoEnergetico.peso) + (89 * calculoEnergetico.peso) - 100 + 20;
      }
    }
  },
  {
    tipo: TIPO_PACIENTE.BEBES.DESCRICAO,
    nome: `(IOM/Food and Nutrition Board, 2002) - Com aleitamento`,
    calculo: (calculoEnergetico) => {
      if (calculoEnergetico.idadeMeses > 35) {
        // nao se aplica
        calculoEnergetico.gastoEnergeticoTotal = 0;
      }

      if (calculoEnergetico.idadeMeses <= 3) {
        calculoEnergetico.gastoEnergeticoTotal =
          - 152 + (92 * calculoEnergetico.peso) + (89 * calculoEnergetico.peso) - 100 + 175;
      }
      else if (calculoEnergetico.idadeMeses <= 6) {
        calculoEnergetico.gastoEnergeticoTotal =
          - 152 + (92 * calculoEnergetico.peso) + (89 * calculoEnergetico.peso) - 100 + 56;
      }
      else if (calculoEnergetico.idadeMeses <= 12) {
        calculoEnergetico.gastoEnergeticoTotal =
          - 152 + (92 * calculoEnergetico.peso) + (89 * calculoEnergetico.peso) - 100 + 22;
      }
      else {
        calculoEnergetico.gastoEnergeticoTotal =
          - 152 + (92 * calculoEnergetico.peso) + (89 * calculoEnergetico.peso) - 100 + 20;
      }
    }
  },
  {
    tipo: TIPO_PACIENTE.BEBES.DESCRICAO,
    nome: `(IOM/Food and Nutrition Board, 2002) - Com fórmulas`,
    calculo: (calculoEnergetico) => {
      if (calculoEnergetico.idadeMeses > 35) {
        // nao se aplica
        calculoEnergetico.gastoEnergeticoTotal = 0;
      }

      if (calculoEnergetico.idadeMeses <= 3) {
        calculoEnergetico.gastoEnergeticoTotal =
          - 29 + (82.6 * calculoEnergetico.peso) + (89 * calculoEnergetico.peso) - 100 + 175;
      }
      else if (calculoEnergetico.idadeMeses <= 6) {
        calculoEnergetico.gastoEnergeticoTotal =
          - 29 + (82.6 * calculoEnergetico.peso) + (89 * calculoEnergetico.peso) - 100 + 56;
      }
      else if (calculoEnergetico.idadeMeses <= 12) {
        calculoEnergetico.gastoEnergeticoTotal =
          - 29 + (82.6 * calculoEnergetico.peso) + (89 * calculoEnergetico.peso) - 100 + 22;
      }
      else {
        calculoEnergetico.gastoEnergeticoTotal =
          - 29 + (82.6 * calculoEnergetico.peso) + (89 * calculoEnergetico.peso) - 100 + 20;
      }
    }
  },
];

const CALCULOS_ADULTOS = [
  {
    tipo: TIPO_PACIENTE.ADULTOS.DESCRICAO,
    nome: `IOM (2005)`,
    calculo: (calculoEnergetico) => {

      if (calculoEnergetico.idade < 9) {
        calculoEnergetico.gastoEnergeticoTotal = 0;
      }
      else if (calculoEnergetico.idade < 19) {
        calculoEnergetico.gastoEnergeticoTotal =
          calculoEnergetico.paciente.sexo == "M" ?
            88.5 - (61.9 * calculoEnergetico.idade) + (calculoEnergetico.fatorAtividade * (26.7 * calculoEnergetico.peso + 903 * calculoEnergetico.altura)) + 25 : // Masculino
            135.3 - (30.8 * calculoEnergetico.idade) + (calculoEnergetico.fatorAtividade * (10 * calculoEnergetico.peso + 934 * calculoEnergetico.altura)) + 25; // Feminino;
      } else {
        calculoEnergetico.gastoEnergeticoTotal =
          calculoEnergetico.paciente.sexo == "M" ?
            662 - (9.53 * calculoEnergetico.idade) + (calculoEnergetico.fatorAtividade * (15.91 * calculoEnergetico.peso + 539.6 * calculoEnergetico.altura)) : // Masculino
            354 - (6.91 * calculoEnergetico.idade) + (calculoEnergetico.fatorAtividade * (9.36 * calculoEnergetico.peso + 726 * calculoEnergetico.altura)); // Feminino;
      }
    }
  },
  {
    tipo: TIPO_PACIENTE.ADULTOS.DESCRICAO,
    nome: `Mifflin-St Jeor (1990)`,
    calculo: (calculoEnergetico) => {
      calculoEnergetico.gastoEnergeticoTotal =
        calculoEnergetico.paciente.sexo == "M" ?
          (10 * calculoEnergetico.peso) + (6.25 * calculoEnergetico.altura) - (5 * calculoEnergetico.idade) + 5 : // Masculino
          (10 * calculoEnergetico.peso) + (6.25 * calculoEnergetico.altura) - (5 * calculoEnergetico.idade) - 161; // Feminino;
    }
  },
  {
    tipo: TIPO_PACIENTE.ADULTOS.DESCRICAO,
    nome: `Harris-Benedict (1919)`,
    calculo: (calculoEnergetico) => {
      calculoEnergetico.gastoEnergeticoTotal =
        calculoEnergetico.paciente.sexo == "M" ?
          66 + (13.8 * calculoEnergetico.peso) + (5 * calculoEnergetico.altura) - (6.8 * calculoEnergetico.idade) : // Masculino
          655 + (9.6 * calculoEnergetico.peso) + (1.9 * calculoEnergetico.altura) - (4.7 * calculoEnergetico.idade); // Feminino;
    }
  },
];

const CALCULOS_ADOLESCENTES = [
  {
    tipo: TIPO_PACIENTE.ADOLESCENTES.DESCRICAO,
    nome: `(Schofield, 1985)`,
    calculo: (calculoEnergetico) => {

      if (calculoEnergetico.idade < 10) {
        calculoEnergetico.gastoEnergeticoTotal = 0;
      }
      else {
        calculoEnergetico.gastoEnergeticoTotal =
          calculoEnergetico.paciente.sexo == "M" ?
            (16.25 * calculoEnergetico.peso) + (1.372 * (calculoEnergetico.altura * 100)) + 515.5 : // Masculino
            (8.365 * calculoEnergetico.peso) + (4.65 * (calculoEnergetico.altura * 100)) + 200; // Feminino;
      }
    }
  },
];

const CALCULOS_LACTANTES = [
  {
    tipo: TIPO_PACIENTE.LACTANTES.DESCRICAO,
    nome: `FAO/OMS, 2004`,
    calculo: (calculoEnergetico) => {
      if (calculoEnergetico.idade <= 30) {
        calculoEnergetico.gastoEnergeticoTotal =
          ((14.818 * calculoEnergetico.peso + 486.6) * calculoEnergetico.fatorAtividade) + 675;
      }
      else {
        calculoEnergetico.gastoEnergeticoTotal =
          ((8.126 * calculoEnergetico.peso + 845.6) * calculoEnergetico.fatorAtividade) + 460;
      }
    }
  },
];

const CALCULOSENERGETICOS =
  CALCULOS_GESTANTES
    .concat(CALCULOS_CRIANCAS)
    .concat(CALCULOS_BEBES)
    .concat(CALCULOS_ADULTOS)
    .concat(CALCULOS_ADOLESCENTES)
    .concat(CALCULOS_LACTANTES);

const ADICIONALENERGETICO = [
  {
    texto: '0 - Sem adicional energético',
    valor: 0
  },
  {
    texto: '85 - Indicado para 1º trimestre',
    valor: 85
  },
  {
    texto: '142 - Indicado para 2º trimestre',
    valor: 142
  },
  {
    texto: '285 - Indicado para 2º trimestre',
    valor: 258
  },
  {
    texto: '237 - Indicado para 3º trimestre',
    valor: 237
  },
  {
    texto: '475 - Indicado para 3º trimestre',
    valor: 475
  },
];

export {
  LISTA_TIPO_PACIENTE,
  LISTA_FATOR_ATIVIDADE,
  TIPO_PACIENTE,
  CALCULOSENERGETICOS,
  ADICIONALENERGETICO,

};