
import moment from 'moment';

function obter() {
  return [
    {
      nome: "Datas",
      filhas: [
        {
          nome: "Dia atual",
          atributo: "Dia_Atual",
          titulo: "Dia atual",
          inline: true,
          conteudo: () => {
            return moment().format('DD');
          }
        },
        {
          nome: "Mês atual",
          atributo: "Mes_Atual",
          titulo: "Mês atual",
          inline: true,
          conteudo: () => {
            return moment().format('MM');
          }
        },
        {
          nome: "Ano atual",
          atributo: "Ano_Atual",
          titulo: "Ano atual",
          inline: true,
          conteudo: () => {
            return moment().format('YYYY');
          }
        },
        {
          nome: "Data atual",
          atributo: "Data_Atual",
          titulo: "Data atual",
          inline: true,
          conteudo: () => {
            return moment().format('DD/MM/YYYY');
          }
        },
      ]
    }
  ]
}

export default { obter }